import { createSlice } from "@reduxjs/toolkit";
import { extractUniqueNumber, shuffleQuestions } from "../utils";

/** create reducer */
export const questionReducer = createSlice({
  name: "questions",
  initialState: {
    exam: "",
    queue: [],
    answers: [],
    trace: 0,
    data: {
      department: "",
      program: "",
      batch: "",
      modality: "",
      status: "",
      takenStatus: "",
    },
    studentQuery: {
      department: "",
      program: "",
      batch: "",
      modality: "",
      section: "",
    },
  },
  reducers: {
    startExamAction: (state, action) => {
      // let { question, answers, exam } = action.payload;
      // return {
      //   ...state,
      //   queue: question,
      //   answers,
      //   exam,
      // };
    },
    startFetch: (state) => {
      state.isLoading = true;
      state.serverError = null;
    },
    fetchSuccess: (state, action) => {
      // let { question, exam } = action.payload;
      let seed = extractUniqueNumber(localStorage.getItem("studentId"));
      state.isLoading = false;
      state.queue = shuffleQuestions(action.payload.questions, seed);
      state.exam = action.payload.examTime;
    },
    fetchFailure: (state, action) => {
      state.isLoading = false;
      state.serverError = action.payload;
    },

    moveNextAction: (state) => {
      return {
        ...state,
        trace: state.trace + 1,
      };
    },
    moveRandomAction: (state, action) => {
      return {
        ...state,
        trace: action.payload,
      };
    },
    movePrevAction: (state) => {
      return {
        ...state,
        trace: state.trace - 1,
      };
    },
    resetAllAction: () => {
      return {
        queue: [],
        answers: [],
        trace: 0,
        exam: "",
      };
    },
    queryDataStart: (state, action) => {
      state.data = action.payload;
    },
    studentQueryStart: (state, action) => {
      state.studentQuery = action.payload;
    },
  },
});

export const {
  startExamAction,
  moveNextAction,
  movePrevAction,
  resetAllAction,
  startFetch,
  moveRandomAction,
  fetchSuccess,
  fetchFailure,
  queryDataStart,
  studentQueryStart,
} = questionReducer.actions;

export default questionReducer.reducer;
