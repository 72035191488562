import * as React from "react";
import { experimentalStyled as styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { useDispatch, useSelector } from "react-redux";
import { startFetch } from "../redux/question_reducer";
import { useParams } from "react-router-dom";
import { PushAnswer } from "../hooks/setResult";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function QuestionList({
  onRandomMove,
  checked,
  handleAnswerSubmit,
  handleTimeSubmit,
}) {
  const { trace } = useSelector((state) => state.questions);
  const result = useSelector((state) => state.result.result);
  const questions = useSelector((state) => state.questions.queue);
  const dispatch = useDispatch();
  const { examId } = useParams();
  React.useEffect(() => {
    dispatch(startFetch({ examId }));
  }, []);
  function onClick(index) {
    onRandomMove(index);
    dispatch(PushAnswer({ checked: checked, trace }));
    handleAnswerSubmit();
    handleTimeSubmit();
  }
  return (
    <Box sx={{ flexGrow: 1, height: "100%" }}>
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
        style={{ height: "100%" }}
      >
        {Array.from(Array(questions?.length)).map((_, index) => (
          <Grid
            item
            xs={4}
            sm={4}
            md={3}
            lg={12}
            key={index}
            style={styles.selectHover}
            onClick={() => onClick(index)}
          >
            <Item variant="secondary" style={result[index] && styles.item}>
              {index + 1}
            </Item>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

const styles = {
  selectHover: {
    cursor: "pointer",
    height: "50px",
    marginBottom: "30px",
    fontWeight: "bold",
  },
  item: {
    background: "grey",
    color: "white",
    fontWeight: "bold",
  },
};
