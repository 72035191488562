/* eslint-disable react/prop-types */
import { CardMedia, Rating } from "@mui/material";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import axios from "axios";
import Modalpopup from "../ui/Modal";

const Button = styled.button`
  display: none;
  width: 100%;
  padding: 10px;
  background-color: ${({ theme }) => theme.white};
  color: ${({ theme }) => theme.text_black};
  font-size: 14px;
  font-weight: 700;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.8s ease-in-out;
`;
const Card = styled.div`
  width: 200px;
  height: auto;
  background-color: ${({ theme }) => theme.card};
  cursor: pointer;
  /* border-radius: 10px; */
  /* box-shadow: 0 0 12px 4px rgba(0, 0, 0, 0.2); */
  overflow: hidden;
  /* padding: 26px 20px; */
  display: flex;
  flex-direction: column;
  gap: 14px;
  transition: all 0.5s ease-in-out;
  /* &:hover {
    transform: translateY(-10px);
    box-shadow: 0 0 50px 4px rgba(0, 0, 0, 0.6);
    filter: brightness(1.1);
  } */
  &:hover ${Button} {
    display: block;
  }
  @media (max-width: 370px) {
    grid-template-columns: repeat(1, 1fr);
    width: 90%;
    height: auto;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 180px;
  background-color: ${({ theme }) => theme.white};
  border-radius: 10px;
  box-shadow: 0 0 16px 2px rgba(0, 0, 0, 0.3);
`;

const Tags = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 4px;
`;

const Tag = styled.span`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.primary};
  background-color: ${({ theme }) => theme.primary + 15};
  padding: 2px 8px;
  border-radius: 10px;
`;

const Details = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0px;
  padding: 0px 2px;
`;
const Title = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.text_secondary};
  overflow: hidden;
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Date = styled.div`
  font-size: 12px;
  margin-left: 2px;
  font-weight: 400;
  color: ${({ theme }) => theme.text_secondary + 80};
  @media only screen and (max-width: 768px) {
    font-size: 10px;
  }
`;

const Description = styled.div`
  font-weight: 400;
  color: ${({ theme }) => theme.text_secondary + 99};
  overflow: hidden;
  margin-top: 8px;
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
`;

const Members = styled.div`
  display: flex;
  align-items: center;
  padding-left: 10px;
`;
const Avatar = styled.img`
  width: 38px;
  height: 38px;
  border-radius: 50%;
  margin-left: -10px;
  background-color: ${({ theme }) => theme.white};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border: 3px solid ${({ theme }) => theme.card};
`;

const TakenExam = ({ project, subjectId }) => {
  const navigate = useNavigate();

  const handleNavigate = (e) => {
    e.preventDefault();
    navigate(`/result-detail/${project?.exam?._id}`);
  };
  return (
    <>
      <Card onClick={handleNavigate}>
        <CardMedia
          component="img"
          height="140"
          width="100%"
          image="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRnbAF8gP-5GH9F5xBotX3P1CYQ6s3JunBjR00MNMvqhA&s"
          alt="green iguana"
        />
        {/* <Image src={project.image} /> */}

        <Details>
          <Title>{project?.exam?.name}</Title>
          <Tags>
            <Tag>{project?.exam?.program}</Tag>
            <Tag>{project?.department?.stream}</Tag>
          </Tags>

          {/* <Date>{project.date}</Date> */}
          <Date style={{ color: "green" }}>{project?.department?.name}</Date>
          <Description>Exam Time: {project?.exam?.examTime}</Description>
        </Details>
        {/* <Members>
        {project.member?.map((member, i) => (
          <Avatar key={i} src={member.img} />
        ))}
      </Members> */}
        {/* <Button>View Project</Button> */}
      </Card>
    </>
  );
};

export default TakenExam;
