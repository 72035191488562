import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
// import {
//   TextField,
//   Radio,
//   RadioGroup,
//   FormControl,
//   FormLabel,
//   FormControlLabel,
//   Select,
//   MenuItem,
//   Button,
//   Container,
//   Grid,
// } from "@material-ui/core";

import {
  TextField,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  FormControlLabel,
  Select,
  MenuItem,
  Button,
  Container,
  Grid,
} from "@mui/material";
import axios from "axios";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { useNavigate, useParams } from "react-router-dom";
import { InputLabel } from "@mui/material";
import ChangePassword from "../Admin/ChangePassword";

const useStyles = makeStyles((theme) => ({
  form: {
    marginTop: theme.spacing(2),
  },
  submitButton: {
    marginTop: theme.spacing(2),
  },
}));
const UpdateAdmins = () => {
  const classes = useStyles();

  const [studentData, setStudentData] = useState({
    id: "",
    firstName: "",
    middleName: "",
    lastName: "",
    sex: "male",
    passwprd: "",
  });
  const [message, setMessage] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [failur, setShowFailur] = useState(false);

  const navigate = useNavigate();
  const { id } = useParams();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setStudentData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const fetchUser = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/students/${id}`
      );
      setStudentData(response.data);
    } catch (error) {
      console.error("Error fetching user:", error.message);
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);
  const handleUpdate = (e) => {
    e.preventDefault();

    const apiUrl = `${process.env.REACT_APP_API_URL}/students`;

    axios
      .put(`${apiUrl}/${id}`, studentData)
      .then((response) => {
        setShowPopup(true);
        setMessage("Student updated successfully");
        navigate("/register/super-admin");
        // Handle success and update UI if needed
      })
      .catch((error) => {
        setShowFailur(true);
        setMessage("error in updating student ", error);
        console.error("Error updating student examTime:", error);
        // Handle error and display error message if needed
      });
  };

  useEffect(() => {
    setTimeout(() => {
      setShowFailur(false);
      setShowPopup(false);
    }, 10000);
  }, [showPopup, failur]);

  return (
    <Container maxWidth="xl" style={{ paddingTop: "3%" }}>
      <div style={{ width: "100%" }}>
        <Button
          style={{ background: "red", color: "white", float: "right" }}
          onClick={() => navigate(-1)}
        >
          Back
        </Button>
      </div>
      <Stack sx={{ width: "100%" }} spacing={2} style={{ marginTop: "30px" }}>
        {showPopup && (
          <Alert variant="filled" severity="success">
            {message}
          </Alert>
        )}

        {failur && (
          <Alert variant="filled" severity="error">
            {message}
          </Alert>
        )}
      </Stack>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
        }}
      >
        <div style={{ width: "60%" }}>
          <form className={classes.form} onSubmit={handleUpdate}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
              }}
            >
              <TextField
                label="ID"
                name="id"
                fullWidth
                value={studentData?.id}
                onChange={handleChange}
                margin="normal"
                required
                style={{ marginRight: "20px" }}
              />
              <TextField
                label="First Name"
                name="firstName"
                fullWidth
                value={studentData?.firstName}
                onChange={handleChange}
                margin="normal"
                required
                style={{ marginRight: "20px" }}
              />
              <TextField
                label="Middle Name"
                name="middleName"
                fullWidth
                value={studentData?.middleName}
                onChange={handleChange}
                margin="normal"
              />
            </div>
            <TextField
              label="Last Name"
              name="lastName"
              fullWidth
              value={studentData?.lastName}
              onChange={handleChange}
              margin="normal"
              required
            />
            <FormControl component="fieldset" margin="normal">
              <FormLabel component="legend">Sex</FormLabel>
              <RadioGroup
                aria-label="sex"
                name="sex"
                value={studentData?.sex}
                onChange={handleChange}
                row
              >
                <FormControlLabel
                  value="male"
                  control={<Radio />}
                  label="Male"
                />
                <FormControlLabel
                  value="female"
                  control={<Radio />}
                  label="Female"
                />
              </RadioGroup>
            </FormControl>
            <br />

            {/* <FormControl fullWidth margin="normal">
          <FormLabel>Role</FormLabel>
          <Select name="role" value={studentData?.role} onChange={handleChange}>
            <MenuItem value="student">Student</MenuItem>
          </Select>
        </FormControl> */}
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.submitButton}
            >
              Update
            </Button>
          </form>
        </div>
        <div>
          <ChangePassword studentId={id} />
        </div>
      </div>
    </Container>
  );
};

export default UpdateAdmins;
