import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  TextField,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  FormControlLabel,
  Select,
  MenuItem,
  Button,
  Container,
} from "@mui/material";
import axios from "axios";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import "../../styles/login.css";
import { IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import SuperAdminList from "./SuperAdminList";

const useStyles = makeStyles((theme) => ({
  form: {
    marginTop: theme.spacing(2),
  },
  submitButton: {
    marginTop: theme.spacing(2),
  },
}));

const RegisterSuperAdmin = () => {
  const classes = useStyles();

  const [message, setMessage] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [failur, setShowFailur] = useState(false);
  const [superAdminData, setSuperAdminData] = useState([]);
  const [flag, setFlag] = useState(false);
  const [role, setRole] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [studentData, setStudentData] = useState({
    id: "",
    firstName: "",
    middleName: "",
    lastName: "",
    sex: "male",
    password: "",
    role: "",
  });

  async function fetchStudent() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/super-admins/${role}`
      );
      const res = response.data;
      // Process the retrieved data here
      setSuperAdminData(res);
    } catch (error) {
      // Handle error
      console.error("Error fetching student data:", error);
    }
  }
  useEffect(() => {
    if (localStorage.getItem("role") === "college") {
      setStudentData((prevData) => ({
        ...prevData,
        role: "super-admin",
      }));
      setRole("super-admin");
    }
    if (localStorage.getItem("role") === "super-admin") {
      setStudentData((prevData) => ({
        ...prevData,
        role: "admin",
      }));
      setRole("admin");
    }
    fetchStudent();
  }, [flag, role]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setStudentData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/register`,
        studentData
      );
      setShowPopup(true);
      setMessage("Student Register Successfully");
      setFlag(!flag);
    } catch (error) {
      setShowFailur(true);
      setMessage("Error in register Id already registered");
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setShowFailur(false);
      setShowPopup(false);
    }, 10000);
  }, [showPopup, failur]);

  return (
    <div style={{ marginBottom: "3rem" }}>
      <Container
        className="registration-container"
        style={{ width: "100%", marginLeft: "30px", maxWidth: "95%" }}
      >
        <Stack sx={{ width: "100%" }} spacing={2} style={{ marginTop: "30px" }}>
          {showPopup && (
            <Alert variant="filled" severity="success">
              {message}
            </Alert>
          )}

          {failur && (
            <Alert variant="filled" severity="error">
              {message}
            </Alert>
          )}
        </Stack>

        <form className={classes.form} onSubmit={handleSubmit}>
          <div className="name-form" style={{}}>
            <TextField
              label="ID"
              name="id"
              fullWidth
              value={studentData.id}
              onChange={handleChange}
              margin="normal"
              required
              style={{ marginRight: "20px" }}
            />
            <TextField
              label="First Name"
              name="firstName"
              fullWidth
              value={studentData.firstName}
              onChange={handleChange}
              margin="normal"
              required
              style={{ marginRight: "20px" }}
            />
            <TextField
              label="Middle Name"
              name="middleName"
              fullWidth
              value={studentData.middleName}
              onChange={handleChange}
              margin="normal"
            />

            <TextField
              label="Last Name"
              name="lastName"
              fullWidth
              value={studentData.lastName}
              onChange={handleChange}
              margin="normal"
              required
            />
          </div>
          <FormControl component="fieldset" margin="normal">
            <FormLabel component="legend">Sex</FormLabel>
            <RadioGroup
              aria-label="sex"
              name="sex"
              value={studentData.sex}
              onChange={handleChange}
              row
            >
              <FormControlLabel value="male" control={<Radio />} label="Male" />
              <FormControlLabel
                value="female"
                control={<Radio />}
                label="Female"
              />
            </RadioGroup>
          </FormControl>
          <br />
          <TextField
            label="Password"
            name="password"
            type={showPassword ? "text" : "password"}
            value={studentData.password}
            onChange={handleChange}
            margin="normal"
            required
            InputProps={{
              endAdornment: (
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              ),
            }}
            style={{ width: "30%" }}
          />
          <br />
          <FormControl margin="normal" style={{ width: "30%" }}>
            <FormLabel>Role</FormLabel>
            <Select
              name="role"
              value={studentData.role}
              onChange={handleChange}
            >
              {/* <MenuItem value="student">Student</MenuItem> */}
              {localStorage.getItem("role") === "college" && (
                <MenuItem value="super-admin">Super Admin</MenuItem>
              )}
              {localStorage.getItem("role") === "super-admin" && (
                <MenuItem value="admin">Admin</MenuItem>
              )}
            </Select>
          </FormControl>
          <br />
          <br />
          <br />
          <div style={{ width: "100%", textAlign: "center" }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.submitButton}
            >
              Register
            </Button>
          </div>
        </form>
      </Container>
      <div style={{ margin: "2%" }}>
        <SuperAdminList
          adminData={superAdminData}
          flag={flag}
          setFlag={setFlag}
        />
      </div>
    </div>
  );
};

export default RegisterSuperAdmin;
