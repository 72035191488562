import { FormControl, InputLabel } from "@material-ui/core";
import "./chart.scss";
import {
  AreaChart,
  Area,
  XAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { MenuItem, Select } from "@mui/material";

const Chart = ({
  aspect,
  title,
  resultAnalysis,
  selectedExam,
  setSelectedExam,
  exams,
}) => {
  const data = [
    { name: "0%", Total: resultAnalysis?.one },
    { name: "30%", Total: resultAnalysis?.two },
    { name: "40%", Total: resultAnalysis?.three },
    { name: "50%", Total: resultAnalysis?.four },
    { name: "60%", Total: resultAnalysis?.five },
    { name: "70%", Total: resultAnalysis?.six },
    { name: "80%", Total: resultAnalysis?.seven },
    { name: "100%", Total: resultAnalysis?.eight },
  ];
  return (
    <div className="chart">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div className="title">{title}</div>
        <FormControl style={{ width: "50%", marginTop: "-10px" }}>
          {/* <InputLabel
            id="demo-simple-select-label"
            style={{ marginLeft: "10px" }}
          >
            Exams
          </InputLabel> */}
          <Select
            labelId="demo-simple-select-label"
            name="course"
            id="demo-simple-select"
            value={selectedExam}
            label="Department"
            onChange={(e) => setSelectedExam(e.target.value)}
          >
            {exams?.map((dept) => (
              <MenuItem key={dept._id} value={dept._id}>
                {dept.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <ResponsiveContainer width="100%" aspect={aspect}>
        <AreaChart
          width={730}
          height={250}
          data={data}
          margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
        >
          <defs>
            <linearGradient id="total" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis dataKey="name" stroke="gray" />
          <CartesianGrid strokeDasharray="3 3" className="chartGrid" />
          <Tooltip />
          <Area
            type="monotone"
            dataKey="Total"
            stroke="#8884d8"
            fillOpacity={1}
            fill="url(#total)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default Chart;
