import "./home.scss";
import Widget from "../widget/Widget";
import Featured from "../featured/Featured";
import Chart from "../chart/Chart";
import Table from "../table/Table";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import axios from "axios";
const Home = () => {
  const [studentsData, setStudentsData] = useState([]);
  const [exams, setExams] = useState([]);
  const [date, setDate] = useState(new Date().toISOString().slice(0, 10));
  const [examAnalysis, setExamAnalysis] = useState({});
  const [selectedExam, setSelectedExam] = useState("");

  const numberOfStudents = studentsData && studentsData.length;
  const numberOfClasses = exams && exams.length;

  useEffect(() => {
    async function fetchStudent() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/students?role=student`
        );
        const res = response.data;
        setStudentsData(res);
      } catch (error) {
        console.error("Error fetching student data:", error);
      }
    }
    const fetchQuestions = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/questions`
        );
        const questionsData = response.data;
        setExams(questionsData);
      } catch (error) {
        console.error("Error fetching questions:", error.message);
      }
    };

    fetchQuestions();
    fetchStudent();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/result/analysis/${selectedExam}`
        );
        const data = response.data;
        setExamAnalysis(data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [selectedExam]);

  const activeExams = exams?.filter((exam) => exam.status === true);
  return (
    <div className="home">
      {/* <Sidebar /> */}
      <div className="homeContainer">
        {/* <Navbar /> */}
        <div className="widgets">
          <Widget
            type="Students"
            title="Total Students"
            number={numberOfStudents}
            rate={"20%"}
          />
          <Widget
            type="Classes"
            title="Total Exams"
            number={numberOfClasses}
            rate={"20%"}
          />
          <Widget
            type="Teachers"
            title="Total Active Exams"
            number={activeExams?.length}
            rate={"20%"}
          />
        </div>
        <div className="charts">
          <Featured
            presentPercent={exams?.length}
            totalPresentStudents={activeExams?.length}
            totalAbsentStudents={exams?.length - activeExams?.length}
          />
          <Chart
            title="Student Result Analysis"
            aspect={2 / 1}
            resultAnalysis={examAnalysis}
            selectedExam={selectedExam}
            setSelectedExam={setSelectedExam}
            exams={exams}
          />
        </div>
        <div className="listContainer">
          <div className="listTitle">Active Exam List</div>
          <Table data={activeExams} date={date} />
        </div>
      </div>
    </div>
  );
};

export default Home;
