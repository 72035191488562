import { Children, useEffect, useState } from "react";
import {
  CssBaseline,
  Box,
  Toolbar,
  List,
  Typography,
  Divider,
  IconButton,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import StudentSideBar from "./StudentSideBar";
import { Navigate, Route, Routes } from "react-router-dom";

import { AppBar, Drawer } from "./styles";

const Dashboard = ({ children }) => {
  const [open, setOpen] = useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  useEffect(() => {
    if (window.innerWidth < 500) {
      console.log("not display");
    } else {
      toggleDrawer();
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setOpen(window.innerWidth >= 700);
    };

    // Add resize event listener
    window.addEventListener("resize", handleResize);

    // Initial check on mount
    handleResize();

    // Clean up the event listener when component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <Drawer variant="permanent" open={open} sx={styles.drawerStyled}>
          <Toolbar sx={styles.toolBarStyled} style={{ background: "#061136" }}>
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon style={{ color: "white" }} />
            </IconButton>
          </Toolbar>
          <Divider />
          <List
            component="nav"
            style={{ background: "#061136", height: "100%", color: "white" }}
          >
            <StudentSideBar />
          </List>
        </Drawer>
        <Box component="main" sx={styles.boxStyled}>
          <Toolbar style={{ maxHeight: "10px", minHeight: "10px" }} />

          {children}
        </Box>
      </Box>
    </>
  );
};

export default Dashboard;

const styles = {
  boxStyled: {
    backgroundColor: (theme) =>
      theme.palette.mode === "light"
        ? theme.palette.grey[100]
        : theme.palette.grey[900],
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  toolBarStyled: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    px: [1],
  },
  drawerStyled: {
    display: "flex",
  },
  hideDrawer: {
    display: "flex",
    "@media (max-width: 600px)": {
      display: "none",
    },
  },
};
