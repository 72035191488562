/* eslint-disable react/prop-types */
import { CardMedia, Rating } from "@mui/material";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import axios from "axios";
import Modalpopup from "../ui/Modal";

const Button = styled.button`
  display: none;
  width: 100%;
  padding: 10px;
  background-color: ${({ theme }) => theme.white};
  color: ${({ theme }) => theme.text_black};
  font-size: 14px;
  font-weight: 700;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.8s ease-in-out;
`;
const Card = styled.div`
  max-width: 200px;
  height: auto;
  background-color: ${({ theme }) => theme.card};
  cursor: pointer;
  /* border-radius: 10px; */
  /* box-shadow: 0 0 12px 4px rgba(0, 0, 0, 0.2); */
  overflow: hidden;
  /* padding: 26px 20px; */
  display: flex;
  flex-direction: column;
  gap: 14px;
  transition: all 0.5s ease-in-out;
  /* &:hover {
    transform: translateY(-10px);
    box-shadow: 0 0 50px 4px rgba(0, 0, 0, 0.6);
    filter: brightness(1.1);
  } */
  &:hover ${Button} {
    display: block;
  }
  @media (max-width: 370px) {
    grid-template-columns: repeat(1, 1fr);
    width: 90%;
    height: auto;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 180px;
  background-color: ${({ theme }) => theme.white};
  border-radius: 10px;
  box-shadow: 0 0 16px 2px rgba(0, 0, 0, 0.3);
`;

const Tags = styled.div`
  width: 100%;
  display: flex;
  align-items: start;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 4px;
`;

const Tag = styled.span`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.primary};
  background-color: ${({ theme }) => theme.primary + 15};
  padding: 2px 8px;
  border-radius: 10px;
`;

const Details = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0px;
  padding: 0px 2px;
`;
const Title = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.text_secondary};
  overflow: hidden;
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Date = styled.div`
  font-size: 12px;
  margin-left: 2px;
  font-weight: 400;
  color: ${({ theme }) => theme.text_secondary + 80};
  @media only screen and (max-width: 768px) {
    font-size: 10px;
  }
`;

const Description = styled.div`
  font-weight: 400;
  color: ${({ theme }) => theme.text_secondary + 99};
  overflow: hidden;
  margin-top: 8px;
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
`;

const Members = styled.div`
  display: flex;
  align-items: center;
  padding-left: 10px;
`;
const Avatar = styled.img`
  width: 38px;
  height: 38px;
  border-radius: 50%;
  margin-left: -10px;
  background-color: ${({ theme }) => theme.white};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border: 3px solid ${({ theme }) => theme.card};
`;

const tags = ["tag1", "tag2", "tag3"];

const ExamDetail = ({ project, subjectId }) => {
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [alert, setAlert] = useState(false);
  const [isTaken, setIsTaken] = useState(false);

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  useEffect(() => {
    const handleResultSubmit = () => {
      const apiUrl = `${process.env.REACT_APP_API_URL}/result`;

      axios
        .get(`${apiUrl}/${localStorage.getItem("userId")}/${project?._id}`)
        .then((response) => {
          if (response.data.result !== null) {
            setIsTaken(true);
          }
        })
        .catch((error) => {
          console.error("Error in geting exam:", error);
        });
    };

    handleResultSubmit();
  }, []);

  const functionopenpopup = () => {
    if (isTaken) {
      navigate(`/result-detail/${project?._id}`);
    } else {
      setOpen(true);
    }
  };
  const closeMOdal = () => {
    setOpen(false);
  };

  const handleNavigate = (e) => {
    e.preventDefault();

    if (project?.password === password) {
      navigate(`/exam/${project?._id}`);
    } else {
      setMessage("Please insert correct exam password");
      setAlert(true);
      setPassword("");
    }
  };
  useEffect(() => {
    setTimeout(() => setAlert(false), 5000);
  }, [alert]);

  return (
    <>
      <Modalpopup
        open={open}
        functionopenpopup={functionopenpopup}
        setPassword={setPassword}
        setOpen={setOpen}
        handleNavigate={handleNavigate}
        password={password}
        alert={alert}
        message={message}
        closeMOdal={closeMOdal}
      />
      <Card onClick={functionopenpopup}>
        <CardMedia
          component="img"
          height="140"
          width="100%"
          image="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRnbAF8gP-5GH9F5xBotX3P1CYQ6s3JunBjR00MNMvqhA&s"
          alt="green iguana"
        />
        {/* <Image src={project.image} /> */}

        <Details>
          <Title>{project?.name}</Title>
          {/* <Tags>
            {project?.program?.map((prog) => (
              <Tag>{prog?.name}</Tag>
            ))}
            {project?.department?.map((dept) => (
              <Tag>{dept?.name}</Tag>
            ))}
          </Tags> */}

          {/* <Date>{project.date}</Date> */}
          {/* <Date style={{ color: "green" }}>{project?.department[0]?.name}</Date> */}
          <Date style={{ color: "green" }}>
            {project?.department?.map((dept) => (
              <span>{dept?.name}, </span>
            ))}
          </Date>
          <Description>Exam Time: {project.examTime}</Description>
        </Details>
        {/* <Members>
        {project.member?.map((member, i) => (
          <Avatar key={i} src={member.img} />
        ))}
      </Members> */}
        {/* <Button>View Project</Button> */}
      </Card>
    </>
  );
};

export default ExamDetail;
