import * as React from "react";
import {
  Divider,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";

import HomeIcon from "@mui/icons-material/Home";
import ClassOutlinedIcon from "@mui/icons-material/ClassOutlined";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AccountMenu from "./AccountMenu";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";

const StudentSideBar = () => {
  const location = useLocation();
  return (
    <>
      <React.Fragment>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            // marginLeft: "1px",
            marginTop: "20px",
          }}
        >
          {(localStorage.getItem("role") === "college" ||
            localStorage.getItem("role") === "super-admin") && (
            <ListItemButton
              component={Link}
              to="/dashboard"
              style={{ marginBottom: "15px" }}
              className="menus"
            >
              <ListItemIcon style={{ minWidth: "36px" }}>
                <HomeIcon
                  style={{ color: "white" }}
                  className={
                    location.pathname === ("/" || "/dashboard")
                      ? "active"
                      : "inherit"
                  }
                />
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItemButton>
          )}
          {(localStorage.getItem("role") === "college" ||
            localStorage.getItem("role") === "super-admin" ||
            localStorage.getItem("role") === "admin") && (
            <ListItemButton
              component={Link}
              to="/register"
              style={{ marginBottom: "15px" }}
            >
              <ListItemIcon style={{ minWidth: "36px" }}>
                <AppRegistrationIcon
                  style={{ color: "white" }}
                  color={
                    location.pathname === ("/" || "/Student/dashboard")
                      ? "primary"
                      : "inherit"
                  }
                />
              </ListItemIcon>
              <ListItemText primary="Register Users" />
            </ListItemButton>
          )}
          {(localStorage.getItem("role") === "college" ||
            localStorage.getItem("role") === "super-admin" ||
            localStorage.getItem("role") === "admin") && (
            <ListItemButton
              component={Link}
              to="/exam-uploud"
              style={{ marginBottom: "15px" }}
            >
              <ListItemIcon style={{ minWidth: "36px" }}>
                <AssignmentIcon
                  style={{ color: "white" }}
                  color={
                    location.pathname.startsWith("/exam-uploud")
                      ? "success"
                      : "inherit"
                  }
                />
              </ListItemIcon>
              <ListItemText primary="Upload Question" />
            </ListItemButton>
          )}
          {(localStorage.getItem("role") === "college" ||
            localStorage.getItem("role") === "super-admin") && (
            <ListItemButton
              component={Link}
              to="/student/list"
              style={{ marginBottom: "15px" }}
            >
              <ListItemIcon style={{ minWidth: "36px" }}>
                <AssignmentIcon
                  style={{ color: "white" }}
                  color={
                    location.pathname.startsWith("/student/list")
                      ? "primary"
                      : "white"
                  }
                />
              </ListItemIcon>
              <ListItemText primary="Manage Students" />
            </ListItemButton>
          )}
          {(localStorage.getItem("role") === "college" ||
            localStorage.getItem("role") === "super-admin") && (
            <ListItemButton
              component={Link}
              to="/exam/list"
              style={{ marginBottom: "15px" }}
            >
              <ListItemIcon style={{ minWidth: "36px" }}>
                <AssignmentIcon
                  style={{ color: "white" }}
                  color={
                    location.pathname.startsWith("/exam/list")
                      ? "primary"
                      : "white"
                  }
                />
              </ListItemIcon>
              <ListItemText primary="Manage Exam" />
            </ListItemButton>
          )}
          {(localStorage.getItem("role") === "college" ||
            localStorage.getItem("role") === "super-admin") && (
            <ListItemButton
              component={Link}
              to="/question-bank"
              style={{ marginBottom: "15px" }}
            >
              <ListItemIcon style={{ minWidth: "36px" }}>
                <ClassOutlinedIcon
                  style={{ color: "white" }}
                  color={
                    location.pathname.startsWith("/question-bank")
                      ? "primary"
                      : "inherit"
                  }
                />
              </ListItemIcon>
              <ListItemText primary="Question Banks" />
            </ListItemButton>
          )}
          {/* {(localStorage.getItem("role") === "college" ||
            localStorage.getItem("role") === "super-admin" ||
            localStorage.getItem("role") === "admin") && (
            <ListItemButton
              component={Link}
              to="/insert-question"
              style={{ marginBottom: "15px" }}
            >
              <ListItemIcon>
                <ClassOutlinedIcon
                  style={{ color: "white" }}
                  color={
                    location.pathname.startsWith("/insert-question")
                      ? "primary"
                      : "inherit"
                  }
                />
              </ListItemIcon>
              <ListItemText primary="Create Course & Exam" />
            </ListItemButton>
          )} */}
          {(localStorage.getItem("role") === "college" ||
            localStorage.getItem("role") === "super-admin") && (
            <ListItemButton
              component={Link}
              to="/department-form"
              style={{ marginBottom: "15px" }}
            >
              <ListItemIcon style={{ minWidth: "36px" }}>
                <ClassOutlinedIcon
                  style={{ color: "white" }}
                  color={
                    location.pathname.startsWith("/department-form")
                      ? "primary"
                      : "inherit"
                  }
                />
              </ListItemIcon>
              <ListItemText primary="Manage Registration" />
            </ListItemButton>
          )}
          {(localStorage.getItem("role") === "college" ||
            localStorage.getItem("role") === "super-admin") && (
            <ListItemButton
              component={Link}
              to="/register/super-admin"
              style={{ marginBottom: "15px" }}
            >
              <ListItemIcon style={{ minWidth: "36px" }}>
                <ClassOutlinedIcon
                  style={{ color: "white" }}
                  color={
                    location.pathname.startsWith("/register/super-admin")
                      ? "primary"
                      : "inherit"
                  }
                />
              </ListItemIcon>
              <ListItemText
                primary={`${
                  localStorage.getItem("role") === "college"
                    ? "Manage Super-Admins"
                    : "Manage Admins"
                }`}
              />
            </ListItemButton>
          )}
          <ListItemButton
            component={Link}
            // to="/department-form"
            style={{ position: "absolute", bottom: "30px", marginLeft: "10px" }}
          >
            <ListItemIcon>
              <AccountMenu />
            </ListItemIcon>
            {/* <ListItemText primary="Create Department" /> */}
          </ListItemButton>
        </div>
      </React.Fragment>
      {/* <Divider sx={{ my: 1 }} />
      <React.Fragment>
        <ListSubheader component="div" inset>
          User
        </ListSubheader>
        <ListItemButton component={Link} to="/Student/profile">
          <ListItemIcon>
            <AccountCircleOutlinedIcon
              color={
                location.pathname.startsWith("/Student/profile")
                  ? "primary"
                  : "inherit"
              }
            />
          </ListItemIcon>
          <ListItemText primary="Profile" />
        </ListItemButton>
        <ListItemButton component={Link} to="/logout">
          <ListItemIcon>
            <ExitToAppIcon
              color={
                location.pathname.startsWith("/logout") ? "primary" : "inherit"
              }
            />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItemButton>
      </React.Fragment> */}
    </>
  );
};

export default StudentSideBar;
