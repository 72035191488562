import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  TextField,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  FormControlLabel,
  Select,
  MenuItem,
  Button,
  Container,
} from "@material-ui/core";
import axios from "axios";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import "../../../styles/login.css";
import { IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import SuperAdminList from "../../college/SuperAdminList";

const useStyles = makeStyles((theme) => ({
  form: {
    marginTop: theme.spacing(2),
  },
  submitButton: {
    marginTop: theme.spacing(2),
  },
}));

const RegisterAdmin = () => {
  const classes = useStyles();

  const [message, setMessage] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [failur, setShowFailur] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [studentData, setStudentData] = useState({
    id: "",
    firstName: "",
    middleName: "",
    lastName: "",
    sex: "male",
    password: "",
    role: `${
      localStorage.getItem("role") === "admin" ? "admin" : "super-admin"
    } `,
  });
  const handleChange = (event) => {
    const { name, value } = event.target;
    setStudentData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/register`,
        studentData
      );
      setShowPopup(true);
      setMessage("Student Register Successfully");
    } catch (error) {
      setShowFailur(true);
      setMessage("Error in register Id already registered");
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setShowFailur(false);
      setShowPopup(false);
    }, 10000);
  }, [showPopup, failur]);

  return (
    <div style={{ marginBottom: "3rem" }}>
      <Container
        className="registration-container"
        style={{ width: "100%", marginLeft: "30px", maxWidth: "95%" }}
      >
        <Stack sx={{ width: "100%" }} spacing={2} style={{ marginTop: "30px" }}>
          {showPopup && (
            <Alert variant="filled" severity="success">
              {message}
            </Alert>
          )}

          {failur && (
            <Alert variant="filled" severity="error">
              {message}
            </Alert>
          )}
        </Stack>

        <form className={classes.form} onSubmit={handleSubmit}>
          <div className="name-form" style={{ gap: "20px" }}>
            <TextField
              label="ID"
              name="id"
              fullWidth
              value={studentData.id}
              onChange={handleChange}
              margin="normal"
              required
              // style={{ marginRight: "20px" }}
            />
            <TextField
              label="First Name"
              name="firstName"
              fullWidth
              value={studentData.firstName}
              onChange={handleChange}
              margin="normal"
              required
              // style={{ marginRight: "20px" }}
            />
            <TextField
              label="Middle Name"
              name="middleName"
              fullWidth
              value={studentData.middleName}
              onChange={handleChange}
              margin="normal"
            />

            <TextField
              label="Last Name"
              name="lastName"
              fullWidth
              value={studentData.lastName}
              onChange={handleChange}
              margin="normal"
              required
            />
          </div>
          <FormControl component="fieldset" margin="normal">
            <FormLabel component="legend">Sex</FormLabel>
            <RadioGroup
              aria-label="sex"
              name="sex"
              value={studentData.sex}
              onChange={handleChange}
              row
            >
              <FormControlLabel value="male" control={<Radio />} label="Male" />
              <FormControlLabel
                value="female"
                control={<Radio />}
                label="Female"
              />
            </RadioGroup>
          </FormControl>
          <br />
          {/* <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <FormControl style={{ width: "80%" }}>
                <InputLabel id="demo-simple-select-label">
                  Department
                </InputLabel>
                <Select
                  name="department"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={studentData.department}
                  label="Department"
                  onChange={handleChange}
                >
                  {departments?.map((dept) => (
                    <MenuItem
                      key={dept._id}
                      value={dept._id}
                      sx={{
                        // height: "40px",
                        textAlign: "left",
                      }}
                    >
                      {dept.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <FormControl style={{ width: "80%" }}>
                <InputLabel id="demo-simple-select-label">Program</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  name="program"
                  id="demo-simple-select"
                  value={studentData.program}
                  label="Department"
                  onChange={handleChange}
                >
                  {programData?.map((prog) => (
                    <MenuItem
                      key={prog._id}
                      value={prog._id}
                      sx={{
                        // height: "40px",
                        textAlign: "left",
                      }}
                    >
                      {prog.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <FormControl style={{ width: "80%" }}>
                <InputLabel id="demo-simple-select-label">Batch</InputLabel>
                <Select
                  name="batch"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={studentData.batch}
                  label="Department"
                  onChange={handleChange}
                >
                  {batchData?.map((bach) => (
                    <MenuItem
                      key={bach._id}
                      value={bach._id}
                      sx={{
                        // height: "40px",
                        textAlign: "left",
                      }}
                    >
                      {bach.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <FormControl style={{ width: "80%" }}>
                <InputLabel id="demo-simple-select-label">Modality</InputLabel>
                <Select
                  name="modality"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={studentData.modality}
                  label="Department"
                  onChange={handleChange}
                >
                  {modalities?.map((mod) => (
                    <MenuItem
                      key={mod._id}
                      value={mod._id}
                      sx={{
                        // height: "40px",
                        textAlign: "left",
                      }}
                    >
                      {mod.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid> */}
          <TextField
            label="Password"
            name="password"
            type={showPassword ? "text" : "password"}
            value={studentData.password}
            onChange={handleChange}
            margin="normal"
            required
            InputProps={{
              endAdornment: (
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              ),
            }}
            style={{ width: "30%" }}
          />
          <br />
          <FormControl margin="normal" style={{ width: "30%" }}>
            <FormLabel>Role</FormLabel>
            <Select
              name="role"
              value={studentData.role}
              onChange={handleChange}
            >
              {/* <MenuItem value="student">Student</MenuItem> */}
              {localStorage.getItem("role") === "college" && (
                <MenuItem value="super-admin">Super Admin</MenuItem>
              )}
              {localStorage.getItem("role") === "super-Admin" && (
                <MenuItem value="admin">Admin</MenuItem>
              )}
            </Select>
          </FormControl>
          <br />
          <br />
          <br />
          <div style={{ width: "100%", textAlign: "center" }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.submitButton}
            >
              Register
            </Button>
          </div>
        </form>
      </Container>
      <SuperAdminList />
    </div>
  );
};

export default RegisterAdmin;
