import FormControl, { useFormControl } from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormHelperText from "@mui/material/FormHelperText";
import { IoMdAddCircleOutline } from "react-icons/io";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useEffect, useState } from "react";
import { Alert, Button, Card, Container, Stack } from "@mui/material";
import axios from "axios";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function DepartmentForm() {
  const [isAdd, setIsAdd] = useState(false);
  const [isEdit, setIsEdit] = useState({});
  const [editedData, setEditedData] = useState({ id: "", name: "" });
  const [deleteData, setDeleteData] = useState({ id: "", name: "" });
  const [departments, setDepartments] = useState([]);
  const [newDepartment, setNewDepartment] = useState("");
  const [flag, setFlag] = useState(false);
  const [open, setOpen] = useState(false);

  const [message, setMessage] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [failur, setShowFailur] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowFailur(false);
      setShowPopup(false);
    }, 5000);
  }, [showPopup, failur]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    const fetchDepartment = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/department`
        );
        setDepartments(response.data);
      } catch (error) {
        console.error("Error fetching user:", error.message);
      }
    };
    fetchDepartment();
  }, [flag]);

  const handleEdit = (id, name) => {
    setIsEdit((prevState) => ({
      // ...prevState,
      [id]: !prevState[id],
      id: id,
      name: name,
    }));

    // const department = departments.find((dept) => dept._id === id);
    setEditedData({ id, name });
  };

  const handleSave = async () => {
    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/department/${editedData.id}`,
        { name: editedData.name }
      );
      setIsEdit({});
      setEditedData({});
      setFlag(!flag);
      setShowPopup(true);
      setMessage("Department Edited Successfully!");

      // Optional: Refresh the department list or perform any other necessary actions
    } catch (error) {
      setShowFailur(true);
      setMessage("Error in editing department name already exist");
    }
  };

  const handleCreate = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/department`, {
        name: newDepartment,
      });
      setNewDepartment("");
      setFlag(!flag);
      setIsAdd(false);
      setShowPopup(true);
      setMessage("New Department created Successfully!");
      // Optional: Refresh the department list or perform any other necessary actions
    } catch (error) {
      setShowFailur(true);
      setMessage("Error in creating department name already exist");
    }
  };

  const handleChange = (e, id) => {
    setDepartments((prevDepartments) =>
      prevDepartments.map((dept) => {
        if (dept._id === id) {
          return { ...dept, name: e.target.value };
        }
        return dept;
      })
    );
    setEditedData((prev) => ({
      ...prev,
      name: e.target.value,
    }));
  };

  const handleDelete = async () => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/department/${deleteData.id}`
      );
      setNewDepartment("");
      setFlag(!flag);
      setIsAdd(false);
      handleClose();
      // toast.success("Department Deleted Successfully!");
      setShowPopup(true);
      setMessage("Department Deleted Successfully!");
      // Optional: Refresh the department list or perform any other necessary actions
    } catch (error) {
      handleClose();
      setShowFailur(true);
      setMessage("Error in deleting department name already exist");
    }
  };
  return (
    <Container>
      <h2>Department Registration</h2>
      <Stack sx={{ width: "100%" }} spacing={2} style={{ marginTop: "30px" }}>
        {showPopup && (
          <Alert variant="filled" severity="success">
            {message}
          </Alert>
        )}

        {failur && (
          <Alert variant="filled" severity="error">
            {message}
          </Alert>
        )}
      </Stack>
      <Card
        style={{
          padding: "3rem",
          display: "flex",
          flexDirection: "column",
          gap: "40px",
          width: "90%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            maxHeight: "300px",
            overflowY: "auto",
          }}
        >
          {departments &&
            departments.map((dept) => (
              <FormControl
                key={dept._id}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "10px",
                }}
              >
                <OutlinedInput
                  value={dept?.name}
                  disabled={!isEdit[dept._id]}
                  onChange={(e) => handleChange(e, dept._id)}
                />
                {!(isEdit.id == dept._id) && (
                  <DeleteIcon
                    style={{
                      height: "30px",
                      width: "30px",
                      color: "#ed3e3b",
                      cursor: "pointer",
                    }}
                    // onClick={() => handleDelete(dept?._id, dept?.name)}
                    onClick={() => {
                      setDeleteData({ id: dept?._id, name: dept?.name });
                      handleClickOpen();
                    }}
                  />
                )}
                {isEdit.id == dept._id ? (
                  <Button
                    variant="contained"
                    style={{ height: "40px", background: "#bcbec2" }}
                    onClick={() => {
                      setIsEdit({});
                      setFlag(!flag);
                    }}
                  >
                    Cancel
                  </Button>
                ) : (
                  <EditIcon
                    style={{
                      height: "30px",
                      width: "30px",
                      color: "#0e67e3",
                      cursor: "pointer",
                    }}
                    onClick={() => handleEdit(dept?._id, dept?.name)}
                  />
                )}
                {isEdit.id == dept._id && (
                  <Button
                    variant="contained"
                    style={{ height: "40px" }}
                    onClick={handleSave}
                  >
                    Save
                  </Button>
                )}
              </FormControl>
            ))}
        </div>
        <form noValidate autoComplete="off" onSubmit={handleCreate}>
          <FormControl
            sx={{ width: "auto" }}
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "20px",
              alignItems: "center",
              // justifyContent: "center",
            }}
          >
            {isAdd && (
              <OutlinedInput
                placeholder="Enter department"
                onChange={(e) => setNewDepartment(e.target.value)}
              />
            )}
            {!isAdd && (
              <IoMdAddCircleOutline
                style={{ height: "40px", width: "40px" }}
                onClick={() => setIsAdd(true)}
              />
            )}
            {isAdd && (
              <RemoveCircleOutlineIcon
                style={{ height: "40px", width: "40px" }}
                onClick={() => setIsAdd(false)}
              />
            )}
            {isAdd && (
              <Button
                variant="contained"
                color="success"
                style={{ height: "40px" }}
                type="submit"
              >
                Create
              </Button>
            )}
          </FormControl>
        </form>
      </Card>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setDeleteData({});
              handleClose();
            }}
          >
            Cancel
          </Button>
          <Button
            autoFocus
            style={{ background: "red" }}
            onClick={handleDelete}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
