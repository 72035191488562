import { takeLatest, put, call } from "redux-saga/effects";
import { startFetch, fetchSuccess, fetchFailure } from "./question_reducer";
import { getServerData } from "../helper/helper";

const fetchQuestion = async (id) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/questions/${id}`
  );
  return response.json();
};

function* fetchQuestionsSaga(action) {
  const { examId } = action.payload;
  try {
    const questions = yield call(fetchQuestion, examId);
    yield put(fetchSuccess(questions));
  } catch (error) {
    yield put(fetchFailure(error.message));
  }
}

export function* watchFetchQuestions() {
  yield takeLatest("questions/startFetch", fetchQuestionsSaga);
}
