import ExamDetail from "./ResultCard";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import axios from "axios";
import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {
  Button,
  CardActionArea,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import * as Action from "../../../redux/question_reducer";
import { QueryData } from "../../../hooks/FetchQuestion";
const modalities = [
  { name: "Regular", _id: "Regular" },
  { name: "Extension", _id: "Extension" },
  { name: "Weekend", _id: "Weekend" },
];
const status = [
  { name: "All", _id: "" },
  { name: "Active", _id: true },
  { name: "Not Active", _id: false },
];
const taken = [
  { name: "All", _id: "" },
  { name: "Taken", _id: true },
  { name: "Not Taken", _id: false },
];
export default function ExamResultList({ openModal, setOpenModal }) {
  const [examList, setExamList] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [departments, setDepartmets] = useState([]);
  const [programData, setPrograms] = useState([]);
  const [batchData, setBatchs] = useState([]);
  const queryData = useSelector((state) => state.questions.data);

  const [data, setData] = useState({
    department: queryData?.department ? queryData.department : "",
    program: queryData?.program ? queryData.program : "",
    batch: queryData?.batch ? queryData.batch : "",
    modality: queryData?.modality ? queryData.modality : "",
    status: queryData?.status ? queryData.status : "",
    takenStatus: queryData?.takenStatus ? queryData.takenStatus : "",
  });

  const [selectedExamId, setSelectedExamId] = useState("");
  const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] =
    useState(false);

  const handleDeleteClick = (examId) => {
    setSelectedExamId(examId);
    setDeleteConfirmationModalOpen(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/question-delete/${selectedExamId}`
      );
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/questions`
      );
      setExamList(response.data);
    } catch (error) {
      console.error("Error deleting exam:", error.message);
    }
    setDeleteConfirmationModalOpen(false);
  };
  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/questions?program=${
            queryData.program
          }&department=${queryData.department}&modality=${
            queryData.modality
          }&batch=${queryData.batch}&status=${
            queryData.status
          }&expire=${false}&takenStatus=${queryData.takenStatus}`
        );
        setExamList(response.data);
      } catch (error) {
        console.error("Error fetching questions:", error.message);
      }
    };
    fetchQuestions();
  }, [queryData]);

  useEffect(() => {
    const fetchDepartment = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/department`
        );
        setDepartmets(response.data);
      } catch (error) {
        console.error("Error fetching department:", error.message);
      }
    };
    const fetchProgram = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/program`
        );
        setPrograms(response.data);
      } catch (error) {
        console.error("Error fetching program:", error.message);
      }
    };
    const fetchBatch = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/batch`
        );
        setBatchs(response.data);
      } catch (error) {
        console.error("Error fetching batch:", error.message);
      }
    };
    fetchBatch();
    fetchProgram();
    fetchDepartment();
  }, []);

  const handleDataChange = (event) => {
    const { name, value } = event.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    dispatch(QueryData(data));
  }, [data]);

  return (
    <Grid
      container
      item
      xs={12}
      md={12}
      lg={12}
      xl={12}
      style={{
        padding: "30px",
        backgroundColor: "white",
        width: "100%",
        height: "100%",
        margin: "1px",
        gap: "20px",
        overflowY: "auto",
        paddingBottom: "90px",
      }}
    >
      <div
        style={{
          width: "100%",
          // marginBottom: "30px",
          position: "sticky",
          top: "1px",
          background: "white",
          // maxHeight: "auto",
          zIndex: "1000",
          padding: "10px",
        }}
      >
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 1 }}>
          <Grid item xs={12} sm={12} md={6} lg={2}>
            <FormControl style={{ width: "80%" }}>
              <InputLabel id="demo-simple-select-label">Department</InputLabel>
              <Select
                name="department"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={data.department}
                label="Department"
                onChange={handleDataChange}
              >
                <MenuItem
                  value=""
                  sx={{
                    height: "40px",
                    textAlign: "left",
                  }}
                >
                  All
                </MenuItem>
                {departments?.map((dept) => (
                  <MenuItem
                    key={dept._id}
                    value={dept._id}
                    sx={{
                      height: "40px",
                      textAlign: "left",
                    }}
                  >
                    {dept.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={2}>
            <FormControl style={{ width: "80%" }}>
              <InputLabel id="demo-simple-select-label">Program</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                name="program"
                id="demo-simple-select"
                value={data.program}
                label="Department"
                onChange={handleDataChange}
              >
                <MenuItem
                  value=""
                  sx={{
                    height: "40px",
                    textAlign: "left",
                  }}
                >
                  All
                </MenuItem>
                {programData?.map((prog) => (
                  <MenuItem
                    key={prog._id}
                    value={prog._id}
                    sx={{
                      height: "40px",
                      textAlign: "left",
                    }}
                  >
                    {prog.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={2}>
            <FormControl style={{ width: "80%" }}>
              <InputLabel id="demo-simple-select-label">Batch</InputLabel>
              <Select
                name="batch"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={data.batch}
                label="Department"
                onChange={handleDataChange}
              >
                <MenuItem
                  value=""
                  sx={{
                    height: "40px",
                    textAlign: "left",
                  }}
                >
                  All
                </MenuItem>
                {batchData?.map((bach) => (
                  <MenuItem
                    key={bach._id}
                    value={bach._id}
                    sx={{
                      height: "40px",
                      textAlign: "left",
                    }}
                  >
                    {bach.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={2}>
            <FormControl style={{ width: "80%" }}>
              <InputLabel id="demo-simple-select-label">Modality</InputLabel>
              <Select
                name="modality"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={data.modality}
                label="Department"
                onChange={handleDataChange}
              >
                <MenuItem
                  value=""
                  sx={{
                    height: "40px",
                    textAlign: "left",
                  }}
                >
                  All
                </MenuItem>
                {modalities?.map((mod) => (
                  <MenuItem
                    key={mod._id}
                    value={mod._id}
                    sx={{
                      height: "40px",
                      textAlign: "left",
                    }}
                  >
                    {mod.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={2}>
            <FormControl style={{ width: "80%" }}>
              <InputLabel id="demo-simple-select-label">Status</InputLabel>
              <Select
                name="status"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={data.status}
                label="Status"
                onChange={handleDataChange}
              >
                {status?.map((stat) => (
                  <MenuItem
                    key={stat._id}
                    value={stat._id}
                    sx={{
                      height: "40px",
                      textAlign: "left",
                    }}
                  >
                    {stat.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={2}>
            <FormControl style={{ width: "80%" }}>
              <InputLabel id="demo-simple-select-label">Taken</InputLabel>
              <Select
                name="takenStatus"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={data.takenStatus}
                label="Taken"
                onChange={handleDataChange}
              >
                {taken?.map((take) => (
                  <MenuItem
                    key={take._id}
                    value={take._id}
                    sx={{
                      height: "40px",
                      textAlign: "left",
                    }}
                  >
                    {take.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </div>
      <Grid
        container
        style={{
          background: "#fff",
          gap: "20px",
          overflowY: "auto",
        }}
        xs={12}
        md={12}
        lg={12}
        xl={12}
        direction="column"
      >
        <Grid
          style={{
            background: "#f5f5f5",
            padding: "1rem",
            border: "1px solid #cccccc",
          }}
          xs={12}
          md={12}
          lg={12}
          xl={12}
        >
          <h3 style={{ fontWeight: "lighter" }}>Exams</h3>
          <Grid
            container
            style={{
              background: "#f5f5f5",
              padding: "1rem",
              width: "100%",
              gap: "20px",
              marginTop: "-20px",
            }}
            direction="row"
          >
            {examList &&
              examList?.map((lesson, i) => (
                <Card
                  sx={{
                    maxHeight: 400,
                    maxWidth: 300,
                    backgroundColor: "#fff",
                  }}
                  key={i}
                  style={{ float: "right" }}
                >
                  <CardActionArea>
                    <CardContent>
                      <ExamDetail
                        project={lesson}
                        openModal={openModal}
                        setOpenModal={setOpenModal}
                        takenStatus={data.takenStatus}
                      />
                    </CardContent>
                  </CardActionArea>
                  <div style={{ width: "100%" }}>
                    <Button
                      variant="contained"
                      color="success"
                      onClick={() =>
                        navigate(`/question-detail/${lesson?._id}`)
                      }
                      style={{
                        width: "50px",
                        float: "right",
                        margin: "0 10px 10px 0",
                      }}
                    >
                      Review
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => handleDeleteClick(lesson?._id)}
                      style={{
                        width: "50px",
                        float: "right",
                        margin: "0 10px 10px 0",
                      }}
                    >
                      Delete
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => navigate(`/exam/edit/${lesson?._id}`)}
                      style={{
                        width: "50px",
                        float: "right",
                        margin: "0 10px 10px 0",
                      }}
                    >
                      Edit
                    </Button>
                    {/* <MoreVertIcon
                      style={{
                        float: "right",
                        margin: "10px",
                        cursor: "pointer",
                      }}
                    /> */}
                  </div>
                </Card>
              ))}
          </Grid>
        </Grid>
      </Grid>
      {/* <Grid
        container
        style={{
          // width: "23%",
          background: "#fff",
          gap: "20px",
        }}
        direction="column"
        // spacing={3}
        xs={12}
        md={3}
        lg={3}
        xl={2}
      >
        <div
          style={{
            width: "100%",
            height: "30%",
            backgroundColor: "#f5f5f5",
            border: "1px solid #cccccc",
          }}
        >
          <CardContent>
            <p>Upcomming Events</p>
            <h3>There are no up comming events</h3>
          </CardContent>
        </div>
        <div
          style={{
            width: "100%",
            height: "auto",
            backgroundColor: "#f5f5f5",
            border: "1px solid #cccccc",
          }}
        >
          <CardContent>
            <p>Calendar</p>
            <DateCalendarValue />
          </CardContent>
        </div>
      </Grid> */}
      <Modal
        open={deleteConfirmationModalOpen}
        onClose={() => setDeleteConfirmationModalOpen(false)}
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            padding: "20px",
          }}
        >
          <h2>Are you sure you want to delete this exam?</h2>
          <div style={{ width: "100%" }}>
            <div style={{ float: "right" }}>
              <Button
                variant="contained"
                color="error"
                onClick={handleDeleteConfirm}
              >
                Delete
              </Button>
              <Button
                variant="contained"
                onClick={() => setDeleteConfirmationModalOpen(false)}
                style={{ marginLeft: "10px" }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </Grid>
  );
}
