import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import {
  FormControl,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import StudentListTable from "./StudentTable";
import { QueryStudent } from "../../../hooks/FetchQuestion";
import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  form: {
    marginTop: theme.spacing(2),
  },
  submitButton: {
    marginTop: theme.spacing(2),
  },
}));

const modalities = [
  { name: "Regular", _id: "Regular" },
  { name: "Extension", _id: "Extension" },
  { name: "Weekend", _id: "Weekend" },
];

const StudentList = () => {
  const [studentData, setStudentData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [departments, setDepartmets] = useState([]);
  const [programData, setPrograms] = useState([]);
  const [batchData, setBatchs] = useState([]);
  const [sectionData, setSections] = useState([]);
  const [flag, setFlag] = useState(false);
  const studentQuery = useSelector((state) => state.questions.studentQuery);

  const [data, setData] = useState({
    department: studentQuery?.department ? studentQuery.department : "",
    program: studentQuery?.program ? studentQuery.program : "",
    batch: studentQuery?.batch ? studentQuery.batch : "",
    modality: studentQuery?.modality ? studentQuery.modality : "",
    section: studentQuery?.section ? studentQuery.section : "",
  });
  const dispatch = useDispatch();
  useEffect(() => {
    async function fetchStudent() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/students?program=${studentQuery.program}&department=${studentQuery.department}&modality=${studentQuery.modality}&batch=${studentQuery.batch}&role=student&section=${studentQuery.section}`
        );
        const res = response.data;
        // Process the retrieved data here
        setStudentData(res);
      } catch (error) {
        // Handle error
        console.error("Error fetching student data:", error);
      }
    }

    fetchStudent();
  }, [studentQuery, flag]);
  const handleSearchInputChange = (event) => {
    const inputValue = event.target.value.toLowerCase();
    setSearchValue(inputValue);
    // setFilteredData(filteredResults);
  };
  const handleDataChange = (event) => {
    const { name, value } = event.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  useEffect(() => {
    const fetchDepartment = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/department`
        );
        setDepartmets(response.data);
      } catch (error) {
        console.error("Error fetching user:", error.message);
      }
    };
    const fetchProgram = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/program`
        );
        setPrograms(response.data);
      } catch (error) {
        console.error("Error fetching user:", error.message);
      }
    };
    const fetchBatch = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/batch`
        );
        setBatchs(response.data);
      } catch (error) {
        console.error("Error fetching user:", error.message);
      }
    };
    const fetchSection = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/section`
        );
        setSections(response.data);
      } catch (error) {
        console.error("Error fetching user:", error.message);
      }
    };
    fetchSection();
    fetchBatch();
    fetchProgram();
    fetchDepartment();
    for (let x = 0; x < 1000000000; x++) {}
    // setUserAnswer(userData.answer);
  }, []);

  const filteredStudents =
    studentData &&
    studentData?.filter((student) => {
      const firstName = student?.firstName?.toLowerCase();
      const id = student?.id?.toString().toLowerCase();

      // Check if the first name or ID matches the search term
      return firstName?.includes(searchValue) || id?.includes(searchValue);
    });

  useEffect(() => {
    dispatch(QueryStudent(data));
  }, [data]);

  const classes = useStyles();

  return (
    <div maxWidth="sm" style={{ margin: "50px 60px" }}>
      <div style={{ width: "25%", marginBottom: "30px" }}>
        <TextField
          id="outlined-basic"
          label="Search"
          variant="outlined"
          onChange={handleSearchInputChange}
        />
      </div>
      <div style={{ width: "100%", marginBottom: "30px" }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} sm={12} md={6} lg={2.5}>
            <FormControl style={{ width: "80%" }}>
              <InputLabel id="demo-simple-select-label">Department</InputLabel>
              <Select
                name="department"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={data.department}
                label="Department"
                onChange={handleDataChange}
              >
                <MenuItem
                  value=""
                  sx={{
                    height: "40px",
                    textAlign: "left",
                  }}
                >
                  All
                </MenuItem>
                {departments?.map((dept) => (
                  <MenuItem
                    key={dept._id}
                    value={dept._id}
                    sx={{
                      height: "40px",
                      textAlign: "left",
                    }}
                  >
                    {dept.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={2.5}>
            <FormControl style={{ width: "80%" }}>
              <InputLabel id="demo-simple-select-label">Program</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                name="program"
                id="demo-simple-select"
                value={data.program}
                label="Department"
                onChange={handleDataChange}
              >
                <MenuItem
                  value=""
                  sx={{
                    height: "40px",
                    textAlign: "left",
                  }}
                >
                  All
                </MenuItem>
                {programData?.map((prog) => (
                  <MenuItem
                    key={prog._id}
                    value={prog._id}
                    sx={{
                      height: "40px",
                      textAlign: "left",
                    }}
                  >
                    {prog.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={2.5}>
            <FormControl style={{ width: "80%" }}>
              <InputLabel id="demo-simple-select-label">Batch</InputLabel>
              <Select
                name="batch"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={data.batch}
                label="Department"
                onChange={handleDataChange}
              >
                <MenuItem
                  value=""
                  sx={{
                    height: "40px",
                    textAlign: "left",
                  }}
                >
                  All
                </MenuItem>
                {batchData?.map((bach) => (
                  <MenuItem
                    key={bach._id}
                    value={bach._id}
                    sx={{
                      height: "40px",
                      textAlign: "left",
                    }}
                  >
                    {bach.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={2.5}>
            <FormControl style={{ width: "80%" }}>
              <InputLabel id="demo-simple-select-label">Modality</InputLabel>
              <Select
                name="modality"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={data.modality}
                label="Department"
                onChange={handleDataChange}
              >
                <MenuItem
                  value=""
                  sx={{
                    height: "40px",
                    textAlign: "left",
                  }}
                >
                  All
                </MenuItem>
                {modalities?.map((mod) => (
                  <MenuItem
                    key={mod._id}
                    value={mod._id}
                    sx={{
                      height: "40px",
                      textAlign: "left",
                    }}
                  >
                    {mod.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={2}>
            <FormControl style={{ width: "80%" }}>
              <InputLabel id="demo-simple-select-label">Section</InputLabel>
              <Select
                name="section"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={data.section}
                label="Section"
                onChange={handleDataChange}
              >
                <MenuItem
                  value=""
                  sx={{
                    height: "40px",
                    textAlign: "left",
                  }}
                >
                  All
                </MenuItem>
                {sectionData?.map((sec) => (
                  <MenuItem
                    key={sec._id}
                    value={sec._id}
                    sx={{
                      height: "40px",
                      textAlign: "left",
                    }}
                  >
                    {sec.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </div>
      <StudentListTable
        studentData={filteredStudents}
        setFlag={setFlag}
        flag={flag}
      />
    </div>
  );
};

export default StudentList;
