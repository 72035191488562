import React from "react";
import Home from "./home/Home";

const AdminDashboard = () => {
  return (
    <>
      <Home />
    </>
  );
};

export default AdminDashboard;
