/* eslint-disable react/prop-types */
import { DataGrid } from "@mui/x-data-grid";
import { theme } from "./theme";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import DeleteModal from "./DeleteModal";
import { useEffect, useMemo, useState } from "react";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { Button, colors } from "@mui/material";
import { CSVLink } from "react-csv";
import * as XLSX from "xlsx";
import FormDialog from "./ui/DialogueForm";

export default function DataTable({ studentData, examId, setFlag, flag }) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [deleteId, setId] = useState("");
  const [message, setMessage] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [failur, setShowFailur] = useState(false);
  const [exportData, setExportData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [isCheatingMessage, setisCheatingMessage] = useState("");
  const [openCheating, setOpenCheating] = useState(false);
  const [cheatId, setCheatId] = useState();
  const handleClickOpen = (id) => {
    setOpen(true);
    setId(id);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const columns = [
    { field: "id", headerName: "ID", width: 150 },
    { field: "firstName", headerName: "First name", width: 130 },
    { field: "middleName", headerName: "Middle name", width: 130 },
    { field: "lastName", headerName: "Last name", width: 130 },
    {
      field: "department",
      headerName: "Department",
      sortable: false,
      width: 210,
    },
    {
      field: "program",
      headerName: "Program",
      sortable: false,
      width: 210,
    },
    {
      field: "batch",
      headerName: "Batch",
      sortable: false,
      width: 210,
    },
    {
      field: "modality",
      headerName: "Modality",
      sortable: false,
      width: 210,
    },
    { field: "sex", headerName: "Sex", width: 130 },
    {
      field: "examTime",
      headerName: "Total Time",
      width: 190,
    },
    {
      field: "usedTime",
      headerName: "Used Time",
      width: 190,
    },
    {
      field: "remainTime",
      headerName: "Remain Time",
      width: 190,
    },
    {
      field: "result",
      headerName: "Result",
      width: 160,
    },
    {
      field: "isCheating",
      headerName: "Cheating Report",
      width: 160,
      renderCell: (params) => (
        <div style={{ color: "red" }}>{params.row.isCheating}</div>
      ),
    },
    {
      field: "review",
      headerName: "Action",
      width: 150,
      renderCell: (params) => (
        <div>
          <Button
            variant="contained"
            onClick={() => {
              navigate(
                `/student/result-detail/${
                  params.row._id
                }/${examId}/${encodeURIComponent(params.row.id)}`
              );
            }}
          >
            Review
          </Button>
        </div>
      ),
    },
    {
      field: "delete",
      headerName: "Action",
      width: 150,
      renderCell: (params) => (
        <div>
          <Button
            variant="contained"
            onClick={() => {
              handleClickOpen(params.row._id);
            }}
            style={{ background: "red" }}
          >
            Delete
          </Button>
        </div>
      ),
    },
    {
      field: "clear",
      headerName: "Action",
      width: 150,
      renderCell: (params) => (
        <div>
          <Button
            variant="contained"
            onClick={() => handleCheatinOpen(params.row._id)}
          >
            Clear Report
          </Button>
        </div>
      ),
    },
  ];

  const handleCheatinClose = () => {
    setOpenCheating(false);
  };
  const handleCheatingMessage = (e) => {
    setisCheatingMessage(e.target.value);
  };
  const handleCheatinOpen = (id) => {
    setOpenCheating(true);
    setCheatId(id);
  };

  const getTimeDifference = (startTime, endTime) => {
    const padZero = (num) => (num < 10 ? `0${num}` : `${num}`);

    const preprocessTime = (timeString) => {
      if (timeString) {
        const [hours, minutes, seconds] = timeString.split(":");
        const paddedHours = padZero(Number(hours));
        const paddedMinutes = padZero(Number(minutes));
        const paddedSeconds = padZero(Number(seconds));

        return `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
      } else {
        return null;
      }
    };

    const processedStartTime = preprocessTime(startTime);
    const processedEndTime = preprocessTime(endTime);

    const start = new Date(`1970-01-01T${processedStartTime}Z`);
    const end = new Date(`1970-01-01T${processedEndTime}Z`);

    if (isNaN(start) || isNaN(end)) {
      return null;
    } else {
      const diff = Math.abs(end - start);
      const hours = Math.floor(diff / 3600000);
      const minutes = Math.floor((diff % 3600000) / 60000);
      const seconds = Math.floor((diff % 60000) / 1000);

      return `${hours}:${padZero(minutes)}:${padZero(seconds)}`;
    }
  };
  const rowss = [
    { id: 1, lastName: "Snow", firstName: "Jon", age: 35 },
    { id: 2, lastName: "Lannister", firstName: "Cersei", age: 42 },
  ];
  const rows = useMemo(() => {
    return studentData?.map((data) => ({
      _id: data.student._id,
      id: data.student.id,
      firstName: data.student.firstName,
      middleName: data.student.middleName,
      lastName: data.student.lastName,
      department: data.student.department.name,
      program: data.student.program.name,
      batch: data.student.batch.name,
      modality: data.student.modality,
      sex: data.student.sex,
      examTime: data.exam.examTime,
      usedTime: getTimeDifference(data.remainTime, data.exam.examTime),
      remainTime: data.remainTime,
      isCheating: data.isCheating,
      result: data.result,
    }));
  }, [studentData]);

  // const handleRowClick = (params) => {
  //   const studentId = params.row.id;
  //   navigate(`/Student/subjects/${studentId}`);
  // };
  const handleDelete = async () => {
    // const encodedId = encodeURIComponent(deleteId);
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/result/${deleteId}`
      );
      handleClose();
      setShowPopup(true);
      setMessage("Result deleted successsfully");

      setId("");
      setFlag(!flag);
    } catch (error) {
      setShowFailur(true);
      setMessage("error in deleting students, error");
      setId("");
      handleClose();
      // Handle the error appropriately
    }
  };
  const handleRowClick = (params) => {
    // const studentId = params.row._id;
    // navigate(`/student/result-detail/${studentId}`);
  };

  useEffect(() => {
    setTimeout(() => {
      setShowFailur(false);
      setShowPopup(false);
    }, 10000);
  }, [showPopup, failur]);

  const handleExportCSV = () => {
    setExportData(
      studentData.map((data) => ({
        Id: data.student.id,
        "First Name": data.student.firstName,
        "Middle Name": data.student.middleName,
        "Last Name": data.student.lastName,
        Department: data.student.department.name,
        Program: data.student.program.name,
        Batch: data.student.batch.name,
        Modality: data.student.modality,
        Sex: data.student.sex,
        "Used Time": getTimeDifference(data.remainTime, data.exam.examTime),
        CheatingStatus: data.isCheating,
        Result: data.result,
      }))
    );
    // if (studentData) {
    //   setExportData([
    //     Object.keys(studentData[0]).filter(
    //       (column) =>
    //         column !== "_id" &&
    //         column !== "password" &&
    //         column !== "examStatus" &&
    //         columns !== "answer" &&
    //         column !== "__v"
    //     ), // Header row
    //     ...studentData.map(
    //       ({ _id, password, examStatus, answer, __v, ...row }) =>
    //         Object.values(row)
    //     ),
    //   ]);
    // }
  };

  const handleExportExcel = () => {
    const formattedData = studentData.map((data) => ({
      Id: data.student.id,
      "First Name": data.student.firstName,
      "Middle Name": data.student.middleName,
      "Last Name": data.student.lastName,
      Department: data.student.department.name,
      Program: data.student.program.name,
      Batch: data.student.batch.name,
      Modality: data.student.modality,
      Sex: data.student.sex,
      "Used Time": getTimeDifference(data.remainTime, data.exam.examTime),
      Result: data.result,
    }));

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    XLSX.writeFile(workbook, "data.xlsx");
  };

  const submitReport = () => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/update-isCheating`;
    axios
      .post(`${apiUrl}/${cheatId}/${examId}`, {
        isCheatingMessage,
      })
      .then((response) => {
        setFlag(!flag);
      })
      .catch((error) => {
        console.error("Error updating student answer:", error);
      });
  };

  return (
    <div style={{ height: "auto", width: "100%" }}>
      <Stack sx={{ width: "100%" }} spacing={2} style={{ marginTop: "30px" }}>
        {showPopup && (
          <Alert variant="filled" severity="success">
            {message}
          </Alert>
        )}

        {failur && (
          <Alert variant="filled" severity="error">
            {message}
          </Alert>
        )}
      </Stack>
      <div style={{ marginTop: "20px", marginBottom: "20px" }}>
        <Button
          variant="contained"
          color="primary"
          style={{ marginRight: "20px" }}
          // onClick={handleExportCSV}
        >
          <CSVLink
            data={exportData}
            filename={"data.csv"}
            className="MuiButtonBase-root MuiButton-root MuiButton-contained"
            target="_blank"
            onClick={handleExportCSV}
            style={{ color: "white", textDecoration: "none" }}
          >
            Export to CSV
          </CSVLink>{" "}
        </Button>
        {/* <Button
          variant="contained"
          color="primary"
          style={{ marginRight: "20px" }}
          // onClick={handleExportCSV}
        >
          Export as CSV
        </Button> */}
        <Button variant="contained" color="primary" onClick={handleExportExcel}>
          Export as Excel
        </Button>
      </div>
      <StyledDataGrid
        rows={studentData ? rows : rowss}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 50 },
          },
        }}
        pageSizeOptions={[5, 10, 15, 20, 25, 50]}
        checkboxSelection
        onRowClick={handleRowClick}
      />
      {open && (
        <DeleteModal
          handleClickOpen={handleClickOpen}
          handleClose={handleClose}
          open={open}
          handleDelete={handleDelete}
        />
      )}
      <FormDialog
        examId={examId}
        openCheating={openCheating}
        setOpenCheating={setOpenCheating}
        handleCheatinOpen={handleCheatinOpen}
        handleCheatinClose={handleCheatinClose}
        submitReport={submitReport}
        handleCheatingMessage={handleCheatingMessage}
      />
    </div>
  );
}
const StyledDataGrid = styled(DataGrid)(() => ({
  maxWidth: "100vw",
  minWidth: "min-content",
  width: "100%",
  height: "692px",
  backgroundColor: theme.colors.white[9],
  fontWeight: 400,
  justifyContent: "space-evenly",
  "& .MuiDataGrid-toolbarContainer": {
    padding: ".8rem .5rem",
  },

  "& .MuiDataGrid-columnHeaders": {
    borderBottom: `1px solid ${theme.colors.border.default}`,
    backgroundColor: theme.colors.primary[0],
  },
  "& .MuiDataGrid-columnHeader": {
    borderBottom: `1px solid ${theme.colors.border.default}`,

    "& .MuiDataGrid-columnHeaderTitle": {
      padding: "0 auto",
      margin: "0 auto",
    },
  },

  "& .MuiDataGrid-row": {
    cursor: "pointer",
    backgroundColor: theme.colors.white[9],
    "&:hover": {
      backgroundColor: theme.colors.primary[2],
    },
    "&.Mui-selected": {
      backgroundColor: theme.colors.primary[2],
      "&:hover": {
        backgroundColor: theme.colors.primary[0],
      },
    },
  },
}));
