import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function FormDialog({
  examId,
  openCheating,
  setOpenCheating,
  handleCheatinOpen,
  handleCheatinClose,
  submitReport,
  handleCheatingMessage,
}) {
  return (
    <React.Fragment>
      <Dialog
        open={openCheating}
        onClose={handleCheatinClose}
        PaperProps={{
          component: "form",
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const email = formJson.email;
            handleCheatinClose();
          },
        }}
      >
        <DialogTitle>Report Cheating</DialogTitle>
        <DialogContent>
          <DialogContentText>Write your reason</DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="email"
            label=""
            type="text"
            fullWidth
            variant="standard"
            onChange={handleCheatingMessage}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCheatinClose}>Cancel</Button>
          <Button type="submit" onClick={submitReport}>
            Report
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
