// import { combineReducers, configureStore } from "@reduxjs/toolkit";

// /** call reducers */
// import questionReducer from "./question_reducer";
// import resultReducer from "./result_reducer";
// import createSagaMiddleware from "redux-saga";
// import rootSaga from "./rootSaga";

// const saga = createSagaMiddleware();
// // const store = configureStore({
// //   reducer: { research, auth },
// //   middleware: [saga],
// // });
// saga.run(rootSaga);

// const rootReducer = combineReducers({
//   questions: questionReducer,
//   result: resultReducer,
// });

// export default configureStore({ reducer: rootReducer, middleware: [saga] });

import {
  combineReducers,
  configureStore,
  applyMiddleware,
} from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { thunk } from "redux-thunk";
import rootSaga from "./rootSaga";

/** call reducers */
import questionReducer from "./question_reducer";
import resultReducer from "./result_reducer";

const sagaMiddleware = createSagaMiddleware();

const rootReducer = combineReducers({
  questions: questionReducer,
  result: resultReducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: [thunk, sagaMiddleware],
});

sagaMiddleware.run(rootSaga);

export default store;
