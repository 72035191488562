import * as React from "react";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";

export default function TimePickerViews({ value, onChange }) {
  const handleTimeChange = (newValue) => {
    const formattedValue = dayjs(newValue).format("HH:mm:ss");
    onChange(formattedValue);
  };

  return (
    <div style={{ marginTop: "-30px" }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer
          components={[
            "MobileTimePicker",
            "MobileTimePicker",
            "MobileTimePicker",
          ]}
        >
          <DemoItem>
            <TimePicker
              views={["hours", "minutes", "seconds"]}
              ampm={false} // Set ampm to false for 24-hour format
              value={value}
              onChange={handleTimeChange}
            />
          </DemoItem>
        </DemoContainer>
      </LocalizationProvider>
    </div>
  );
}
