import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export function ResultModal({
  open,
  handleClickOpen,
  handleClose,
  handleDelete,
}) {
  //   const [open, setOpen] = React.useState(false);

  //   const handleClickOpen = () => {
  //     setOpen(true);
  //   };

  //   const handleClose = () => {
  //     setOpen(false);
  //   };

  return (
    <React.Fragment>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open alert dialog
      </Button> */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ height: "30%", width: "50%", margin: "auto" }}
      >
        <DialogTitle id="alert-dialog-title" style={{ fontSize: "24px" }}>
          {"Are you sure you want to submit you result?"}
        </DialogTitle>
        <DialogContent></DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            style={{ background: "red", color: "white", fontSize: "16px" }}
          >
            Go Back
          </Button>
          <Button
            onClick={handleDelete}
            autoFocus
            style={{ background: "green", color: "white", fontSize: "16px" }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
