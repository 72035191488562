import "./table.scss";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";

const List = ({ data, date }) => {
  const navigate = useNavigate();

  return (
    <TableContainer component={Paper} className="table">
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className="tableCell">Name</TableCell>
            <TableCell className="tableCell">Course</TableCell>
            <TableCell className="tableCell">Department</TableCell>
            <TableCell className="tableCell">Modality</TableCell>
            <TableCell className="tableCell">Program</TableCell>
            <TableCell className="tableCell">Batch</TableCell>
            <TableCell className="tableCell">Time</TableCell>
            <TableCell className="tableCell">Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data &&
            data?.map((row, i) => (
              <TableRow
                key={row?._id}
                onClick={() => navigate(`/student-result/${row._id}`)}
              >
                <TableCell className="tableCell">{row?.name}</TableCell>
                <TableCell className="tableCell">
                  {/* <div className="cellWrapper"> */}
                  {/* <img src={row.img} alt="" className="image" /> */}
                  {row?.course?.name}
                  {/* </div> */}
                </TableCell>
                <TableCell className="tableCell">
                  {row?.department?.map((dept) => (
                    <div>{dept.name}</div>
                  ))}
                </TableCell>
                <TableCell className="tableCell">
                  {row?.modality?.map((dept) => (
                    <div>{dept.name}</div>
                  ))}
                </TableCell>
                <TableCell className="tableCell">
                  {" "}
                  {row?.program?.map((dept) => (
                    <div>{dept.name}</div>
                  ))}
                </TableCell>
                <TableCell className="tableCell">
                  {row?.batch?.map((dept) => (
                    <div>{dept.name}</div>
                  ))}
                </TableCell>
                <TableCell className="tableCell">
                  <span className={`status Approved`}>{row.examTime}</span>
                </TableCell>
                <TableCell className="tableCell">
                  <span className={`status Approved`}>
                    {row.status ? "Active" : "Not Active"}
                  </span>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default List;
