import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Stack,
  TextField,
} from "@mui/material";
import FormControlContext from "@mui/material/FormControl/FormControlContext";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";

const Modalpopup = ({
  open,
  functionopenpopup,
  setOpen,
  handleNavigate,
  password,
  setPassword,
  message,
  alert,
  closeMOdal,
}) => {
  return (
    <div style={{ textAlign: "center" }}>
      <Dialog
        // fullScreen
        open={open}
        onClose={closeMOdal}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          Enter Exam Password{" "}
          <IconButton onClick={closeMOdal} style={{ float: "right" }}>
            <CloseIcon color="primary"></CloseIcon>
          </IconButton>{" "}
        </DialogTitle>
        <DialogContent>
          {/* <DialogContentText>Do you want remove this user?</DialogContentText> */}
          <Stack spacing={2} margin={2}>
            {/* <TextField variant="outlined" label="Username"></TextField> */}
            {alert && <h4 style={{ color: "red" }}>{message}</h4>}
            <TextField
              variant="outlined"
              type="password"
              label="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            ></TextField>
            {/* <TextField variant="outlined" label="Email"></TextField>
            <TextField variant="outlined" label="Phone"></TextField> */}
            {/* <FormControlLabel
              control={<Checkbox defaultChecked color="primary"></Checkbox>}
              label="Agree terms & conditions"
            ></FormControlLabel> */}
            <Button
              color="primary"
              variant="contained"
              onClick={handleNavigate}
            >
              Verify
            </Button>
          </Stack>
        </DialogContent>
        <DialogActions>
          {/* <Button color="success" variant="contained">Yes</Button>
                    <Button onClick={closepopup} color="error" variant="contained">Close</Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Modalpopup;
