import React, { useEffect, useState } from "react";
import axios from "axios";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Button, TextField } from "@material-ui/core";
const programs = ["Regular", "Masters", "Extension"];
const ExcelExamUploader = ({
  courseList,
  questionData,
  setMessage,
  setShowPopup,
  setShowFailur,
  handleProgramChange,
  handleTimeChange,
  time,
  program,
  department,
  handleDepartmentChange,
  departments,
  password,
  handlePasswordChange,
}) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [examId, setExamId] = useState("");
  const [exams, setExams] = useState([]);

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/questions`
        );
        const questionsData = response.data;
        setExams(questionsData);
      } catch (error) {
        console.error("Error fetching questions:", error.message);
      }
    };
    fetchQuestions();
  }, []);

  const handleFileUpload = () => {
    // const id = courseList?.find((courses) => courses.type === course)?._id;
    // Create a FormData object
    const formData = new FormData();
    formData.append("file", selectedFile);

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/question-upload/${examId}`,
        formData
      )
      .then((response) => {
        setMessage("Exam Uploaded");
        setShowPopup(true);
      })
      .catch((error) => {
        setMessage("Error uploading file:", error);
        setShowFailur(true);
      });
  };
  return (
    <div>
      <h3>Excel File Uploader</h3>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <input type="file" onChange={handleFileSelect} />
        <FormControl style={{ width: "33%" }}>
          <InputLabel id="demo-simple-select-label">Exam List</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={examId}
            label="Department"
            onChange={(e) => setExamId(e.target.value)}
          >
            {exams?.map((dept) => (
              <MenuItem
                key={dept._id}
                value={dept._id}
                sx={{
                  height: "40px",
                  // textAlign: "left",
                }}
              >
                {dept.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={handleFileUpload}
          style={{ height: "90%" }}
        >
          Upload Excel File
        </Button>
      </div>
    </div>
  );
};

export default ExcelExamUploader;
