import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
// import {
//   TextField,
//   Radio,
//   RadioGroup,
//   FormControl,
//   FormLabel,
//   FormControlLabel,
//   Select,
//   MenuItem,
//   Button,
//   Container,
//   Grid,
// } from "@material-ui/core";
import {
  TextField,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  FormControlLabel,
  Select,
  MenuItem,
  Button,
  Container,
  Grid,
} from "@mui/material";
import axios from "axios";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { useNavigate, useParams } from "react-router-dom";
import { InputLabel } from "@mui/material";
import { batch } from "react-redux";
import ChangePassword from "./Admin/ChangePassword";

const useStyles = makeStyles((theme) => ({
  form: {
    marginTop: theme.spacing(2),
  },
  submitButton: {
    marginTop: theme.spacing(2),
  },
}));
const modalities = [
  { name: "Regular", _id: "Regular" },
  { name: "Extension", _id: "Extension" },
  { name: "Weekend", _id: "Weekend" },
];

const UpdateForm = () => {
  const classes = useStyles();

  const [studentData, setStudentData] = useState({
    id: "",
    firstName: "",
    middleName: "",
    lastName: "",
    sex: "",
    age: 20,
    department: "",
    program: "",
    batch: "",
    modality: "",
    role: "student",
    remainTime: "",
    result: null,
    answer: [],
  });
  const [message, setMessage] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [failur, setShowFailur] = useState(false);
  const [userData, setUserData] = useState(null);
  const [sectionData, setSections] = useState([]);

  const [departments, setDepartments] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [batches, setBatches] = useState([]);

  const navigate = useNavigate();

  const { id } = useParams();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setStudentData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const fetchUser = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/students/${id}`
      );
      setUserData(response.data);
      setStudentData(response.data);
      setStudentData({
        id: response.data.id,
        firstName: response.data.firstName,
        middleName: response.data.middleName,
        lastName: response.data.lastName,
        sex: response.data.sex,
        department: response.data.department._id,
        program: response.data.program._id,
        batch: response.data.batch._id,
        role: "student",
      });
    } catch (error) {
      console.error("Error fetching user:", error.message);
    }
  };

  useEffect(() => {
    const fetchSection = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/section`
        );
        setSections(response.data);
      } catch (error) {
        console.error("Error fetching user:", error.message);
      }
    };
    fetchSection();
    fetchUser();
    // for (let x = 0; x < 1000000000; x++) {}
    // setUserAnswer(userData.answer);
  }, []);
  const handleUpdate = (e) => {
    e.preventDefault();

    // const studentId = "65b3c810e22ef1cb97b43cf0";
    const apiUrl = `${process.env.REACT_APP_API_URL}/students`;
    // const parsedExamTime = parseInt(newExamTime);

    axios
      .put(`${apiUrl}/${id}`, studentData)
      .then((response) => {
        setShowPopup(true);
        setMessage("Student updated successfully");
        // Handle success and update UI if needed
      })
      .catch((error) => {
        setShowFailur(true);
        setMessage("error in updating student ", error);
        console.error("Error updating student examTime:", error);
        // Handle error and display error message if needed
      });
  };

  useEffect(() => {
    setTimeout(() => {
      setShowFailur(false);
      setShowPopup(false);
    }, 10000);
  }, [showPopup, failur]);

  useEffect(() => {
    const fetchDepartment = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/department`
        );
        setDepartments(response.data);
      } catch (error) {
        console.error("Error fetching user:", error.message);
      }
    };
    fetchDepartment();
  }, []);
  useEffect(() => {
    const fetchBatch = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/batch`
        );
        setBatches(response.data);
      } catch (error) {
        console.error("Error fetching user:", error.message);
      }
    };
    fetchBatch();
  }, []);

  useEffect(() => {
    const fetchProgram = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/program`
        );
        setPrograms(response.data);
      } catch (error) {
        console.error("Error fetching user:", error.message);
      }
    };
    fetchProgram();
  }, []);
  return (
    <Container maxWidth="xl" style={{ paddingTop: "3%" }}>
      <div style={{ width: "100%" }}>
        <Button
          style={{ background: "red", color: "white", float: "right" }}
          onClick={() => navigate(-1)}
        >
          Back
        </Button>
      </div>
      <Stack sx={{ width: "100%" }} spacing={2} style={{ marginTop: "30px" }}>
        {showPopup && (
          <Alert variant="filled" severity="success">
            {message}
          </Alert>
        )}

        {failur && (
          <Alert variant="filled" severity="error">
            {message}
          </Alert>
        )}
      </Stack>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
        }}
      >
        <div style={{ width: "60%" }}>
          <form className={classes.form} onSubmit={handleUpdate}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
              }}
            >
              <TextField
                label="ID"
                name="id"
                fullWidth
                value={studentData?.id}
                onChange={handleChange}
                margin="normal"
                required
                style={{ marginRight: "20px" }}
              />
              <TextField
                label="First Name"
                name="firstName"
                fullWidth
                value={studentData?.firstName}
                onChange={handleChange}
                margin="normal"
                required
                style={{ marginRight: "20px" }}
              />
              <TextField
                label="Middle Name"
                name="middleName"
                fullWidth
                value={studentData?.middleName}
                onChange={handleChange}
                margin="normal"
              />
            </div>
            <TextField
              label="Last Name"
              name="lastName"
              fullWidth
              value={studentData?.lastName}
              onChange={handleChange}
              margin="normal"
              required
            />
            <FormControl component="fieldset" margin="normal">
              <FormLabel component="legend">Sex</FormLabel>
              <RadioGroup
                aria-label="sex"
                name="sex"
                value={studentData?.sex}
                onChange={handleChange}
                row
              >
                <FormControlLabel
                  value="male"
                  control={<Radio />}
                  label="Male"
                />
                <FormControlLabel
                  value="female"
                  control={<Radio />}
                  label="Female"
                />
              </RadioGroup>
            </FormControl>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              style={{ marginTop: "30px", marginBottom: "20px" }}
            >
              <Grid item xs={12} sm={12} md={6} lg={3}>
                <FormControl style={{ width: "80%" }}>
                  <InputLabel id="demo-simple-select-label">
                    Department
                  </InputLabel>
                  <Select
                    name="department"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={studentData.department}
                    label="Department"
                    onChange={handleChange}
                  >
                    {departments?.map((dept) => (
                      <MenuItem
                        key={dept._id}
                        value={dept._id}
                        sx={{
                          height: "40px",
                          textAlign: "left",
                        }}
                      >
                        {dept.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3}>
                <FormControl style={{ width: "80%" }}>
                  <InputLabel id="demo-simple-select-label">Program</InputLabel>
                  <Select
                    name="program"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={studentData.program}
                    label="Department"
                    onChange={handleChange}
                  >
                    {programs?.map((prog) => (
                      <MenuItem
                        key={prog._id}
                        value={prog._id}
                        sx={{
                          height: "40px",
                          textAlign: "left",
                        }}
                      >
                        {prog.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3}>
                <FormControl style={{ width: "80%" }}>
                  <InputLabel id="demo-simple-select-label">Batch</InputLabel>
                  <Select
                    name="batch"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={studentData.batch}
                    label="Department"
                    onChange={handleChange}
                  >
                    {batches?.map((bach) => (
                      <MenuItem
                        key={bach._id}
                        value={bach._id}
                        sx={{
                          height: "40px",
                          textAlign: "left",
                        }}
                      >
                        {bach.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3}>
                <FormControl style={{ width: "80%" }}>
                  <InputLabel id="demo-simple-select-label">
                    Modality
                  </InputLabel>
                  <Select
                    name="modality"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={studentData.modality}
                    label="Modality"
                    onChange={handleChange}
                  >
                    {modalities?.map((modal) => (
                      <MenuItem
                        key={modal._id}
                        value={modal._id}
                        sx={{
                          height: "40px",
                          textAlign: "left",
                        }}
                      >
                        {modal.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <FormControl style={{ width: "80%" }}>
                  <InputLabel id="demo-simple-select-label">Section</InputLabel>
                  <Select
                    name="section"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={studentData.section}
                    label="Section"
                    onChange={handleChange}
                  >
                    {sectionData?.map((sec) => (
                      <MenuItem
                        key={sec._id}
                        value={sec._id}
                        sx={{
                          // height: "40px",
                          textAlign: "left",
                        }}
                      >
                        {sec.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <FormControl fullWidth margin="normal">
              <FormLabel>Role</FormLabel>
              <Select
                name="role"
                value={studentData?.role}
                onChange={handleChange}
              >
                <MenuItem value="student">Student</MenuItem>
                {/* <MenuItem value="admin">Admin</MenuItem> */}
              </Select>
            </FormControl>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.submitButton}
            >
              Update
            </Button>
          </form>
        </div>
        <div>
          <ChangePassword studentId={id} />
        </div>
      </div>
    </Container>
  );
};

export default UpdateForm;
