// extract a unique number used for randomization
export function extractUniqueNumber(id) {
  const parts = id.split("/");
  if (parts.length >= 3) {
    const uniqueNumberPart = parts[2];
    const match = uniqueNumberPart.match(/\d+/);
    if (match) {
      return parseInt(match[0]);
    }
  }
  return 0;
}

// this is to shuffle the questions
export function shuffleQuestions(questions, seed) {
  const shuffledQuestions = [...questions];

  const customShuffle = (array, seed) => {
    const rng = (seed) => {
      let x = Math.sin(seed++) * 10000;
      return x - Math.floor(x);
    };

    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(rng(seed) * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }

    return array;
  };

  return customShuffle(shuffledQuestions, seed);
}
