// import Button from "@mui/material/Button";
// import axios from "axios";
// import { useEffect, useState } from "react";
// import { useParams } from "react-router-dom";
// import EditIcon from "@mui/icons-material/Edit";
// import DeleteModal from "./DeleteModal";
// import DeleteIcon from "@mui/icons-material/Delete";

// const QuestionBank = () => {
//   const [questions, setQuestion] = useState([]);
//   const [open, setOpen] = useState(false);
//   const { id } = useParams();
//   const [deleteId, setId] = useState("");
//   const [deletedIndex, setDeletedIndex] = useState(null);
//   const fetchUser = async () => {
//     try {
//       const response = await axios.get(
//         `${process.env.REACT_APP_API_URL}/questions/departments/${id}`
//       );
//       const questions = response.data;
//       setQuestion(questions);
//     } catch (error) {
//       console.error("Error fetching user:", error.message);
//     }
//   };

//   const handleDelete = async () => {
//     axios
//       .delete(
//         `${process.env.REACT_APP_API_URL}/questions/${deletedIndex}/${deleteId}`
//       )
//       .then((response) => {
//         handleClose();
//         setId("");
//         // Handle the response data
//       })
//       .catch((error) => {
//         setId("");
//         handleClose();
//         console.error(error);
//         // Handle the error
//       });
//   };

//   useEffect(() => {
//     fetchUser();
//     // for (let x = 0; x < 10000000000; x++) {}
//     // setUserAnswer(userData.answer);
//   }, []);
//   const handleClickOpen = (index, delId) => {
//     setOpen(true);
//     setDeletedIndex(index);
//     setId(delId);
//   };

//   const handleClose = () => {
//     setOpen(false);
//   };
//   return (
//     <div maxWidth="sm" style={{ margin: "20px 60px" }}>
//       <h2 style={{ color: "blue" }}>
//         {questions && questions[0]?.name} Questions (
//         <span style={{ color: "red" }}>
//           {questions && questions[0]?.questions.length}
//         </span>{" "}
//         )
//       </h2>
//       {questions &&
//         questions?.questions?.map((question, ind) => (
//           <div className="questions" key={ind}>
//             <h2 className="text" style={{ marginTop: "30px" }}>
//               {ind + 1}. {question.question}
//             </h2>
//             <div>
//               <DeleteIcon
//                 style={{ cursor: "pointer", color: "red" }}
//                 // onClick={() => handleDelete(params.row._id)}
//                 onClick={() => handleClickOpen(ind, questions[0]?._id)}
//               />
//               {/* <EditIcon
//                 style={{ cursor: "pointer", color: "green" }}
//                 // onClick={() => navigate(`/update-student/${params.row._id}`)}
//               /> */}
//             </div>
//             <ul key={question?.id} style={{ marginBottom: "500px" }}>
//               {question?.options.map((q, i) => (
//                 <li key={i} style={{ color: "black" }}>
//                   <input
//                     type="radio"
//                     value={false}
//                     name="options"
//                     id={`q${i}-option`}
//                     disabled
//                     // onChange={() => onSelect(i)}
//                   />

//                   <label className="text-primary" htmlFor={`q${i}-option`}>
//                     {q}
//                   </label>
//                   <div
//                     className={`check ${
//                       questions[0]?.answers[ind] == i ? "checked" : ""
//                     }`}
//                   ></div>
//                 </li>
//               ))}
//             </ul>
//           </div>
//         ))}
//       {open && (
//         <DeleteModal
//           handleClickOpen={handleClickOpen}
//           handleClose={handleClose}
//           open={open}
//           handleDelete={handleDelete}
//         />
//       )}
//     </div>
//   );
// };

// export default QuestionBank;

import Button from "@mui/material/Button";
import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteModal from "./DeleteModal";
import DeleteIcon from "@mui/icons-material/Delete";

const QuestionBank = () => {
  const [questions, setQuestions] = useState([]);
  const [open, setOpen] = useState(false);
  const { id } = useParams();
  const [deleteId, setDeleteId] = useState("");
  const [deletedIndex, setDeletedIndex] = useState(null);

  const handleDelete = async () => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/questions/${deletedIndex}/${deleteId}`
      );
      handleClose();
      setDeleteId("");
      // Handle the successful deletion
    } catch (error) {
      console.error(error);
      // Handle the error
    }
  };

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/questions/departments/${id}`
        );
        const questionsData = response.data;
        setQuestions(questionsData);
      } catch (error) {
        console.error("Error fetching questions:", error.message);
      }
    };
    fetchQuestions();
  }, []);

  const handleClickOpen = (index, delId) => {
    setOpen(true);
    setDeletedIndex(index);
    setDeleteId(delId);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div maxWidth="sm" style={{ margin: "20px 60px" }}>
      <h2 style={{ color: "blue" }}>
        {questions.length > 0 && questions[0]?.name} Questions (
        <span style={{ color: "red" }}>
          {questions.length > 0 && questions[0]?.questions.length}
        </span>{" "}
        )
      </h2>
      {questions.length > 0 &&
        questions[0]?.questions.map((question, ind) => (
          <div className="questions" key={ind}>
            <p className="text" style={{ marginTop: "30px" }}>
              {ind + 1}. {question.question}
            </p>
            <div>
              <DeleteIcon
                style={{ cursor: "pointer", color: "red" }}
                onClick={() => handleClickOpen(ind, questions[0]?._id)}
              />
              {/* <EditIcon
                style={{ cursor: "pointer", color: "green" }}
                // onClick={() => navigate(`/update-student/${params.row._id}`)}
              /> */}
            </div>
            <ul key={question?._id} style={{ marginBottom: "250px" }}>
              {Object.entries(question.options).map(([key, value], i) => (
                <li
                  key={key}
                  style={{ color: "black", height: "50px", border: "none" }}
                >
                  <input
                    type="radio"
                    value={key}
                    name={`options-${ind}`}
                    id={`q${i}-option`}
                    disabled
                  />

                  <label
                    className="text-primary"
                    htmlFor={`q${i}-option`}
                    style={{ marginTop: "-15px" }}
                  >
                    {value}
                  </label>
                  <div
                    className={`check ${
                      question?.correctAnswer == key ? "checked" : ""
                    }`}
                    style={{ top: "10px" }}
                  ></div>
                </li>
              ))}
            </ul>
          </div>
        ))}
      {open && (
        <DeleteModal
          handleClickOpen={handleClickOpen}
          handleClose={handleClose}
          open={open}
          handleDelete={handleDelete}
        />
      )}
    </div>
  );
};

export default QuestionBank;
