import React, { useEffect, useState } from "react";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Container, FormControl, InputLabel } from "@material-ui/core";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { Card, Grid, MenuItem, Select, TextField } from "@mui/material";
import MultipleSelect from "../ui/MultiSelector";
import TimePickerViews from "../ui/TimePicker";
import CourseForm from "./AcademicComponent/Course";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import dayjs from "dayjs";

const useStyles = makeStyles((theme) => ({
  form: {
    marginTop: theme.spacing(2),
  },
  submitButton: {
    marginTop: theme.spacing(2),
  },
}));
const modalities = [
  { name: "Regular", _id: "Regular" },
  { name: "Extension", _id: "Extension" },
  { name: "Weekend", _id: "Weekend" },
];
const InsertQuestionForm = () => {
  const classes = useStyles();
  const [questionData, setQuestionData] = useState({
    name: "",
    instructor: "",
    year: "",
    semester: "",
    course: "",
    department: [],
    program: [],
    modality: [],
    batch: [],
    examTime: "",
    type: "",
    date: "",
    password: "",
  });
  const [departmentData, setDepartments] = useState([]);
  const [programData, setPrograms] = useState([]);
  const [batchData, setBatchs] = useState([]);
  const [courses, setCourses] = useState([]);
  const [changeControl, setChangeControl] = useState(true);
  const [message, setMessage] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [failur, setShowFailur] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [createdExam, setCreatedExam] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "department" || name === "modality" || name === "batch") {
      // For multi-select fields, the selected options are returned as an array
      setQuestionData({
        ...questionData,
        [name]: typeof value === "string" ? value.split(",") : value,
      });
    } else {
      // For other fields, the value is a single string
      setQuestionData({
        ...questionData,
        [name]: value,
      });
    }
  };

  const handleDateChange = (newDate) => {
    setQuestionData({
      ...questionData,
      date: newDate ? dayjs(newDate).format("YYYY-MM-DD") : null,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/question`,
        questionData
      );
      setCreatedExam(response.data);
      setShowPopup(true);
      setMessage("Exam Add successfully");
      // You can redirect or perform additional actions after successful insertion
    } catch (error) {
      setShowFailur(true);
      setMessage(error.response.data.message);
    }
  };
  useEffect(() => {
    const fetchProgram = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/program`
        );
        setPrograms(response.data);
      } catch (error) {
        console.error("Error fetching user:", error.message);
      }
    };
    fetchProgram();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setShowFailur(false);
      setShowPopup(false);
    }, 5000);
  }, [showPopup, failur]);

  useEffect(() => {
    const fetchDepartment = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/department`
        );
        setDepartments(response.data);
      } catch (error) {
        console.error("Error fetching user:", error.message);
      }
    };

    const fetchBatch = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/batch`
        );
        setBatchs(response.data);
      } catch (error) {
        console.error("Error fetching user:", error.message);
      }
    };
    fetchDepartment();
    fetchBatch();
  }, []);
  useEffect(() => {
    const fetchCourse = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/course`
        );
        setCourses(response.data);
      } catch (error) {
        console.error("Error fetching user:", error.message);
      }
    };
    fetchCourse();
  }, [changeControl]);

  const handleTimeChange = (time) => {
    setQuestionData({
      ...questionData,
      examTime: time,
    });
  };

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleFileUpload = () => {
    const formData = new FormData();
    formData.append("file", selectedFile);

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/question-upload/${createdExam._id}`,
        formData
      )
      .then((response) => {
        setShowPopup(true);
        setMessage("Questions Uploaded Successfully!");
      })
      .catch((error) => {
        console.error("Error in uploading file:", error.message);
        setShowFailur(true);
        setMessage("Error in uploading file:");
      });
  };

  return (
    <Container maxWidth="90%">
      <Card style={{ padding: "3rem" }}>
        <h2>Exam Registration</h2>
        <Stack sx={{ width: "100%" }} spacing={2} style={{ marginTop: "30px" }}>
          {showPopup && (
            <Alert variant="filled" severity="success">
              {message}
            </Alert>
          )}

          {failur && (
            <Alert variant="filled" severity="error">
              {message}
            </Alert>
          )}
        </Stack>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Grid
            container
            rowSpacing={3}
            columnSpacing={{ xs: 3, sm: 2, md: 3 }}
          >
            {/* <TextField
          label="Course Name"
          name="type"
          fullWidth
          value={questionData.type}
          onChange={handleChange}
          margin="normal"
          required
          style={{ marginRight: "20px" }}
        /> */}
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <h4>Enter Exam name</h4>
              <TextField
                label="Exam title"
                name="name"
                fullWidth
                margin="normal"
                value={questionData.name}
                onChange={handleChange}
                required
                style={{ marginTop: "-10px" }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <h4>Enter Instructor name</h4>
              <TextField
                label="Instructor"
                name="instructor"
                fullWidth
                margin="normal"
                value={questionData.instructor}
                onChange={handleChange}
                // required
                style={{ marginTop: "-10px" }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <h4>Enter password</h4>
              <TextField
                label="Exam Password"
                name="password"
                type="password"
                fullWidth
                value={questionData.password}
                onChange={handleChange}
                margin="normal"
                required
                style={{ marginTop: "-10px" }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={4}>
              <h4 style={{ marginTop: "-10px" }}>Enter Exam Course</h4>
              <FormControl style={{ width: "100%", marginTop: "-10px" }}>
                {/* <InputLabel
                  id="demo-simple-select-label"
                  style={{ marginLeft: "10px" }}
                >
                  COURSE
                </InputLabel> */}
                <Select
                  labelId="demo-simple-select-label"
                  name="course"
                  id="demo-simple-select"
                  value={questionData.course}
                  label="Course"
                  onChange={handleChange}
                >
                  {courses?.map((dept) => (
                    <MenuItem key={dept._id} value={dept._id}>
                      {dept.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <MultipleSelect
                data={departmentData}
                name="department"
                value={questionData.department}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <MultipleSelect
                data={programData}
                name="program"
                value={questionData.program}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <MultipleSelect
                data={batchData}
                name="batch"
                value={questionData.batch}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={4}>
              <MultipleSelect
                data={modalities}
                name="modality"
                value={questionData.modality}
                onChange={handleChange}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={3}
              style={{ marginTop: "-20px" }}
            >
              <h4>Enter Exam time</h4>
              <TimePickerViews
                // name="examTime"
                value={questionData.examTime}
                onChange={handleTimeChange}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={3}>
              <h4>Academic year</h4>
              <TextField
                label="Exam year"
                name="year"
                type="number"
                fullWidth
                value={questionData.year}
                onChange={handleChange}
                margin="normal"
                required
                style={{ marginTop: "-10px" }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3}>
              <h4>Enter Exam Semester</h4>
              <FormControl style={{ width: "100%", marginTop: "-10px" }}>
                {/* <InputLabel
                  id="demo-simple-select-label"
                  style={{ marginLeft: "10px" }}
                >
                  Semester
                </InputLabel> */}
                <Select
                  labelId="demo-simple-select-label"
                  name="semester"
                  id="demo-simple-select"
                  value={questionData.semester}
                  label="Semester"
                  onChange={handleChange}
                >
                  <MenuItem value="1">1</MenuItem>
                  <MenuItem value="2">2</MenuItem>
                  <MenuItem value="3">3</MenuItem>
                  <MenuItem value="4">4</MenuItem>
                  <MenuItem value="5">5</MenuItem>
                  <MenuItem value="6">6</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3}>
              <h4>Enter Exam type</h4>
              <TextField
                label="Exam type"
                name="type"
                fullWidth
                value={questionData.type}
                onChange={handleChange}
                margin="normal"
                required
                style={{ marginTop: "-10px" }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={3}
              style={{ marginTop: "20px" }}
            >
              <div style={{ fontWeight: "bold" }}>Exam Date</div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    name="date"
                    value={questionData.data}
                    onChange={handleDateChange}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
          </Grid>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submitButton}
            style={{ float: "left", width: "150px", height: "40px" }}
          >
            Create
          </Button>
        </form>

        {createdExam && (
          <div
            style={{
              // border: "2px solid blue",
              padding: "10px",
              // boxShadow: "0 0 1px 4px rgba(179, 178, 178, 0.4)",
              background: "white",
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "end",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <h3>{createdExam?.name}</h3>
            <div style={{ position: "relative" }}>
              {/* <h3 style={{ width: "auto" }}>Excel File Uploader</h3> */}
              <label
                htmlFor="file-upload"
                style={{
                  display: "inline-block",
                  padding: "6px 12px",
                  cursor: "pointer",
                  backgroundColor: "#4caf50",
                  color: "#fff",
                  borderRadius: "4px",
                  border: "none",
                  fontSize: "14px",
                }}
              >
                <span
                  style={{
                    marginRight: "6px",
                    fontSize: "18px",
                    verticalAlign: "middle",
                  }}
                >
                  {/* Replace the 'mui-icon' component with the appropriate Material-UI icon component */}
                  <CloudUploadIcon />
                </span>{" "}
                Choose File
              </label>
              <input
                id="file-upload"
                type="file"
                onChange={handleFileSelect}
                style={{ display: "none" }}
              />
              {selectedFile && <p>Selected file: {selectedFile.name}</p>}
            </div>
            <div>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={handleFileUpload}
                style={{ float: "right" }}
                // className={classes.submitButton}
              >
                Upload Excel File
              </Button>
            </div>
          </div>
        )}
      </Card>
      {(localStorage.getItem("role") === "college" ||
        localStorage.getItem("role") === "super-admin") && (
        <CourseForm
          setChangeControl={setChangeControl}
          changeControl={changeControl}
          showPopup={showPopup}
          setShowPopup={setShowPopup}
          setShowFailur={setShowFailur}
          failur={failur}
          message={message}
          setMessage={setMessage}
        />
      )}
      {/* <ToastContainer /> */}
    </Container>
  );
};

export default InsertQuestionForm;
