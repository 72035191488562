/* eslint-disable react/prop-types */
import { DataGrid } from "@mui/x-data-grid";
import { theme } from "../theme";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import DeleteModal from "../DeleteModal";
import { useEffect, useMemo, useState } from "react";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { Button, Modal } from "@mui/material";

export default function SuperAdminList({ adminData, setFlag, flag }) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [deleteId, setId] = useState("");
  const [message, setMessage] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [failur, setShowFailur] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  ///// fdhjhdsfjjsdlhf

  const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] =
    useState(false);

  const handleDeleteClick = () => {
    setDeleteConfirmationModalOpen(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/students-batch-delete`,
        selectedRows
      );
      setFlag(!flag);
      setSelectedRows([]);
    } catch (error) {
      console.error("Error deleting exam:", error.message);
    }
    setDeleteConfirmationModalOpen(false);
  };

  const handleRowSelection = (selectedRowIds) => {
  };
  const handleRowClick = (selectedRowIds) => {
    const selectedId = selectedRowIds.row._id;
    const isRowSelected = selectedRows?.find((rowId) => rowId === selectedId);
    if (isRowSelected) {
      setSelectedRows((prevSelectedRows) =>
        prevSelectedRows?.filter((rowId) => rowId !== selectedId)
      );
    } else {
      setSelectedRows((prevSelectedRows) => [...prevSelectedRows, selectedId]);
    }
  };
  const handleClickOpen = (id) => {
    setOpen(true);
    setId(id);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const columns = [
    { field: "id", headerName: "ID", width: 150 },
    { field: "firstName", headerName: "First name", width: 130 },
    { field: "middleName", headerName: "Middle name", width: 130 },
    { field: "lastName", headerName: "Last name", width: 130 },
    { field: "sex", headerName: "Sex", width: 130 },
    {
      field: "delete",
      headerName: "Action",
      width: 100,
      renderCell: (params) => (
        <div>
          <DeleteIcon
            style={{ cursor: "pointer", color: "red" }}
            // onClick={() => handleDelete(params.row._id)}
            onClick={() => handleClickOpen(params.row._id)}
          />
          <EditIcon
            style={{ cursor: "pointer", color: "green" }}
            onClick={() => navigate(`/update-admin/${params.row._id}`)}
          />
        </div>
      ),
    },
  ];

  const rowss = [];
  const rows = useMemo(() => {
    return adminData?.map((data) => ({
      _id: data._id,
      id: data.id,
      firstName: data.firstName,
      middleName: data.middleName,
      lastName: data.lastName,
      sex: data.sex,
    }));
  }, [adminData]);

  const handleDelete = async () => {
    const encodedId = encodeURIComponent(deleteId);
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/students/${encodedId}`
      );
      handleClose();
      setShowPopup(true);
      setMessage("Student deleted successsfully");
      setFlag(!flag);
      setId("");
    } catch (error) {
      setShowFailur(true);
      setMessage("error in deleting students, error");
      setId("");
      handleClose();
      // Handle the error appropriately
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setShowFailur(false);
      setShowPopup(false);
    }, 10000);
  }, [showPopup, failur]);

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <Stack sx={{ width: "100%" }} spacing={2} style={{ marginTop: "30px" }}>
        {showPopup && (
          <Alert variant="filled" severity="success">
            {message}
          </Alert>
        )}

        {failur && (
          <Alert variant="filled" severity="error">
            {message}
          </Alert>
        )}
      </Stack>
      <div style={{ marginTop: "20px", marginBottom: "20px" }}>
        {selectedRows?.length > 0 && (
          <Button
            variant="contained"
            color="error"
            onClick={handleDeleteClick}
            style={{
              width: "350px",
              float: "right",
              margin: "0 10px 10px 0",
            }}
          >
            Delete Selected Student
          </Button>
        )}
      </div>
      <StyledDataGrid
        rows={adminData ? rows : rowss}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 50 },
          },
        }}
        pageSizeOptions={[5, 10, 15, 20, 25, 50]}
        checkboxSelection
        // onRowClick={handleRowClick()}
        onSelectionModelChange={handleRowSelection}
        onRowClick={handleRowClick}
      />
      {open && (
        <DeleteModal
          handleClickOpen={handleClickOpen}
          handleClose={handleClose}
          open={open}
          handleDelete={handleDelete}
        />
      )}
      <Modal
        open={deleteConfirmationModalOpen}
        onClose={() => setDeleteConfirmationModalOpen(false)}
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            padding: "20px",
          }}
        >
          <h2>Are you sure you want to delete this exam?</h2>
          <div style={{ width: "100%" }}>
            <div style={{ float: "right" }}>
              <Button
                variant="contained"
                color="error"
                onClick={handleDeleteConfirm}
              >
                Delete
              </Button>
              <Button
                variant="contained"
                onClick={() => setDeleteConfirmationModalOpen(false)}
                style={{ marginLeft: "10px" }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
const StyledDataGrid = styled(DataGrid)(() => ({
  maxWidth: "100vw",
  minWidth: "min-content",
  width: "100%",
  height: "692px",
  backgroundColor: theme.colors.white[9],
  fontWeight: 400,
  justifyContent: "space-evenly",
  "& .MuiDataGrid-toolbarContainer": {
    padding: ".8rem .5rem",
  },

  "& .MuiDataGrid-columnHeaders": {
    borderBottom: `1px solid ${theme.colors.border.default}`,
    backgroundColor: theme.colors.primary[0],
  },
  "& .MuiDataGrid-columnHeader": {
    borderBottom: `1px solid ${theme.colors.border.default}`,

    "& .MuiDataGrid-columnHeaderTitle": {
      padding: "0 auto",
      margin: "0 auto",
    },
  },

  "& .MuiDataGrid-row": {
    cursor: "pointer",
    backgroundColor: theme.colors.white[9],
    "&:hover": {
      backgroundColor: theme.colors.primary[2],
    },
    "&.Mui-selected": {
      backgroundColor: theme.colors.primary[2],
      "&:hover": {
        backgroundColor: theme.colors.primary[0],
      },
    },
  },
}));
