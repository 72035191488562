import { useRef, useState } from "react";
import { useEffect } from "react";
import "../form.css";
import axios from "axios";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { Navigate, useNavigate, useParams } from "react-router-dom";

const EditQuestion = () => {
  const [questionData, setQuestionData] = useState({
    question: "",
    options: {
      A: "",
      B: "",
      C: "",
      D: "",
    },
    correctAnswer: "",
    explanation: "",
  });
  const [question, setQuestion] = useState({});
  const [message, setMessage] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [failur, setShowFailur] = useState(false);
  const navigate = useNavigate();
  const [exams, setExams] = useState([]);

  const { index, examId } = useParams();

  const formRef = useRef(null);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name.includes("option")) {
      const optionName = name.slice(-1);
      setQuestionData((prevData) => ({
        ...prevData,
        options: {
          ...prevData.options,
          [optionName]: value,
        },
      }));
    } else {
      setQuestionData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .put(
        `${process.env.REACT_APP_API_URL}/questions/${index}/${examId}`,
        questionData
      )
      .then((response) => {
        setShowPopup(true);
        setMessage("Question Updated successfully");
        formRef.current.reset();
      })
      .catch((error) => {
        setShowFailur(true);
        setMessage("Failure in question update" + error);
        console.error(error);
      });
    // handleTimeSubmit();
  };

  useEffect(() => {
    setTimeout(() => {
      setShowFailur(false);
      setShowPopup(false);
    }, 10000);
  }, [showPopup, failur]);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/questions/${examId}`
        );
        setQuestionData(response.data.questions[index]);
      } catch (error) {
        console.error("Error fetching user:", error.message);
      }
    };
    fetchUser();
  }, []);

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/questions`
        );
        const questionsData = response.data;
        setExams(questionsData);
      } catch (error) {
        console.error("Error fetching questions:", error.message);
      }
    };
    fetchQuestions();
  }, []);
  return (
    <>
      <div className="container" style={{ width: "90%" }}>
        <div className="row" style={{ width: "100%", margin: "auto" }}>
          <div
            className="col-md-12"
            style={{
              width: "100%",
              margin: "auto",
            }}
          >
            <div className="card" style={{ width: "100%", margin: "auto" }}>
              <Stack sx={{ width: "100%" }} spacing={2}>
                {showPopup && (
                  <Alert variant="filled" severity="success">
                    {message}
                  </Alert>
                )}

                {failur && (
                  <Alert variant="filled" severity="error">
                    {message}
                  </Alert>
                )}
              </Stack>
              <div
                className="card-header row"
                style={{
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <h4>Edit Question</h4>
                <Button
                  style={{ background: "red", color: "white" }}
                  onClick={() => navigate(`/question-detail/${examId}`)}
                >
                  Back
                </Button>
              </div>

              <div className="card-body">
                <form onSubmit={handleSubmit} ref={formRef}>
                  <div className="mb-3">
                    <label>Question</label>
                    <input
                      value={questionData?.question}
                      type="text"
                      name="question"
                      className="form-control"
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="row">
                    <div
                      className="mb-3"
                      style={{ paddingLeft: "3rem", width: "50%" }}
                    >
                      <label>Option A</label>
                      <input
                        value={questionData?.options.A}
                        type="text"
                        name="optionA"
                        className="form-control"
                        onChange={handleInputChange}
                      />
                    </div>

                    <div
                      className="mb-3"
                      style={{ paddingLeft: "3rem", width: "50%" }}
                    >
                      <label>Option B</label>
                      <input
                        value={questionData?.options.B}
                        type="text"
                        name="optionB"
                        className="form-control"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div
                      className="mb-3"
                      style={{ paddingLeft: "3rem", width: "50%" }}
                    >
                      <label>Option C</label>
                      <input
                        value={questionData?.options.C}
                        type="text"
                        name="optionC"
                        className="form-control"
                        onChange={handleInputChange}
                      />
                    </div>
                    <div
                      className="mb-3"
                      style={{ paddingLeft: "3rem", width: "50%" }}
                    >
                      <label>Option D</label>
                      <input
                        value={questionData?.options.D}
                        type="text"
                        name="optionD"
                        className="form-control"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div
                      className="mb-3"
                      style={{ paddingLeft: "3rem", width: "50%" }}
                    >
                      <label>Correct Answer</label>
                      <br />
                      <input
                        value={questionData?.correctAnswer}
                        type="text"
                        name="correctAnswer"
                        className="form-control"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>

                  {/* <div className="row"></div> */}

                  <div style={{ marginTop: "100px" }}>
                    <button type="submit" className="btn btn-primary">
                      Update Question
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          {/* {examStatus && <ResponseModal type="exam" />} */}
        </div>
      </div>
      {/* </div>
      </WrapperContainer> */}
    </>
  );
};

export default EditQuestion;
