import React, { useState } from "react";
import axios from "axios";
import { Button } from "@material-ui/core";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ExcelUploader = ({
  setMessage,
  setShowPopup,
  setShowFailur,
  handleDepartmentChange,
  department,
  departments,
  batchs,
  sections,
  programs,
  modalities,
  data,
  setData,
  handleDataChange,
}) => {
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleFileUpload = () => {
    // Create a FormData object
    const formData = new FormData();

    // Append the selected file to the FormData object
    formData.append("file", selectedFile);
    formData.append("department", data.department);
    formData.append("program", data.program);
    formData.append("batch", data.batch);
    formData.append("modality", data.modality);
    formData.append("section", data.section);

    axios
      .post(`${process.env.REACT_APP_API_URL}/student-upload`, formData)
      .then((response) => {
        toast.success("Student uploaded successfully!");
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          // justifyContent: "space-around",
          gap: "50px",
          // alignItems: "center",
          alignContent: "center",
        }}
      >
        {/* <div>
          <h3 style={{ width: "auto" }}>Excel File Uploader</h3>
          <input type="file" onChange={handleFileSelect} />
        </div> */}
        <div style={{ position: "relative" }}>
          <h3 style={{ width: "auto" }}>Excel File Uploader</h3>
          <label
            htmlFor="file-upload"
            style={{
              display: "inline-block",
              padding: "6px 12px",
              cursor: "pointer",
              backgroundColor: "#4caf50",
              color: "#fff",
              borderRadius: "4px",
              border: "none",
              fontSize: "14px",
            }}
          >
            <span
              style={{
                marginRight: "6px",
                fontSize: "18px",
                verticalAlign: "middle",
              }}
            >
              {/* Replace the 'mui-icon' component with the appropriate Material-UI icon component */}
              <CloudUploadIcon />
            </span>{" "}
            Choose File
          </label>
          <input
            id="file-upload"
            type="file"
            onChange={handleFileSelect}
            style={{ display: "none" }}
          />
          {selectedFile && <p>Selected file: {selectedFile.name}</p>}
        </div>

        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <FormControl style={{ width: "80%" }}>
              <InputLabel id="demo-simple-select-label">Department</InputLabel>
              <Select
                name="department"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={data.department}
                label="Department"
                onChange={handleDataChange}
              >
                {departments?.map((dept) => (
                  <MenuItem
                    key={dept._id}
                    value={dept._id}
                    sx={{
                      // height: "40px",
                      textAlign: "left",
                    }}
                  >
                    {dept.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <FormControl style={{ width: "80%" }}>
              <InputLabel id="demo-simple-select-label">Program</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                name="program"
                id="demo-simple-select"
                value={data.program}
                label="Department"
                onChange={handleDataChange}
              >
                {programs?.map((prog) => (
                  <MenuItem
                    key={prog._id}
                    value={prog._id}
                    sx={{
                      // height: "40px",
                      textAlign: "left",
                    }}
                  >
                    {prog.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <FormControl style={{ width: "80%" }}>
              <InputLabel id="demo-simple-select-label">Batch</InputLabel>
              <Select
                name="batch"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={data.batch}
                label="Department"
                onChange={handleDataChange}
              >
                {batchs?.map((bach) => (
                  <MenuItem
                    key={bach._id}
                    value={bach._id}
                    sx={{
                      // height: "40px",
                      textAlign: "left",
                    }}
                  >
                    {bach.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <FormControl style={{ width: "80%" }}>
              <InputLabel id="demo-simple-select-label">Modality</InputLabel>
              <Select
                name="modality"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={data.modality}
                label="Department"
                onChange={handleDataChange}
              >
                {modalities?.map((mod) => (
                  <MenuItem
                    key={mod._id}
                    value={mod._id}
                    sx={{
                      // height: "40px",
                      textAlign: "left",
                    }}
                  >
                    {mod.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <FormControl style={{ width: "80%" }}>
              <InputLabel id="demo-simple-select-label">Section</InputLabel>
              <Select
                name="section"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={data.section}
                label="Section"
                onChange={handleDataChange}
              >
                {sections?.map((sec) => (
                  <MenuItem
                    key={sec._id}
                    value={sec._id}
                    sx={{
                      // height: "40px",
                      textAlign: "left",
                    }}
                  >
                    {sec.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <div>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={handleFileUpload}
            style={{ float: "right" }}
            // className={classes.submitButton}
          >
            Upload Excel File
          </Button>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ExcelUploader;
