import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getServerData } from "../helper/helper";

/** redux actions */
import * as Action from "../redux/question_reducer";

/** fetch question hook to fetch api data and set value to store */
export const useFetchQestion = () => {
  const dispatch = useDispatch();
  const [getData, setGetData] = useState({
    isLoading: false,
    apiData: [],
    serverError: null,
  });

  useEffect(() => {
    setGetData((prev) => ({ ...prev, isLoading: true }));

    /** async function fetch backend data */
    (async () => {
      try {
        const [
          { questions, answers, examTime },
          { questions: manQuestion, answers: manAnswer, examTime: manExamTIme },
          { questions: socQuestion, answers: socAnswer, examTime: socExamTIme },
        ] = await getServerData(
          `${process.env.REACT_APP_API_URL}/questions/department/program/${localStorage.getItem}`,
          (data) => data
        );

        if (questions.length > 0) {
          setGetData((prev) => ({ ...prev, isLoading: false }));
          setGetData((prev) => ({ ...prev, apiData: questions }));

          /** dispatch an action */
          if (localStorage.getItem("department") === "IT") {
            dispatch(
              Action.startExamAction({
                question: questions,
                answers,
                exam: examTime,
                allQuestion: getData,
              })
            );
          } else if (localStorage.getItem("department") === "Management") {
            dispatch(
              Action.startExamAction({
                question: manQuestion,
                answers: manAnswer,
                exam: manExamTIme,
              })
            );
          } else {
            dispatch(
              Action.startExamAction({
                question: socQuestion,
                answers: socAnswer,
                exam: socExamTIme,
              })
            );
          }
        } else {
          throw new Error("No Question Avalibale");
        }
      } catch (error) {
        setGetData((prev) => ({ ...prev, isLoading: false }));
        setGetData((prev) => ({ ...prev, serverError: error }));
      }
    })();
  }, [dispatch]);

  return [getData, setGetData];
};

/** MoveAction Dispatch function */
export const MoveNextQuestion = () => async (dispatch) => {
  try {
    await dispatch(Action.moveNextAction()); /** increase trace by 1 */
  } catch (error) {
    console.log(error);
  }
};

export const MoveRandomQuestion = (index) => async (dispatch) => {
  try {
    dispatch(Action.moveRandomAction(index)); /** increase trace by 1 */
  } catch (error) {
    console.log(error);
  }
};

export const QueryData = (data) => async (dispatch) => {
  try {
    dispatch(Action.queryDataStart(data));
  } catch (error) {
    console.log(error);
  }
};

export const QueryStudent = (data) => async (dispatch) => {
  try {
    dispatch(Action.studentQueryStart(data));
  } catch (error) {
    console.log(error);
  }
};
/** PrevAction Dispatch function */
export const MovePrevQuestion = () => async (dispatch) => {
  try {
    dispatch(Action.movePrevAction()); /** decrease trace by 1 */
  } catch (error) {
    console.log(error);
  }
};
