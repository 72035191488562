import "./featured.scss";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
const Featured = ({
  presentPercent,
  totalPresentStudents,
  totalAbsentStudents,
}) => {
  return (
    <div className="featured">
      <div className="top">
        <h1 className="title">Total Exams</h1>
        <MoreVertIcon fontSize="small" />
      </div>
      <div className="bottom">
        <div className="featuredChart">
          <CircularProgressbar
            value={presentPercent}
            text={`${presentPercent.toFixed(2)}`}
            strokeWidth={5}
          />
        </div>
        <p className="title">Total Active Exams</p>
        <p className="amount">{totalPresentStudents}</p>
        <p className="desc">Today student Attendance implementation</p>
        <div className="summary">
          <div className="item">
            <div className="itemTitle">Total</div>
            <div className="itemResult negative">
              <PersonOutlinedIcon
                className="icon"
                style={{
                  color: "blue",
                  backgroundColor: "rgba(0, 79, 250, 0.2)",
                  marginRight: "10px",
                }}
                fontSize="small"
              />
              <div className="resultAmount">
                {totalAbsentStudents + totalPresentStudents}
              </div>
            </div>
          </div>
          <div className="item">
            <div className="itemTitle">Active</div>
            <div className="itemResult positive">
              <PersonOutlinedIcon
                className="icon"
                style={{
                  // color: "crimson",
                  backgroundColor: "rgba(0, 250, 21, 0.2)",
                  marginRight: "10px",
                }}
                fontSize="small"
              />
              <div className="resultAmount">{totalPresentStudents}</div>
            </div>
          </div>
          <div className="item">
            <div className="itemTitle">Inactive</div>
            <div className="itemResult positive">
              <PersonOutlinedIcon
                className="icon"
                style={{
                  color: "crimson",
                  backgroundColor: "rgba(250, 37, 0, 0.2)",
                  marginRight: "10px",
                }}
                fontSize="small"
              />
              <div className="resultAmount">{totalAbsentStudents}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Featured;
