import React, { useEffect, useState } from "react";
import Questions from "./Questions";

import {
  MoveNextQuestion,
  MovePrevQuestion,
  MoveRandomQuestion,
} from "../hooks/FetchQuestion";
import { PushAnswer, UserResultAnswer, updateResult } from "../hooks/setResult";

/** redux store import */
import { useSelector, useDispatch } from "react-redux";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import QuestionList from "./QuestionSelection";
import ResultReport from "./Result";
import FormDialog from "./ui/DialogueForm";
import { Button, Card } from "@mui/material";

export default function Quiz() {
  const [checked, setChecked] = useState({});
  const [open, setOpen] = useState(false);

  const result = useSelector((state) => state.result.result);
  const { queue, trace, exam } = useSelector((state) => state.questions);
  const dispatch = useDispatch();
  const [time, setTime] = useState({ hours: 0, minutes: 0, seconds: 0 });
  const [showPopup, setShowPopup] = useState(false);
  const { examId } = useParams();
  const [userData, setUserData] = useState(null);
  const [userAnswer, setUserAnswer] = useState([]);
  const [examData, setExamData] = useState({});
  const [user, setUser] = useState({});
  const [isResult, setIsResult] = useState();
  const [openCheating, setOpenCheating] = useState(false);
  const [isCheatingMessage, setisCheatingMessage] = useState("");
  const [cheatingStastus, setCheatingStastus] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/result/${localStorage.getItem(
            "userId"
          )}/${examId}`
        );
        setIsResult(response.data.result);
        setUserData(response.data);
        setUserAnswer(response.data.answer);
      } catch (error) {
        console.error("Error fetching user:", error.message);
      }
    };

    fetchUser();
    for (let i = 0; i < 1000000000; i++) {}
  }, []);

  useEffect(() => {
    const handleCreateResult = () => {
      const apiUrl = `${process.env.REACT_APP_API_URL}/result`;

      axios
        .post(`${apiUrl}`, {
          result: {
            exam: examId,
            department: localStorage.getItem("department"),
            student: localStorage.getItem("userId"),
            // result: null,
            answer: [],
          },
        })
        .then((response) => {
          console.log("Student result initiate successfully");
        })
        .catch((error) => {
          console.error("Error :", error);
        });
    };

    async function fetchExam() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/questions/${examId}`
        );
        const data = response.data;

        setExamData(data);
        // Process the retrieved data here
      } catch (error) {
        // Handle error
        console.error("Error fetching student data:", error);
      }
    }
    async function fetchStudent() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/students/${localStorage.getItem(
            "userId"
          )}`
        );
        const datas = response.data;
        setUser(datas);
      } catch (error) {
        console.error("Error fetching student data:", error);
      }
    }

    fetchStudent();
    fetchExam();

    handleCreateResult();
  }, []);

  useEffect(() => {
    updateTimeFromString(
      userData?.remainTime == null || userData?.remainTime === undefined
        ? exam
        : userData?.remainTime
    );
  }, [userData, exam]);

  const updateTimeFromString = (timeString) => {
    const [hours, minutes, seconds] = timeString?.split(":")?.map(Number);

    const validMinutes = Math.min(59, minutes);
    const validSeconds = Math.min(59, seconds);

    setTime({ hours, minutes: validMinutes, seconds: validSeconds });
  };

  useEffect(() => {
    const timer = setInterval(() => {
      if (time?.hours === 0 && time?.minutes === 0 && time?.seconds === 0) {
        setShowPopup(true);
        clearInterval(timer);
      } else {
        // Update the countdown
        setTime((prevTime) => {
          const newTime = { ...prevTime };
          if (newTime?.seconds === 0) {
            if (newTime?.minutes === 0) {
              newTime.hours = Math.max(0, newTime.hours - 1);
              newTime.minutes = 59;
            } else {
              newTime.minutes -= 1;
            }
            newTime.seconds = 59;
          } else {
            newTime.seconds -= 1;
          }
          return newTime;
        });
      }
    }, 1000);

    // Clean up the timer on component unmount
    return () => clearInterval(timer);
  }, [time]);

  const handleTimeSubmit = () => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/result/update-time`;

    axios
      .put(`${apiUrl}/${localStorage.getItem("userId")}/${examId}`, {
        remainTime:
          time.hours.toString() +
          ":" +
          time.minutes.toString() +
          ":" +
          time.seconds.toString(),
      })
      .then((response) => {
        // Handle success and update UI if needed
      })
      .catch((error) => {
        console.error("Error updating student examTime:", error);
        // Handle error and display error message if needed
      });
  };

  const handleAnswerSubmit = () => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/result/update-answer`;

    axios
      .put(`${apiUrl}/${localStorage.getItem("userId")}/${examId}`, {
        answer: result,
      })
      .then((response) => {
        console.log("Student answer updated successfully:", response.data);
      })
      .catch((error) => {
        console.error("Error updating student answer:", error);
      });
  };

  const submitReport = () => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/update-isCheating`;
    axios
      .post(`${apiUrl}/${localStorage.getItem("userId")}/${examId}`, {
        isCheatingMessage,
      })
      .then((response) => {
        setCheatingStastus(true);
      })
      .catch((error) => {
        console.error("Error updating student answer:", error);
      });
  };

  useEffect(() => {
    if (userAnswer?.length > 0 && queue?.length > 0) {
      dispatch(UserResultAnswer(userAnswer));
    }
  }, [userAnswer, queue]);

  useEffect(() => {
    for (let i = 0; i < userAnswer.length; i++) {
      dispatch(MoveRandomQuestion(i));
    }
  }, [userAnswer]);

  function onNext() {
    if (trace < queue.length) {
      dispatch(MoveNextQuestion());
      if (result.length <= trace) {
        dispatch(PushAnswer({ checked: checked[trace], trace }));
      }
    }
    handleAnswerSubmit();
    handleTimeSubmit();
  }

  function onFinish() {
    if (trace < queue.length) {
      // dispatch(MoveNextQuestion());
      if (result.length <= trace) {
        dispatch(PushAnswer({ checked: checked[trace], trace }));
      }
    }
    handleAnswerSubmit();
    handleTimeSubmit();
    setOpen(true);
    // navigate(`/result/${examId}`);
  }

  function onRandomMove(index) {
    dispatch(MoveRandomQuestion(index));
  }

  /** Prev button event handler */
  function onPrev() {
    if (trace > 0) {
      dispatch(MovePrevQuestion());
    }
    handleTimeSubmit();
  }

  function onChecked(trace, key) {
    setChecked((prevChecked) => ({
      ...prevChecked,
      [trace]: key,
    }));
  }

  useEffect(() => {
    async function fetchExamByStudent() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/result/${localStorage.getItem(
            "userId"
          )}/${examId}`
        );
        const data = response.data;
        if (data.isCheating) {
          setCheatingStastus(true);
        }
        // setExamData(data);
        // Process the retrieved data here
      } catch (error) {
        // Handle error
        console.error("Error fetching student data:", error);
      }
    }

    fetchExamByStudent();
  }, [cheatingStastus]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCheatinOpen = () => {
    setOpenCheating(true);
  };

  const handleCheatinClose = () => {
    setOpenCheating(false);
  };

  const handleCheatingMessage = (e) => {
    setisCheatingMessage(e.target.value);
  };

  if (isResult) {
    navigate(`/result-detail/${examId}`);
  }

  return (
    <div
      className="container"
      style={{ width: "100%", height: "100vh", backgroundColor: "#f7f7f7" }}
    >
      {/* {userData?.result === null ? ( */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Card
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "25px",
            padding: "1%",
            // position: "relative",
          }}
        >
          <Button
            variant="outlined"
            onClick={handleCheatinOpen}
            // style={{ background: "red", color: "white" }}
            style={{
              background: cheatingStastus && "red",
              color: cheatingStastus && "white",
            }}
            // className={cheatingStastus && "cheating-btn"}
          >
            Report Cheating
          </Button>
          <div>
            Student Name:{" "}
            <span style={{ color: "#289af7" }}>
              {user?.firstName} {user?.middleName}
            </span>
          </div>
          <div>
            Student Id: <span style={{ color: "#289af7" }}>{user?.id}</span>
          </div>
          <div>
            Department:{" "}
            <span style={{ color: "#289af7" }}>{user?.department?.name}</span>
          </div>
          <div>
            Modality: <span style={{ color: "#289af7" }}>{user?.modality}</span>
          </div>
          <div>
            Exam Name:{" "}
            <span style={{ color: "#289af7" }}>{examData?.name}</span>
          </div>
          {/* <div>
            Exam Course:{" "}
            <span style={{ color: "#289af7" }}>{examData?.course?.name}</span>
          </div> */}

          <div
            style={{
              fontSize: "30px",
              fontWeight: "bold",
              position: "absolute",
              right: "100px",
            }}
          >
            {time.hours}:{time.minutes}:{time.seconds}
            {showPopup && (
              <div className="popup">
                <p>Time's up!</p>
              </div>
            )}
          </div>
        </Card>

        <div
          style={{
            display: "flex",
            // justifyContent: "center",
            // alignItems: "center",
            padding: "3%",
          }}
        >
          <div
            className="container"
            style={{
              height: "700px",
              background: "#f0eded",
              width: "20%",
              overflowY: "scroll",
              border: "2px solid #cccccc",
            }}
            sx
          >
            <QuestionList
              onRandomMove={onRandomMove}
              checked={checked[trace]}
              setChecked={setChecked}
              handleAnswerSubmit={handleAnswerSubmit}
              handleTimeSubmit={handleTimeSubmit}
            />
          </div>
          <div
            className="container"
            style={{ backgroundColor: "#f0eded", marginTop: "60px" }}
          >
            {/* <h1 className="title text-light">Quiz Application</h1> */}

            {/* display questions */}
            <Questions
              onChecked={onChecked}
              checked={checked[trace]} // Pass the checked state for the current question
              setChecked={(key) => onChecked(trace, key)} // Pass the function to update checked state
            />

            <div className="grid">
              {trace > 0 ? (
                <button className="btn prev" onClick={onPrev}>
                  Prev
                </button>
              ) : (
                <div></div>
              )}
              {trace + 1 == queue?.length ? (
                <button
                  className="btn next"
                  onClick={onFinish}
                  style={{ background: "green", color: "white" }}
                >
                  Finish and submit
                </button>
              ) : (
                <button className="btn next" onClick={onNext}>
                  Next
                </button>
              )}
            </div>
          </div>
        </div>
        <FormDialog
          examId={examId}
          openCheating={openCheating}
          setOpenCheating={setOpenCheating}
          handleCheatinOpen={handleCheatinOpen}
          handleCheatinClose={handleCheatinClose}
          submitReport={submitReport}
          handleCheatingMessage={handleCheatingMessage}
        />
      </div>
      {/* ) : (
        <ResultReport />
      )} */}
      <ResultReport
        open={open}
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        showPopup={showPopup}
        setOpen={setOpen}
      />
    </div>
  );
}
