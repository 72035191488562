import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  TextField,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  FormControlLabel,
  Select,
  MenuItem,
  Button,
  Container,
  InputLabel,
} from "@mui/material";
import axios from "axios";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import ExcelUploader from "./ExcelUploud";
import "../styles/login.css";
import { Grid, colors } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  form: {
    marginTop: theme.spacing(2),
  },
  submitButton: {
    marginTop: theme.spacing(2),
  },
}));
const modalities = [
  { name: "Regular", _id: "Regular" },
  { name: "Extension", _id: "Extension" },
  { name: "Weekend", _id: "Weekend" },
];
const RegisterForm = () => {
  const classes = useStyles();

  const [courseList, setCourseList] = useState([]);
  const [message, setMessage] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [failur, setShowFailur] = useState(false);
  const [departments, setDepartmets] = useState([]);
  const [department, setDepartment] = useState("");
  const [programData, setPrograms] = useState([]);
  const [batchData, setBatchs] = useState([]);
  const [sectionData, setSections] = useState([]);
  const [program, setProgram] = useState("");

  const [data, setData] = useState({
    department: "",
    program: "",
    batch: "",
    modality: "",
    section: "",
  });
  const [studentData, setStudentData] = useState({
    id: "",
    firstName: "",
    middleName: "",
    lastName: "",
    sex: "male",
    password: "",
    role: "student",
    department: "",
    batch: "",
    modality: "",
    program: "",
    section: "",
  });
  const handleChange = (event) => {
    const { name, value } = event.target;
    setStudentData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleDataChange = (event) => {
    const { name, value } = event.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // You can add your logic to handle form submission here
    // studentData.department = department;
    // studentData.program = program;
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/register`,
        studentData
      );
      setShowPopup(true);
      setMessage("Student Register Successfully");
      // You can add additional logic based on the response
    } catch (error) {
      setShowFailur(true);
      setMessage("Error in register Id already registered");
      // You can handle errors here
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setShowFailur(false);
      setShowPopup(false);
    }, 10000);
  }, [showPopup, failur]);

  const fetchUser = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/questions`
      );
      setCourseList(response.data);
    } catch (error) {
      console.error("Error fetching user:", error.message);
    }
  };

  useEffect(() => {
    const fetchDepartment = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/department`
        );
        setDepartmets(response.data);
      } catch (error) {
        console.error("Error fetching user:", error.message);
      }
    };
    const fetchProgram = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/program`
        );
        setPrograms(response.data);
      } catch (error) {
        console.error("Error fetching user:", error.message);
      }
    };
    const fetchBatch = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/batch`
        );
        setBatchs(response.data);
      } catch (error) {
        console.error("Error fetching user:", error.message);
      }
    };
    const fetchSection = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/section`
        );
        setSections(response.data);
      } catch (error) {
        console.error("Error fetching user:", error.message);
      }
    };
    fetchSection();
    fetchBatch();
    fetchProgram();
    fetchUser();
    fetchDepartment();
    for (let x = 0; x < 1000000000; x++) {}
    // setUserAnswer(userData.answer);
  }, []);
  const handleDepartmentChange = (e) => {
    setDepartment(e.target.value);
  };
  const handleProgramChange = (e) => {
    setProgram(e.target.value);
  };

  return (
    <div style={{ marginBottom: "3rem" }}>
      <Container
        className="registration-container"
        style={{ width: "100%", marginLeft: "30px", maxWidth: "95%" }}
        // maxWidth="98%"
      >
        <div>
          <ExcelUploader
            setMessage={setMessage}
            setShowPopup={setShowPopup}
            setShowFailur={setShowFailur}
            department={department}
            handleDepartmentChange={handleDepartmentChange}
            departments={departments}
            batchs={batchData}
            programs={programData}
            modalities={modalities}
            sections={sectionData}
            data={data}
            setData={setData}
            handleDataChange={handleDataChange}
          />
        </div>
      </Container>
      <Container
        className="registration-container"
        style={{
          width: "100%",
          marginLeft: "30px",
          maxWidth: "95%",
          height: "auto",
        }}
      >
        <Stack sx={{ width: "100%" }} spacing={2} style={{ marginTop: "30px" }}>
          {showPopup && (
            <Alert variant="filled" severity="success">
              {message}
            </Alert>
          )}

          {failur && (
            <Alert variant="filled" severity="error">
              {message}
            </Alert>
          )}
        </Stack>

        <form className={classes.form} onSubmit={handleSubmit}>
          <div className="name-form" style={{ gap: "20px" }}>
            <TextField
              label="ID"
              name="id"
              fullWidth
              value={studentData.id}
              onChange={handleChange}
              margin="normal"
              required
            />
            <TextField
              label="First Name"
              name="firstName"
              fullWidth
              value={studentData.firstName}
              onChange={handleChange}
              margin="normal"
              required
            />
            <TextField
              label="Middle Name"
              name="middleName"
              fullWidth
              value={studentData.middleName}
              onChange={handleChange}
              margin="normal"
            />

            <TextField
              label="Last Name"
              name="lastName"
              fullWidth
              value={studentData.lastName}
              onChange={handleChange}
              margin="normal"
              required
            />
          </div>
          <FormControl component="fieldset" margin="normal">
            <FormLabel component="legend">Sex</FormLabel>
            <RadioGroup
              aria-label="sex"
              name="sex"
              value={studentData.sex}
              onChange={handleChange}
              row
            >
              <FormControlLabel value="male" control={<Radio />} label="Male" />
              <FormControlLabel
                value="female"
                control={<Radio />}
                label="Female"
              />
            </RadioGroup>
          </FormControl>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <FormControl style={{ width: "80%" }}>
                <InputLabel id="demo-simple-select-label">
                  Department
                </InputLabel>
                <Select
                  name="department"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={studentData.department}
                  label="Department"
                  onChange={handleChange}
                >
                  {departments?.map((dept) => (
                    <MenuItem
                      key={dept._id}
                      value={dept._id}
                      sx={{
                        textAlign: "left",
                      }}
                    >
                      {dept.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <FormControl style={{ width: "80%" }}>
                <InputLabel id="demo-simple-select-label">Program</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  name="program"
                  id="demo-simple-select"
                  value={studentData.program}
                  label="Department"
                  onChange={handleChange}
                >
                  {programData?.map((prog) => (
                    <MenuItem
                      key={prog._id}
                      value={prog._id}
                      sx={{
                        // height: "40px",
                        textAlign: "left",
                      }}
                    >
                      {prog.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <FormControl style={{ width: "80%" }}>
                <InputLabel id="demo-simple-select-label">Batch</InputLabel>
                <Select
                  name="batch"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={studentData.batch}
                  label="Department"
                  onChange={handleChange}
                >
                  {batchData?.map((bach) => (
                    <MenuItem
                      key={bach._id}
                      value={bach._id}
                      sx={{
                        // height: "40px",
                        textAlign: "left",
                      }}
                    >
                      {bach.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <FormControl style={{ width: "80%" }}>
                <InputLabel id="demo-simple-select-label">Modality</InputLabel>
                <Select
                  name="modality"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={studentData.modality}
                  label="Department"
                  onChange={handleChange}
                >
                  {modalities?.map((mod) => (
                    <MenuItem
                      key={mod._id}
                      value={mod._id}
                      sx={{
                        // height: "40px",
                        textAlign: "left",
                      }}
                    >
                      {mod.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <FormControl style={{ width: "80%" }}>
                <InputLabel id="demo-simple-select-label">Section</InputLabel>
                <Select
                  name="section"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={studentData.section}
                  label="Section"
                  onChange={handleChange}
                >
                  {sectionData?.map((sec) => (
                    <MenuItem
                      key={sec._id}
                      value={sec._id}
                      sx={{
                        // height: "40px",
                        textAlign: "left",
                      }}
                    >
                      {sec.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <FormControl style={{ width: "80%" }}>
                <InputLabel id="demo-simple-select-label">Role</InputLabel>
                {/* <FormLabel>Role</FormLabel> */}
                <Select
                  name="role"
                  value={studentData.role}
                  onChange={handleChange}
                >
                  <MenuItem value="student">Student</MenuItem>
                  {/* <MenuItem value="admin">Admin</MenuItem> */}
                </Select>
              </FormControl>
            </Grid>
            <div style={{ width: "100%", marginTop: "50px" }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.submitButton}
                style={{ margin: "1%", float: "right" }}
              >
                Register
              </Button>
            </div>
          </Grid>
        </form>
      </Container>
    </div>
  );
};

export default RegisterForm;
