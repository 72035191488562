import React, { useEffect, useState } from "react";
import "../styles/Result.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  attempts_Number,
  earnPoints_Number,
  flagResult,
} from "../helper/helper";
import Button from "@mui/material/Button";

/** import actions  */
import { resetAllAction } from "../redux/question_reducer";
import { resetResultAction } from "../redux/result_reducer";
import { UserResultAnswer, usePublishResult } from "../hooks/setResult";
import axios from "axios";
import { MovePrevQuestion } from "../hooks/FetchQuestion";
import { ResultModal } from "./Admin/Result/ResultModal";

export default function Result({
  open,
  handleClickOpen,
  handleClose,
  showPopup,
  setOpen,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    questions: { queue },
    result: { result, userId },
  } = useSelector((state) => state);

  const { trace } = useSelector((state) => state.questions);

  const answers = queue.map((question) => question.correctAnswer);
  const totalPoints = queue.length;
  const attempts = attempts_Number(result);
  // const flag = flagResult(totalPoints, earnPoints);

  const { examId } = useParams();
  const department = localStorage.getItem("department");
  const student = localStorage.getItem("userId");

  const finalResult = {
    exam: examId,
    department,
    student,
    result: earnPoints_Number(result, answers, 1),
    answer: result,
  };

  /** store user result */
  usePublishResult({
    result,
    username: userId,
    attempts,
    points: earnPoints_Number(result, answers, 1),
    achived: "",
  });

  const handleResultSubmit = () => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/result/update-result`;
    axios
      .put(`${apiUrl}/${student}/${examId}`, {
        result: finalResult,
      })
      .then((response) => {
        navigate(`/result-detail/${examId}`);
      })
      .catch((error) => {
        console.error("Error updating student examTime:", error);
      });
  };

  //   handleResultSubmit();
  if (showPopup) {
    handleResultSubmit();
  }
  // if (trace !== 0 && queue?.length !== 0 && trace >= queue?.length) {
  //   setOpen(true);
  //   handleResultSubmit();
  // }
  return (
    <div
      className="container shadow"
      style={{
        backgroundColor: "white",
        boxShadow: "0 0.5rem 1rem rgba(0, 0, 0, 0.15)",
        // height: "90%",
        width: "70%",
        maxHeight: "70%",
        marginTop: "100px",
      }}
    >
      {/* <h1 className='title text-light'>Quiz Application</h1> */}
      {/* <div className="result flex-center">
        <div className="flex">
          <span style={{ color: "black" }}>Student Name</span>
          <span className="bold" style={{ color: "black" }}>
            {localStorage.getItem("name") || ""}
          </span>
        </div>
        <div className="flex">
          <span style={{ color: "black" }}>Total Exam Points : </span>
          <span className="bold" style={{ color: "black" }}>
            {totalPoints || 0}
          </span>
        </div>
        <div className="flex"></div>
      </div>

      <div className="start">
        <Button
          variant="contained"
          onClick={handleResultSubmit}
          style={{ background: "green" }}
        >
          Submit Result
        </Button>
      </div>

      <div
        className="container"
        style={{
          maxHeight: "350px",
          width: "80%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button variant="contained" onClick={handleQuestionCOunt}>
          Back to Question
        </Button>
      </div> */}
      {open && (
        <ResultModal
          handleClickOpen={handleClickOpen}
          handleClose={handleClose}
          open={open}
          handleDelete={handleResultSubmit}
        />
      )}
    </div>
  );
}
