import React, { useEffect, useState } from "react";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Container, FormControl, InputLabel } from "@material-ui/core";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { Card, Grid, MenuItem, Select, TextField } from "@mui/material";
import MultipleSelect from "../../ui/MultiSelector";
import TimePickerViews from "../../ui/TimePicker";
import { useNavigate, useParams } from "react-router-dom";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const useStyles = makeStyles((theme) => ({
  form: {
    marginTop: theme.spacing(2),
  },
  submitButton: {
    marginTop: theme.spacing(2),
  },
}));

const modalities = [
  { name: "Regular", _id: "Regular" },
  { name: "Extension", _id: "Extension" },
  { name: "Weekend", _id: "Weekend" },
];

const EditExamForm = () => {
  const classes = useStyles();
  const [examData, setExamData] = useState({
    name: "",
    password: "",
    course: "",
    department: [],
    program: [],
    modality: [],
    batch: [],
    examTime: "",
    instructor: "",
    type: "",
    year: "",
    semester: "",
    date: "",
  });
  const [departments, setDepartments] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [batches, setBatches] = useState([]);
  const [courses, setCourses] = useState([]);
  const [message, setMessage] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [failure, setFailure] = useState(false);
  const navigate = useNavigate();
  const { examId } = useParams();

  const handleTimeEdit = (time) => {
    // Split the time string into hours, minutes, and seconds
    const [hours, minutes, seconds] = time.split(":");

    // Create a new Date object with the specified hours, minutes, and seconds
    const timeObject = new Date();
    timeObject.setHours(hours);
    timeObject.setMinutes(minutes);
    timeObject.setSeconds(seconds);

    // Update the examData with the new time object
    setExamData((prevExamData) => ({
      ...prevExamData,
      examTime: time,
    }));
  };
  useEffect(() => {
    const fetchExamData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/question-delete/${examId}`
        );

        const { examTime, ...restData } = response.data;

        const modifiedExamTime = handleTimeEdit(examTime);

        setExamData({
          ...restData,
          examTime: modifiedExamTime,
        });

      } catch (error) {
        console.error("Error fetching exam data:", error.message);
      }
    };

    fetchExamData();
  }, [examId]);
  const handleChange = (e) => {
    const { name, value } = e.target;

    setExamData({
      ...examData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/question/${examId}`,
        examData
      );
      setShowPopup(true);
      setMessage("Exam updated successfully");
      navigate(-1);
      // You can redirect or perform additional actions after successful update
    } catch (error) {
      setFailure(true);
      // setMessage("Error updating exam: ", error.response.message);
    }
  };
  //by it extension 15, acc reg 13
  const handleTimeChange = (time) => {
    setExamData({
      ...examData,
      examTime: time,
    });
  };
  //////
  useEffect(() => {
    const fetchDepartment = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/department`
        );
        setDepartments(response.data);
      } catch (error) {
        console.error("Error fetching user:", error.message);
      }
    };
    fetchDepartment();
  }, []);
  useEffect(() => {
    const fetchBatch = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/batch`
        );
        setBatches(response.data);
      } catch (error) {
        console.error("Error fetching user:", error.message);
      }
    };
    fetchBatch();
  }, []);
  useEffect(() => {
    const fetchCourse = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/course`
        );
        setCourses(response.data);
      } catch (error) {
        console.error("Error fetching user:", error.message);
      }
    };
    fetchCourse();
  }, []);

  useEffect(() => {
    const fetchProgram = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/program`
        );
        setPrograms(response.data);
      } catch (error) {
        console.error("Error fetching user:", error.message);
      }
    };
    fetchProgram();
  }, []);

  const handleDateChange = (newDate) => {
    setExamData({
      ...examData,
      date: newDate ? dayjs(newDate).format("YYYY-MM-DD") : null,
    });
  };

  useEffect(() => {
    setTimeout(() => {
      setShowPopup(false);
      setFailure(false);
    }, 5000);
  }, [showPopup, failure]);
  return (
    <Container maxWidth="90%">
      <Card style={{ padding: "3rem" }}>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <h2>Edit Exam</h2>
          <Button
            style={{ background: "red", color: "white", height: "30px" }}
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
        </div>
        <Stack sx={{ width: "100%" }} spacing={2} style={{ marginTop: "30px" }}>
          {showPopup && (
            <Alert variant="filled" severity="success">
              {message}
            </Alert>
          )}

          {failure && (
            <Alert variant="filled" severity="error">
              {message}
            </Alert>
          )}
        </Stack>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Grid
            container
            rowSpacing={3}
            columnSpacing={{ xs: 3, sm: 2, md: 3 }}
          >
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <h4>Edit Exam Name</h4>

              <TextField
                label="Exam title"
                name="name"
                fullWidth
                margin="normal"
                value={examData.name}
                onChange={handleChange}
                required
                style={{ marginTop: "-10px" }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <h4>Enter Instructor name</h4>
              <TextField
                label="Instructor"
                name="instructor"
                fullWidth
                margin="normal"
                value={examData.instructor}
                onChange={handleChange}
                // required
                style={{ marginTop: "-10px" }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <h4>Edit Exam Password</h4>
              <TextField
                label="Exam Password"
                name="password"
                type="password"
                fullWidth
                value={examData.password}
                onChange={handleChange}
                margin="normal"
                required
                style={{ marginTop: "-10px" }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={4}
              style={{ marginTop: "-40px" }}
            >
              <h4>Edit Exam Course</h4>
              <FormControl style={{ width: "100%", marginTop: "-10px" }}>
                {/* <InputLabel>COURSE</InputLabel> */}
                <Select
                  name="course"
                  value={examData.course}
                  onChange={handleChange}
                  required
                >
                  {courses?.map((course) => (
                    <MenuItem key={course._id} value={course._id}>
                      {course.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={4}
              style={{ marginTop: "-10px" }}
            >
              <MultipleSelect
                data={departments}
                name="department"
                value={examData.department}
                onChange={handleChange}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={4}
              style={{ marginTop: "-10px" }}
            >
              <MultipleSelect
                data={programs}
                name="program"
                value={examData.program}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <MultipleSelect
                data={batches}
                name="batch"
                value={examData.batch}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <MultipleSelect
                data={modalities}
                name="modality"
                value={examData.modality}
                onChange={handleChange}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={3}
              style={{ marginTop: "-20px" }}
            >
              <h4>Edit Exam Time</h4>
              <TimePickerViews
                value={examData.examTime}
                onChange={handleTimeChange}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={3}>
              <h4>Enter Exam year</h4>
              <TextField
                label="Exam year"
                name="year"
                type="number"
                fullWidth
                value={examData.year}
                onChange={handleChange}
                margin="normal"
                required
                style={{ marginTop: "-10px" }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3}>
              <h4>Enter Exam Semester</h4>
              <FormControl style={{ width: "100%", marginTop: "-10px" }}>
                {/* <InputLabel
                  id="demo-simple-select-label"
                  style={{ marginLeft: "10px" }}
                >
                  Semester
                </InputLabel> */}
                <Select
                  labelId="demo-simple-select-label"
                  name="semester"
                  id="demo-simple-select"
                  value={examData.semester}
                  label="Semester"
                  onChange={handleChange}
                >
                  <MenuItem value="I">I</MenuItem>
                  <MenuItem value="II">II</MenuItem>
                  <MenuItem value="III">III</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3}>
              <h4>Enter Exam type</h4>
              <TextField
                label="Exam type"
                name="type"
                fullWidth
                value={examData.type}
                onChange={handleChange}
                margin="normal"
                required
                style={{ marginTop: "-10px" }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={3}
              style={{ marginTop: "20px" }}
            >
              <div style={{ fontWeight: "bold" }}>Exam Date</div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    name="date"
                    value={examData.data}
                    onChange={handleDateChange}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
          </Grid>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submitButton}
            style={{ marginTop: "20px" }}
          >
            Update
          </Button>
        </form>
      </Card>
    </Container>
  );
};

export default EditExamForm;
