/* eslint-disable react/prop-types */
import { CardMedia } from "@mui/material";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import axios from "axios";
import Modalpopup from "../../ui/Modal";
import { format } from "date-fns";

const Button = styled.button`
  display: none;
  width: 100%;
  padding: 10px;
  background-color: ${({ theme }) => theme.white};
  color: ${({ theme }) => theme.text_black};
  font-size: 14px;
  font-weight: 700;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.8s ease-in-out;
`;

const Card = styled.div`
  width: 100%;
  height: auto;
  background-color: ${({ theme }) => theme.card};
  cursor: pointer;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 14px;
  transition: all 0.5s ease-in-out;
  &:hover ${Button} {
    display: block;
  }
  @media (max-width: 370px) {
    grid-template-columns: repeat(1, 1fr);
    width: 90%;
    height: auto;
  }
`;

const Tags = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 4px;
`;

const Tag = styled.span`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.primary};
  background-color: ${({ theme }) => theme.primary + 15};
  padding: 2px 8px;
  border-radius: 10px;
`;

const Details = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0px;
  padding: 0px 2px;
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.text_secondary};
  overflow: hidden;
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const DateWrapper = styled.div`
  font-size: 12px;
  margin-left: 2px;
  font-weight: 400;
  color: ${({ theme }) => theme.text_secondary + 80};
  @media only screen and (max-width: 768px) {
    font-size: 10px;
  }
`;

const Description = styled.div`
  font-weight: 400;
  color: ${({ theme }) => theme.text_secondary + 99};
  overflow: hidden;
  margin-top: 8px;
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
`;

const ExamDetail = ({ project, subjectId, takenStatus }) => {
  const [alert, setAlert] = useState(false);
  const [isTaken, setIsTaken] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => setAlert(false), 5000);
    return () => clearTimeout(timer);
  }, [alert]);

  useEffect(() => {
    async function fetchStudent() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/result/${project?._id}`
        );
        const datas = response.data;
        if (datas?.length > 0) {
          setIsTaken(true);
        }
      } catch (error) {
        console.error("Error fetching student data:", error);
      }
    }

    fetchStudent();
  }, [project]);

  return (
    <>
      <Card onClick={() => navigate(`/student-result/${project?._id}`)}>
        <CardMedia
          component="img"
          height="140"
          width="100%"
          image="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRnbAF8gP-5GH9F5xBotX3P1CYQ6s3JunBjR00MNMvqhA&s"
          alt="green iguana"
        />
        {/* <Image src={project.image} /> */}
        <Details>
          <Title>{project?.name}</Title>
          <Tags>
            {/* <Tag>{project?.program?.name}</Tag> */}
            <Tag>{project?.status}</Tag>
          </Tags>
          {/* <DateWrapper>{project.date}</DateWrapper> */}
          <DateWrapper style={{ color: "green" }}>
            {project?.department?.name}
          </DateWrapper>
          <Description>{project?.instructor}</Description>
          <Description>Exam Time: {project?.examTime}</Description>
          <Description>
            {project?.date
              ? format(new Date(project?.date), "MMMM dd, yyyy HH:mm:ss")
              : "Unknown Date"}
          </Description>
          <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
            <Description
              style={{
                background: project?.status ? "green" : "red",
                width: "90px",
                borderRadius: "10px",
                textAlign: "center",
                color: "#fff",
              }}
            >
              {project.status ? "Active" : "Not Active"}
            </Description>
            <Description
              style={{
                background: takenStatus ? "green" : "red",
                width: "90px",
                borderRadius: "10px",
                textAlign: "center",
                color: "#fff",
              }}
            >
              {takenStatus ? "Taken" : "Not taken"}
            </Description>
          </div>
        </Details>
      </Card>
    </>
  );
};

export default ExamDetail;
