import axios from "axios";

const API_BASE_URL = `${process.env.REACT_APP_API_URL}/api`; // Update with your server URL

const api = axios.create({
  baseURL: API_BASE_URL,
});

export const getStudents = () => api.get("/students");
export const getStudentById = (id) => api.get(`/students/${id}`);
export const createStudent = (data) => api.post("/students", data);
export const updateStudent = (id, data) => api.put(`/students/${id}`, data);
export const deleteStudent = (id) => api.delete(`/students/${id}`);

const apiObject = {
  getStudents,
  getStudentById,
  createStudent,
  updateStudent,
  deleteStudent,
};

export default apiObject;
