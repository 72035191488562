import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import DataTable from "./DataTable";
import BasicSelect from "./Select";
import { useNavigate, useParams } from "react-router-dom";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ExamSwitch from "./ui/Switch";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Button,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
} from "@mui/material";
import ExcelExamUploader from "./ExamExcelUpload";
import { format } from "date-fns";

const useStyles = makeStyles((theme) => ({
  form: {
    marginTop: theme.spacing(2),
  },
  submitButton: {
    marginTop: theme.spacing(2),
  },
}));

const modalities = [
  { name: "Regular", _id: "Regular" },
  { name: "Extension", _id: "Extension" },
  { name: "Weekend", _id: "Weekend" },
];

const StudentResult = () => {
  const [studentData, setStudentData] = useState([]);
  const [department, setDepartmets] = useState("All");
  const [searchValue, setSearchValue] = useState("");
  const [checked, setChecked] = React.useState(false);
  const [expire, setExpire] = useState(false);
  const { examId } = useParams();
  const navigate = useNavigate();
  const [examData, setExamData] = useState({});
  const [departments, setDepartmetss] = useState([]);
  const [programData, setPrograms] = useState([]);
  const [batchData, setBatchs] = useState([]);
  const [sectionData, setSections] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedExamId, setSelectedExamId] = useState("");
  const [isTaken, setIsTaken] = useState();
  const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] =
    useState(false);
  const [flag, setFlag] = useState(false);
  const [data, setData] = useState({
    department: "",
    program: "",
    batch: "",
    modality: "",
    section: "",
  });

  async function fetchStudent() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/result/${examId}?program=${data.program}&department=${data.department}&modality=${data.modality}&batch=${data.batch}&section=${data.section}`
      );
      const datas = response.data;
      // Process the retrieved data here
      setStudentData(datas);
      if (datas?.length > 0) {
        setIsTaken(true);
      }
    } catch (error) {
      // Handle error
      console.error("Error fetching student data:", error);
    }
  }

  async function fetchExam() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/questions/${examId}`
      );
      const data = response.data;

      setExamData(data);
      // Process the retrieved data here
      setChecked(data.status);
      setExpire(data.expire);
    } catch (error) {
      // Handle error
      console.error("Error fetching student data:", error);
    }
  }

  const handleDataChange = (event) => {
    const { name, value } = event.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSearchInputChange = (event) => {
    const inputValue = event.target.value.toLowerCase();
    setSearchValue(inputValue);
    // setFilteredData(filteredResults);
  };

  useEffect(() => {
    fetchExam();
  }, []);

  useEffect(() => {
    fetchStudent();
  }, [data, flag]);
  const classes = useStyles();

  useEffect(() => {
    const fetchDepartment = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/department`
        );
        setDepartmets(response.data);
      } catch (error) {
        console.error("Error fetching user:", error.message);
      }
    };
    fetchDepartment();
  }, []);

  useEffect(() => {
    const fetchDepartment = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/department`
        );
        setDepartmetss(response.data);
      } catch (error) {
        console.error("Error fetching user:", error.message);
      }
    };
    const fetchProgram = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/program`
        );
        setPrograms(response.data);
      } catch (error) {
        console.error("Error fetching user:", error.message);
      }
    };
    const fetchBatch = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/batch`
        );
        setBatchs(response.data);
      } catch (error) {
        console.error("Error fetching user:", error.message);
      }
    };
    const fetchSection = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/section`
        );
        setSections(response.data);
      } catch (error) {
        console.error("Error fetching user:", error.message);
      }
    };
    fetchSection();
    fetchBatch();
    fetchProgram();
    fetchDepartment();
    for (let x = 0; x < 1000000000; x++) {}
  }, []);

  const handleChange = (event) => {
    setChecked(event.target.checked);
    handleStatusChange(event.target.checked);
  };
  const handleExpire = (event) => {
    setExpire(event.target.checked);
    handleExpireChange(event.target.checked);
  };
  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleStatusChange = (status) => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/question/update-status`;

    axios
      .put(`${apiUrl}/${examId}`, {
        status,
      })
      .then((response) => {
        console.log("Student result updated successfully:");
      })
      .catch((error) => {
        console.error("Error updating student examTime:", error);
      });
  };

  const handleExpireChange = (expire) => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/question/update-expire`;

    axios
      .put(`${apiUrl}/${examId}`, {
        expire,
      })
      .then((response) => {
        console.log("Student result updated successfully:");
      })
      .catch((error) => {
        console.error("Error updating student examTime:", error);
      });
  };

  const handleFileUpload = () => {
    const formData = new FormData();
    formData.append("file", selectedFile);

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/question-upload/${examId}`,
        formData
      )
      .then((response) => {
        toast.success("Question uploaded Successfully!");
      })
      .catch((error) => {
        console.error("Error in uploading file:", error.message);
      });
  };

  const handleDeleteConfirm = async () => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/question-delete/${selectedExamId}`
      );
      // Refresh the exam list after deletion
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/questions`
      );
      navigate(-1);
    } catch (error) {
      console.error("Error deleting exam:", error.message);
    }
    setDeleteConfirmationModalOpen(false);
  };

  const handleDeleteClick = (examId) => {
    setSelectedExamId(examId);
    setDeleteConfirmationModalOpen(true);
  };

  const filteredStudents =
    studentData &&
    studentData?.filter((student) => {
      const firstName = student?.student?.firstName?.toLowerCase();
      const id = student?.student?.id?.toString().toLowerCase();

      // Check if the first name or ID matches the search term
      return firstName?.includes(searchValue) || id?.includes(searchValue);
    });

  return (
    <div maxWidth="sm" style={{ margin: "50px 60px" }}>
      <Button
        style={{ background: "red", color: "white" }}
        onClick={() => navigate(-1)}
      >
        Back
      </Button>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          fontWeight: "bold",
          margin: "10px",
        }}
      >
        <div style={{ width: "100%" }}>
          <Button
            variant="contained"
            color="success"
            onClick={() => navigate(`/question-detail/${examId}`)}
            style={{
              width: "100px",
              float: "right",
              margin: "0 10px 10px 0",
            }}
          >
            Review
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => handleDeleteClick(examId)}
            style={{
              width: "100px",
              float: "right",
              margin: "0 10px 10px 0",
            }}
          >
            Delete
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate(`/exam/edit/${examId}`)}
            style={{
              width: "100px",
              float: "right",
              margin: "0 10px 10px 0",
            }}
          >
            Edit
          </Button>
          {/* <MoreVertIcon
                      style={{
                        float: "right",
                        margin: "10px",
                        cursor: "pointer",
                      }}
                    /> */}
        </div>
      </div>
      <Card style={{ marginBottom: "20px", padding: "7px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            fontWeight: "bold",
          }}
        >
          <div
            style={{
              color: "#5a95fa",
              fontSize: "20px",
              width: "50%",
              maxWidth: "50%",
            }}
          >
            {examData.name}({examData?.questions?.length})
          </div>

          <div
            style={{
              width: "15%",
              // marginBottom: "30px",
              display: "flex",
              justifyContent: "end",
            }}
          >
            <div
              style={{
                textAlign: "center",
                display: "flex",
                alignItems: "center",
              }}
            >
              Exam Visibility
            </div>
            <ExamSwitch
              checked={checked}
              setChecked={setChecked}
              handleChange={handleChange}
            />
          </div>
          <div
            style={{
              width: "15%",
              // marginBottom: "30px",
              display: "flex",
              justifyContent: "end",
            }}
          >
            <div
              style={{
                textAlign: "center",
                display: "flex",
                alignItems: "center",
              }}
            >
              <span style={{ color: "red" }}>Exam Expired</span>
            </div>
            <ExamSwitch
              checked={expire}
              setChecked={setExpire}
              handleChange={handleExpire}
            />
          </div>
        </div>
      </Card>

      <Card style={{ marginBottom: "20px", padding: "7px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            fontWeight: "bold",
          }}
        >
          <div>
            Instructor :{" "}
            <span style={{ color: "#5a95fa" }}>{examData?.instructor}</span>
          </div>
          <div>
            Exam Password :{" "}
            <span style={{ color: "#5a95fa" }}>{examData?.password}</span>
          </div>

          <div>
            <div>
              Exam Date :{" "}
              <span style={{ color: "#5a95fa" }}>
                {examData?.date
                  ? format(new Date(examData?.date), "MMMM dd, yyyy HH:mm:ss")
                  : "Unknown Date"}
              </span>
            </div>
          </div>
          <div>
            <div>
              Exam Time :{" "}
              <span style={{ color: "#5a95fa" }}>{examData?.examTime}</span>
            </div>
          </div>
          <div>
            <div>
              Course :{" "}
              <span style={{ color: "#5a95fa" }}>{examData?.course?.name}</span>
            </div>
          </div>
          <div
            style={{
              background: isTaken ? "green" : "red",
              width: "100px",
              borderRadius: "10px",
              textAlign: "center",
            }}
          >
            {isTaken ? "Taken" : "Not taken"}
          </div>
        </div>
      </Card>

      <Card
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          padding: "7px",
          marginBottom: "20px",
          marginTop: "-10px",
          fontWeight: "bold",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            // padding: "7px",
            marginBottom: "20px",
            // marginTop: "-10px",
            fontWeight: "bold",
          }}
        >
          <div>
            <div>Department</div>
            <div>
              {examData?.department?.map((department, i) => (
                <ul key={i}>
                  <div style={{ color: "#5a95fa" }}>{department.name}</div>
                </ul>
              ))}
            </div>
          </div>
          <div>
            <div>Batch</div>
            <div>
              {examData?.batch?.map((batch, i) => (
                <ul key={i}>
                  <div style={{ color: "#5a95fa" }}>{batch.name}</div>
                </ul>
              ))}
            </div>
          </div>
          <div>
            <div>Program</div>
            <div>
              {examData?.program?.map((program, i) => (
                <ul key={i}>
                  <div style={{ color: "#5a95fa" }}>{program.name}</div>
                </ul>
              ))}
            </div>
          </div>
          <div>
            <div>Modality</div>
            <div>
              {examData?.modality?.map((modality, i) => (
                <ul key={i}>
                  <div style={{ color: "#5a95fa" }}>{modality}</div>
                </ul>
              ))}
            </div>
          </div>
          <div>
            <div>Year</div>
            <div style={{ color: "#5a95fa" }}>{examData?.year}</div>
          </div>
          <div>
            <div>Semester</div>
            <div style={{ color: "#5a95fa" }}>{examData?.semester}</div>
          </div>
          <div>
            <div>Type</div>
            <div style={{ color: "#5a95fa" }}>{examData?.type}</div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
            gap: "5%",
          }}
        ></div>

        <div
          style={{
            // border: "2px solid blue",
            padding: "10px",
            // boxShadow: "0 0 1px 4px rgba(179, 178, 178, 0.4)",
            background: "white",
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
            alignItems: "center",
            gap: "20px",
          }}
        >
          <div style={{ position: "relative" }}>
            {/* <h3 style={{ width: "auto" }}>Excel File Uploader</h3> */}
            <label
              htmlFor="file-upload"
              style={{
                display: "inline-block",
                padding: "6px 12px",
                cursor: "pointer",
                backgroundColor: "#4caf50",
                color: "#fff",
                borderRadius: "4px",
                border: "none",
                fontSize: "14px",
              }}
            >
              <span
                style={{
                  marginRight: "6px",
                  fontSize: "18px",
                  verticalAlign: "middle",
                }}
              >
                {/* Replace the 'mui-icon' component with the appropriate Material-UI icon component */}
                <CloudUploadIcon />
              </span>{" "}
              Choose File
            </label>
            <input
              id="file-upload"
              type="file"
              onChange={handleFileSelect}
              style={{ display: "none" }}
            />
            {selectedFile && <p>Selected file: {selectedFile.name}</p>}
          </div>
          <div>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={handleFileUpload}
              style={{ float: "right" }}
              // className={classes.submitButton}
            >
              Upload Excel File
            </Button>
          </div>
        </div>
      </Card>
      <div
        style={{
          width: "100%%",
          marginBottom: "30px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <TextField
          id="outlined-basic"
          label="Search"
          variant="outlined"
          onChange={handleSearchInputChange}
        />
      </div>
      <div style={{ width: "100%", marginBottom: "30px" }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} sm={12} md={6} lg={2}>
            <FormControl style={{ width: "80%" }}>
              <InputLabel id="demo-simple-select-label">Department</InputLabel>
              <Select
                name="department"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={data.department}
                label="Department"
                onChange={handleDataChange}
              >
                {departments?.map((dept) => (
                  <MenuItem
                    key={dept._id}
                    value={dept._id}
                    sx={{
                      height: "40px",
                      textAlign: "left",
                    }}
                  >
                    {dept.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={2}>
            <FormControl style={{ width: "80%" }}>
              <InputLabel id="demo-simple-select-label">Program</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                name="program"
                id="demo-simple-select"
                value={data.program}
                label="Department"
                onChange={handleDataChange}
              >
                {programData?.map((prog) => (
                  <MenuItem
                    key={prog._id}
                    value={prog._id}
                    sx={{
                      height: "40px",
                      textAlign: "left",
                    }}
                  >
                    {prog.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={2}>
            <FormControl style={{ width: "80%" }}>
              <InputLabel id="demo-simple-select-label">Batch</InputLabel>
              <Select
                name="batch"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={data.batch}
                label="Department"
                onChange={handleDataChange}
              >
                {batchData?.map((bach) => (
                  <MenuItem
                    key={bach._id}
                    value={bach._id}
                    sx={{
                      height: "40px",
                      textAlign: "left",
                    }}
                  >
                    {bach.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={2}>
            <FormControl style={{ width: "80%" }}>
              <InputLabel id="demo-simple-select-label">Modality</InputLabel>
              <Select
                name="modality"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={data.modality}
                label="Department"
                onChange={handleDataChange}
              >
                {modalities?.map((mod) => (
                  <MenuItem
                    key={mod._id}
                    value={mod._id}
                    sx={{
                      height: "40px",
                      textAlign: "left",
                    }}
                  >
                    {mod.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={2}>
            <FormControl style={{ width: "80%" }}>
              <InputLabel id="demo-simple-select-label">Section</InputLabel>
              <Select
                name="section"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={data.section}
                label="Section"
                onChange={handleDataChange}
              >
                <MenuItem
                  value=""
                  sx={{
                    height: "40px",
                    textAlign: "left",
                  }}
                >
                  All
                </MenuItem>
                {sectionData?.map((sec) => (
                  <MenuItem
                    key={sec._id}
                    value={sec._id}
                    sx={{
                      height: "40px",
                      textAlign: "left",
                    }}
                  >
                    {sec.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </div>
      <DataTable
        studentData={filteredStudents}
        examId={examId}
        setFlag={setFlag}
        flag={flag}
      />
      <Modal
        open={deleteConfirmationModalOpen}
        onClose={() => setDeleteConfirmationModalOpen(false)}
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            padding: "20px",
          }}
        >
          <h2>Are you sure you want to delete this exam?</h2>
          <div style={{ width: "100%" }}>
            <div style={{ float: "right" }}>
              <Button
                variant="contained"
                color="error"
                onClick={handleDeleteConfirm}
              >
                Delete
              </Button>
              <Button
                variant="contained"
                onClick={() => setDeleteConfirmationModalOpen(false)}
                style={{ marginLeft: "10px" }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      <ToastContainer />
    </div>
  );
};

export default StudentResult;
