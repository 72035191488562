import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import { IoMdAddCircleOutline } from "react-icons/io";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useEffect, useState } from "react";
import { Button, Card, Container } from "@mui/material";
import axios from "axios";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function SectionForm() {
  const [isAdd, setIsAdd] = useState(false);
  const [isEdit, setIsEdit] = useState({});
  const [editedData, setEditedData] = useState({ id: "", name: "" });
  const [deleteData, setDeleteData] = useState({ id: "", name: "" });
  const [batchs, setBatchs] = useState([]);
  const [newBatch, setNewBatch] = useState("");
  const [flag, setFlag] = useState(false);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    const fetchSection = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/section`
        );
        setBatchs(response.data);
      } catch (error) {
        console.error("Error fetching section:", error.message);
      }
    };
    fetchSection();
  }, [flag]);

  const handleEdit = (id, name) => {
    setIsEdit((prevState) => ({
      // ...prevState,
      [id]: !prevState[id],
      id: id,
      name: name,
    }));

    // const department = departments.find((dept) => dept._id === id);
    setEditedData({ id, name });
  };

  const handleSave = async () => {
    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/section/${editedData.id}`,
        { name: editedData.name }
      );
      setIsEdit({});
      setEditedData({});
      //   setFlag(!flag);
      toast.success("Section Edited Successfully!");
      // Optional: Refresh the department list or perform any other necessary actions
    } catch (error) {
      console.error("Error updating section:", error.message);
      toast.error("network error", error);
    }
  };

  const handleCreate = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/section`, {
        name: newBatch,
      });
      setNewBatch("");
      setFlag(!flag);
      setIsAdd(false);
      toast.success("New Section created Successfully!");
      // Optional: Refresh the department list or perform any other necessary actions
    } catch (error) {
      toast.error(error.response.data.error);
    }
  };

  const handleChange = (e, id) => {
    setBatchs((prevPrograms) =>
      prevPrograms.map((dept) => {
        if (dept._id === id) {
          return { ...dept, name: e.target.value };
        }
        return dept;
      })
    );
    setEditedData((prev) => ({
      ...prev,
      name: e.target.value,
    }));
  };

  const handleDelete = async () => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/section/${deleteData.id}`
      );
      setNewBatch("");
      //   setFlag(!flag);
      setIsAdd(false);
      handleClose();
      toast.success("Section Deleted Successfully!");
    } catch (error) {
      handleClose();
      toast.error("error");
    }
  };
  return (
    <Container>
      <h2>Section Registration</h2>
      <Card
        style={{
          padding: "3rem",
          display: "flex",
          flexDirection: "column",
          gap: "40px",
          width: "500px",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          {batchs &&
            batchs.map((dept) => (
              <FormControl
                key={dept._id}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "10px",
                }}
              >
                <OutlinedInput
                  value={dept?.name}
                  disabled={!isEdit[dept._id]}
                  onChange={(e) => handleChange(e, dept._id)}
                />
                {!(isEdit.id == dept._id) && (
                  <DeleteIcon
                    style={{
                      height: "30px",
                      width: "30px",
                      color: "#ed3e3b",
                      cursor: "pointer",
                    }}
                    // onClick={() => handleDelete(dept?._id, dept?.name)}
                    onClick={() => {
                      setDeleteData({ id: dept?._id, name: dept?.name });
                      handleClickOpen();
                    }}
                  />
                )}
                {isEdit.id == dept._id ? (
                  <Button
                    variant="contained"
                    style={{ height: "40px", background: "#bcbec2" }}
                    onClick={() => {
                      setIsEdit({});
                      setFlag(!flag);
                    }}
                  >
                    Cancel
                  </Button>
                ) : (
                  <EditIcon
                    style={{
                      height: "30px",
                      width: "30px",
                      color: "#0e67e3",
                      cursor: "pointer",
                    }}
                    onClick={() => handleEdit(dept?._id, dept?.name)}
                  />
                )}
                {isEdit.id == dept._id && (
                  <Button
                    variant="contained"
                    style={{ height: "40px" }}
                    onClick={handleSave}
                  >
                    Save
                  </Button>
                )}
              </FormControl>
            ))}
        </div>
        <form noValidate autoComplete="off" onSubmit={handleCreate}>
          <FormControl
            sx={{ width: "auto" }}
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "20px",
              alignItems: "center",
              // justifyContent: "center",
            }}
          >
            {isAdd && (
              <OutlinedInput
                placeholder="Enter program"
                onChange={(e) => setNewBatch(e.target.value)}
              />
            )}
            {!isAdd && (
              <IoMdAddCircleOutline
                style={{ height: "40px", width: "40px" }}
                onClick={() => setIsAdd(true)}
              />
            )}
            {isAdd && (
              <RemoveCircleOutlineIcon
                style={{ height: "40px", width: "40px" }}
                onClick={() => setIsAdd(false)}
              />
            )}
            {isAdd && (
              <Button
                variant="contained"
                color="success"
                style={{ height: "40px" }}
                type="submit"
              >
                Create
              </Button>
            )}
          </FormControl>
        </form>
      </Card>
      <ToastContainer />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setDeleteData({});
              handleClose();
            }}
          >
            Cancel
          </Button>
          <Button
            autoFocus
            style={{ background: "red" }}
            onClick={handleDelete}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
