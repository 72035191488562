import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { resetAllAction } from "../../redux/question_reducer";
import { resetResultAction } from "../../redux/result_reducer";
import { useDispatch } from "react-redux";
import { Button } from "@mui/material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function ResultDisplay() {
  const [exam, setExam] = useState({});
  const { examId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const studentId = localStorage.getItem("userId");
  function onRestart() {
    dispatch(resetAllAction());
    dispatch(resetResultAction());
  }
  useEffect(() => {
    const handleResultSubmit = () => {
      const apiUrl = `${process.env.REACT_APP_API_URL}/result`;

      axios
        .get(`${apiUrl}/${studentId}/${examId}`)
        .then((response) => {
          setExam(response.data);
        })
        .catch((error) => {
          console.error("Error updating student examTime:", error);
        });
    };
    onRestart();
    handleResultSubmit();
  }, []);
  return (
    <div style={{ margin: "10%", height: "100%" }}>
      <div style={{ position: "absolute", top: "150px", right: "100px" }}>
        <Button variant="contained" onClick={() => navigate("/home")}>
          Back to home
        </Button>
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="right">Id</StyledTableCell>
              <StyledTableCell>Full Name</StyledTableCell>
              <StyledTableCell align="right">Program</StyledTableCell>
              <StyledTableCell align="right">Department</StyledTableCell>
              <StyledTableCell align="right">Modality</StyledTableCell>
              <StyledTableCell align="right">Batch</StyledTableCell>
              <StyledTableCell align="right">Exam Name</StyledTableCell>
              <StyledTableCell align="right">examTime</StyledTableCell>
              <StyledTableCell align="right">Result</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <StyledTableRow>
              <StyledTableCell component="th" scope="row">
                {exam?.student?.id}
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
                {exam?.student?.firstName} {exam?.student?.middleName}{" "}
                {exam?.student?.lastName}
              </StyledTableCell>
              <StyledTableCell align="right">
                {exam?.student?.program?.name}
              </StyledTableCell>
              <StyledTableCell align="right">
                {exam?.student?.department?.name}
              </StyledTableCell>
              <StyledTableCell align="right">
                {exam?.student?.modality}
              </StyledTableCell>
              <StyledTableCell align="right">
                {exam?.student?.batch?.name}
              </StyledTableCell>
              <StyledTableCell align="right">
                {exam?.exam?.name}
              </StyledTableCell>
              <StyledTableCell align="right">
                {exam?.remainTime}
              </StyledTableCell>
              <StyledTableCell align="right">{exam?.result}</StyledTableCell>
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
