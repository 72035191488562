import { createSlice } from "@reduxjs/toolkit";

export const resultReducer = createSlice({
  name: "result",
  initialState: {
    userId: null,
    result: [],
    user: {},
  },
  reducers: {
    setUserId: (state, action) => {
      state.userId = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    pushResultAction: (state, action) => {
      const { trace, checked } = action.payload;

      if (state.result.length < trace) {
        for (let i = state.result.length; i < trace; i++) {
          state.result.push(undefined);
        }
      }
      state.result[trace] = checked;

      // state.result[trace] = checked;
      // state.result.push(action.payload);
    },
    setUserResult: (state, action) => {
      state.result = action.payload;
    },
    updateResultAction: (state, action) => {
      const { trace, checked } = action.payload;
      state.result.fill(checked, trace, trace + 1);
    },
    resetResultAction: () => {
      return {
        userId: null,
        result: [],
      };
    },
  },
});

export const {
  setUserId,
  pushResultAction,
  resetResultAction,
  updateResultAction,
  setUserResult,
  setUser,
} = resultReducer.actions;

export default resultReducer.reducer;
