import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { setUserId } from "../redux/result_reducer";
import "../styles/Main.css";
import axios from "axios";
import { format } from "date-fns";

export default function Main() {
  const [result, setResult] = useState([]);
  const [examData, setExamData] = useState({});
  const [isTaken, setIsTaken] = useState(false);
  const [user, setUser] = useState({});
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const { examId } = useParams();

  useEffect(() => {
    const handleResultSubmit = () => {
      const apiUrl = `${process.env.REACT_APP_API_URL}/result`;

      axios
        .get(`${apiUrl}/${localStorage.getItem("userId")}/${examId}`)
        .then((response) => {
          if (response.data.result !== null) {
            isTaken(true);
          }
        })
        .catch((error) => {
          console.error("Error in geting exam:", error);
        });
    };
    async function fetchStudent() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/students/${localStorage.getItem(
            "userId"
          )}`
        );
        const datas = response.data;
        setUser(datas);
      } catch (error) {
        console.error("Error fetching student data:", error);
      }
    }

    fetchStudent();
    handleResultSubmit();
  }, []);

  function startQuiz() {
    if (inputRef.current?.value) {
      dispatch(setUserId(inputRef.current?.value));
    }
    handleStart();
  }

  useEffect(() => {
    const fetchResult = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/result/${localStorage.getItem(
            "userId"
          )}/${examId}`
        );
        setResult(response.data);
        // setUserAnswer(response.data.answer);
      } catch (error) {
        console.error("Error fetching user:", error.message);
      }
    };
    async function fetchExam() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/questions/${examId}`
        );
        const data = response.data;

        setExamData(data);
        // Process the retrieved data here
      } catch (error) {
        // Handle error
        console.error("Error fetching student data:", error);
      }
    }
    fetchExam();
    fetchResult();
  }, []);

  const handleStart = () => {
    if (isTaken) {
      navigate(`/result/${examId}`);
    } else {
      navigate(`/quiz/${examId}`);
    }
  };

  return (
    <div
      className="container"
      style={{
        backgroundColor: "white",
        boxShadow: "0 0.5rem 1rem rgba(0, 0, 0, 0.15)",
        maxHeight: "90vh",
        width: "70%",
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        marginTop: "50px",
        overflowY: "scroll",
      }}
    >
      <h2 style={{ textAlign: "center" }}>Alliance Exam </h2>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ width: "100%", fontSize: ".9rem" }}>
          <ol>
            <li style={{ color: "black" }}>
              Unauthorized materials are strictly prohibited.
            </li>
            <li style={{ color: "black" }}>
              {" "}
              Communication with others during the exam is not allowed.
            </li>
            <h4>
              Student Name:{" "}
              <span style={{ color: "#289af7" }}>
                {user?.firstName} {user?.middleName} {user?.lastName}
              </span>
            </h4>
            <h4>
              Student Id: <span style={{ color: "#289af7" }}>{user?.id}</span>
            </h4>
            <h4>
              Department:{" "}
              <span style={{ color: "#289af7" }}>{user?.department?.name}</span>
            </h4>
            <h4>
              Program:{" "}
              <span style={{ color: "#289af7" }}>{user?.program?.name}</span>
            </h4>
            <h4>
              Modality:{" "}
              <span style={{ color: "#289af7" }}>{user?.modality}</span>
            </h4>
            <h4>
              Exam Name:{" "}
              <span style={{ color: "#289af7" }}>{examData?.name}</span>
            </h4>
            <h4>
              Exam Date:{" "}
              <span style={{ color: "#289af7" }}>
                {}
                {examData?.date
                  ? format(new Date(examData?.date), "MMMM dd, yyyy HH:mm:ss")
                  : "-----"}
              </span>
            </h4>
            <h4>
              Exam Time:{" "}
              <span style={{ color: "#289af7" }}>{examData?.examTime}</span>
            </h4>
          </ol>
        </div>

        <img
          style={{ width: "40%" }}
          src="https://media.istockphoto.com/id/1355440542/vector/student-cheating-school-boys-during-test-cheater-pupil-on-exam-schoolkid-copy-answers-from.jpg?s=612x612&w=0&k=20&c=h3Df3UfyjxlfiFyEjTu9G_WkZWOUCxJY10jZWv0yBsM="
          alt=""
        />
      </div>
      <div className="start">
        <Link
          className="btn"
          to={`/quiz/${examId}`}
          onClick={startQuiz}
          style={{
            backgroundColor: "#007bff",
            borderColor: "#007bff",
            color: "#fff",
            padding: "0.375rem 0.75rem",
            fontSize: "1rem",
            lineHeight: "1.5",
            borderRadius: "0.25rem",
            cursor: "pointer",
          }}
        >
          Start Exam
        </Link>
      </div>
    </div>
  );
}
