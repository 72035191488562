import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DeleteModal from "../../DeleteModal";
import { extractUniqueNumber, shuffleQuestions } from "../../../utils/index";
import "../../../styles/review.css";
import { Button } from "@mui/material";
const ResultReview = () => {
  const [questions, setQuestions] = useState([]);
  const [open, setOpen] = useState(false);
  const { studentId, examId, id } = useParams();
  const [userAnswer, setUserAnswer] = useState([]);
  const [deleteId, setDeleteId] = useState("");
  const [deletedIndex, setDeletedIndex] = useState(null);
  const decodeId = decodeURIComponent(id);
  const navigate = useNavigate();
  const handleDelete = async () => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/questions/${deletedIndex}/${deleteId}`
      );
      handleClose();
      setDeleteId("");
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/questions/${examId}`
        );
        let seed = extractUniqueNumber(decodeId);
        const questionsData = shuffleQuestions(response.data.questions, seed);
        setQuestions(questionsData);
      } catch (error) {
        console.error("Error fetching questions:", error.message);
      }
    };
    fetchQuestions();
  }, []);

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/result/${studentId}/${examId}`
        );
        const resultData = response.data;
        setUserAnswer(resultData.answer);
      } catch (error) {
        console.error("Error fetching questions:", error.message);
      }
    };
    fetchQuestions();
  }, []);

  const handleClickOpen = (index, delId) => {
    setOpen(true);
    setDeletedIndex(index);
    setDeleteId(delId);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div maxWidth="sm" style={{ margin: "20px 60px" }}>
      {/* <h2 style={{ color: "blue" }}>
        {questions?.length > 0 && questions[0]?.name} Questions (
        <span style={{ color: "red" }}>
          {questions.length > 0 && questions.length}
        </span>{" "}
        )
      </h2> */}
      <div style={{ width: "100%" }}></div>
      <Button
        style={{
          background: "red",
          color: "white",
          float: "right",
          marginBottom: "50px",
        }}
        onClick={() => navigate(-1)}
      >
        Back
      </Button>
      {questions &&
        questions?.map((question, ind) => (
          <div className="questions" key={ind}>
            <p className="text" style={{ marginTop: "30px" }}>
              {ind + 1}. {question?.question}
            </p>
            <div></div>
            <ul key={question?._id} style={{ marginBottom: "250px" }}>
              {Object.entries(question?.options).map(([key, value], i) => (
                <li
                  key={key}
                  style={{ color: "black", height: "50px", border: "none" }}
                >
                  <input
                    type="radio"
                    value={key}
                    name={`options-${ind}${key}`}
                    id={`q${i}-option`}
                    disabled
                  />

                  <label
                    className={`text-primary ${
                      userAnswer[ind] === key
                        ? question?.correctAnswer === key
                          ? "correct"
                          : "user"
                        : "normal"
                    }`}
                    htmlFor={`q${i}-option`}
                    style={{ marginTop: "-15px", marginLeft: "70px" }}
                  >
                    {value}
                  </label>
                  <div
                    className={`select ${
                      question?.correctAnswer === key ? "checked" : ""
                    } `}
                    style={{ top: "10px" }}
                  ></div>
                </li>
              ))}
            </ul>
          </div>
        ))}
      {open && (
        <DeleteModal
          handleClickOpen={handleClickOpen}
          handleClose={handleClose}
          open={open}
          handleDelete={handleDelete}
        />
      )}
    </div>
  );
};

const styles = {
  check: {
    display: "block",
    position: "absolute",
    content: "",
    borderRadius: "100%",
    backgroundColor: "white",
    height: "25px",
    width: "25px",
    top: "-1px",
    left: "-2px",
    margin: "auto",
  },
};

export default ResultReview;
