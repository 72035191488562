import { call, put, takeLatest } from "redux-saga/effects";
import apiObject from "./api";
import {
  fetchStudentsStart,
  fetchStudentsSuccess,
  fetchStudentsFailure,
  updateStudentStart,
  updateStudentSuccess,
  updateStudentFailure,
  deleteStudentStart,
  deleteStudentSuccess,
  deleteStudentFailure,
  // Add other actions here
} from "./studentSlice.js";

function* handleFetchStudents() {
  try {
    const response = yield call(apiObject.getStudents);
    yield put(fetchStudentsSuccess(response.data));
  } catch (error) {
    yield put(fetchStudentsFailure(error.message));
  }
}

function* handleUpdateStudent(action) {
  try {
    const response = yield call(
      apiObject.updateStudent,
      action.payload.id,
      action.payload.data
    );
    yield put(updateStudentSuccess(response.data));
  } catch (error) {
    yield put(updateStudentFailure(error.message));
  }
}

function* handleDeleteStudent(action) {
  try {
    const response = yield call(apiObject.deleteStudent, action.payload);
    yield put(deleteStudentSuccess(response.data._id));
  } catch (error) {
    yield put(deleteStudentFailure(error.message));
  }
}

function* watchSaga() {
  yield takeLatest(fetchStudentsStart.type, handleFetchStudents);
  yield takeLatest(updateStudentStart.type, handleUpdateStudent);
  yield takeLatest(deleteStudentStart.type, handleDeleteStudent);
  // Add other takeLatest functions for other actions
}

export default watchSaga;
