import { Container, Wrapper, CardContainer } from "./ProjectsStyle";
import ExamDetail from "./ExamDetailCard";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";

// const ExamList = ({ openModal, setOpenModal }) => {
//   const dispatch = useDispatch();
//   const department = localStorage.getItem("department");
//   const program = localStorage.getItem("program");
//   const [examList, setExamList] = useState();
//   useEffect(() => {
//     const fetchQuestions = async () => {
//       try {
//         const response = await axios.get(
//           `${process.env.REACT_APP_API_URL}/questions/department/program/${department}?program=${program}&status=true`
//         );
//         const questionsData = response.data;

//         setExamList(questionsData);
//       } catch (error) {
//         console.error("Error fetching questions:", error.message);
//       }
//     };
//     fetchQuestions();
//   }, []);
//   return (
//     <Container id="projects">
//       <Wrapper>
//         {/* <Title>Subjects</Title> */}
//         {/* <Desc>
//           I have worked on a wide range of projects. From web apps to android
//           apps and console java projects. Here are some of my projects.
//         </Desc> */}

//         <CardContainer>
//           {examList?.map((lesson, i) => (
//             <ExamDetail
//               key={i}
//               project={lesson}
//               openModal={openModal}
//               setOpenModal={setOpenModal}
//               //   subjectId={subjectId}
//             />
//           ))}
//         </CardContainer>
//       </Wrapper>
//     </Container>
//   );
// };

// export default ExamList;
import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea, Grid } from "@mui/material";
import Modalpopup from "../ui/Modal";
import DateCalendarValue from "../ui/Calendar";
import TakenExam from "./TakenExamList";
import { resetResultAction } from "../../redux/result_reducer";
import { resetAllAction } from "../../redux/question_reducer";

export default function ActionAreaCard({ openModal, setOpenModal }) {
  const dispatch = useDispatch();
  const department = localStorage.getItem("department");
  const program = localStorage.getItem("program");
  const modality = localStorage.getItem("modality");
  const batch = localStorage.getItem("batch");
  const [examList, setExamList] = useState();
  const [takenExam, setTakenExam] = useState([]);

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/questions/department/program/${department}?program=${program}&modality=${modality}&batch=${batch}&status=true`
        );
        const questionsData = response.data;

        setExamList(questionsData);
      } catch (error) {
        console.error("Error fetching questions:", error.message);
      }
    };
    fetchQuestions();
  }, []);

  useEffect(() => {
    const handleResultSubmit = () => {
      const apiUrl = `${process.env.REACT_APP_API_URL}/result`;

      axios
        .get(`${apiUrl}/student/exam/${localStorage.getItem("userId")}`)
        .then((response) => {
          setTakenExam(response.data);
        })
        .catch((error) => {
          console.error("Error getting student taken exam", error);
        });
    };

    handleResultSubmit();
  }, []);
  useEffect(() => {
    dispatch(resetAllAction());
    dispatch(resetResultAction());
  }, []);
  return (
    <Grid
      container
      item
      xs={12}
      md={12}
      lg={12}
      xl={12}
      style={{
        padding: "30px",
        backgroundColor: "white",
        width: "100%",
        height: "100vh",
        margin: "1px",
        gap: "20px",
        overflowY: "auto",
        paddingBottom: "90px",
      }}
    >
      <Grid
        container
        style={{
          // width: "23%",
          background: "#fff",
          gap: "20px",
        }}
        direction="column"
        xs={12}
        md={2}
        lg={2}
        xl={2}
      >
        <div
          style={{
            width: "100%",
            height: "30%",
            backgroundColor: "#f5f5f5",
            border: "1px solid #cccccc",
          }}
        >
          <CardContent>
            <p>Navigation</p>
          </CardContent>
        </div>
        <div
          style={{
            width: "100%",
            height: "30%",
            backgroundColor: "#f5f5f5",
            border: "1px solid #cccccc",
          }}
        >
          <CardContent>
            <p>Files</p>
          </CardContent>
        </div>
        <Card
          style={{
            width: "100%",
            height: "30%",
            backgroundColor: "#f5f5f5",
            border: "1px solid #cccccc",
          }}
        >
          <CardContent>
            <p>Coueses</p>
          </CardContent>
        </Card>
      </Grid>
      <Grid
        container
        style={{
          background: "#fff",
          gap: "20px",
          overflowY: "auto",
        }}
        xs={12}
        md={12}
        lg={7}
        xl={7.5}
        direction="column"
      >
        <Grid
          style={{
            background: "#f5f5f5",
            padding: "1rem",
            border: "1px solid #cccccc",
          }}
        >
          <h3 style={{ fontWeight: "lighter" }}>Open exams</h3>
          <Grid
            container
            style={{
              background: "#f5f5f5",
              padding: "1rem",
              width: "100%",
              gap: "20px",
            }}
            direction="row"
          >
            {examList?.map((lesson, i) => (
              <Card
                sx={{
                  maxHeight: 300,
                  maxWidth: 300,
                  backgroundColor: "#fff",
                }}
                key={i}
              >
                <CardActionArea>
                  <CardContent>
                    <ExamDetail
                      project={lesson}
                      openModal={openModal}
                      setOpenModal={setOpenModal}
                    />
                  </CardContent>
                </CardActionArea>
              </Card>
            ))}
          </Grid>
        </Grid>
        <Grid
          style={{
            background: "#f5f5f5",
            padding: "1rem",
            border: "1px solid #cccccc",
          }}
        >
          <h3 style={{ fontWeight: "lighter" }}>Recently taken exams</h3>
          <Grid
            container
            style={{
              background: "#f5f5f5",
              padding: "1rem",
              gap: "20px",
            }}
          >
            {takenExam?.map((lesson, i) => (
              <Card
                sx={{ maxHeight: 300, maxWidth: 300, backgroundColor: "#fff" }}
                key={i}
              >
                <CardActionArea>
                  <CardContent>
                    <TakenExam
                      project={lesson}
                      openModal={openModal}
                      setOpenModal={setOpenModal}
                      //   subjectId={subjectId}
                    />
                  </CardContent>
                </CardActionArea>
              </Card>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        style={{
          // width: "23%",
          background: "#fff",
          gap: "20px",
        }}
        direction="column"
        // spacing={3}
        xs={12}
        md={3}
        lg={2}
        xl={2}
      >
        <div
          style={{
            width: "100%",
            height: "30%",
            backgroundColor: "#f5f5f5",
            border: "1px solid #cccccc",
          }}
        >
          <CardContent>
            <p>Upcomming Events</p>
            <h3>There are no up comming events</h3>
          </CardContent>
        </div>
        <div
          style={{
            width: "100%",
            height: "auto",
            backgroundColor: "#f5f5f5",
            border: "1px solid #cccccc",
          }}
        >
          <CardContent>
            <p>Calendar</p>
            <DateCalendarValue />
          </CardContent>
        </div>
        {/* <Card
          sx={{
            backgroundColor: "#f5f5f5",
            border: "2px solid #ebf0ec",
          }}
          style={{ width: "100%", height: "30%" }}
        >
          <CardContent>
            <p>Timeline</p>
          </CardContent>
        </Card> */}
      </Grid>
    </Grid>
  );
}
