import Button from "@mui/material/Button";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteModal from "../../DeleteModal";
import DeleteIcon from "@mui/icons-material/Delete";

const QuestionDetailInResult = () => {
  const [questions, setQuestions] = useState([]);
  const [open, setOpen] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const [deleteId, setDeleteId] = useState("");
  const [deletedIndex, setDeletedIndex] = useState(null);

  const handleDelete = async () => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/questions/${deletedIndex}/${deleteId}`
      );
      handleClose();
      setDeleteId("");
      // Handle the successful deletion
    } catch (error) {
      console.error(error);
      // Handle the error
    }
  };

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/questions/${id}`
        );
        const questionsData = response.data;
        setQuestions([questionsData]);
      } catch (error) {
        console.error("Error fetching questions:", error.message);
      }
    };
    fetchQuestions();
  }, []);

  const handleClickOpen = (index, delId) => {
    setOpen(true);
    setDeletedIndex(index);
    setDeleteId(delId);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div maxWidth="sm" style={{ margin: "20px 60px" }}>
      <div style={{ width: "100%" }}></div>
      <Button
        style={{ background: "red", color: "white", float: "right" }}
        onClick={() => navigate(-1)}
      >
        Back
      </Button>
      <h2 style={{ color: "blue" }}>
        {questions.length > 0 && questions[0]?.name} Questions (
        <span style={{ color: "red" }}>
          {questions.length > 0 && questions[0]?.questions.length}
        </span>{" "}
        )
      </h2>
      {questions.length > 0 &&
        questions[0]?.questions.map((question, ind) => (
          <div className="questions" key={ind}>
            <p className="text" style={{ marginTop: "30px" }}>
              {ind + 1}. {question.question}
            </p>
            <div>
              <DeleteIcon
                style={{ cursor: "pointer", color: "red" }}
                onClick={() => handleClickOpen(ind, questions[0]?._id)}
              />
              <EditIcon
                style={{ cursor: "pointer", color: "green" }}
                onClick={() =>
                  navigate(`/update-question/${ind}/${questions[0]?._id}`)
                }
              />
            </div>
            <ul key={question?._id} style={{ marginBottom: "250px" }}>
              {Object.entries(question.options).map(([key, value], i) => (
                <li
                  key={key}
                  style={{ color: "black", height: "50px", border: "none" }}
                >
                  <input
                    type="radio"
                    value={key}
                    name={`options-${ind}`}
                    id={`q${i}-option`}
                    disabled
                  />

                  <label
                    className="text-primary"
                    htmlFor={`q${i}-option`}
                    style={{ marginTop: "-15px" }}
                  >
                    {value}
                  </label>
                  <div
                    className={`check ${
                      question?.correctAnswer == key ? "checked" : ""
                    }`}
                    style={{ top: "10px" }}
                  ></div>
                </li>
              ))}
            </ul>
          </div>
        ))}
      {open && (
        <DeleteModal
          handleClickOpen={handleClickOpen}
          handleClose={handleClose}
          open={open}
          handleDelete={handleDelete}
        />
      )}
    </div>
  );
};

export default QuestionDetailInResult;
