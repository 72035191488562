import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import "../styles/login.css";
import { CircularProgress } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import { setUser, setUserId } from "../redux/result_reducer";
import { useDispatch } from "react-redux";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function SignIn() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [failur, setShowFailur] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    try {
      const response = await axios.post(
        "https://alliance-exam-v2.onrender.com/login",
        {
          username,
          password,
        }
      );
      dispatch(setUser(response.data));
      const token = response.data.token;
      const role = response.data.role;
      const userId = response.data._id;
      const department = response.data.department;
      const modality = response.data.modality;
      const batch = response.data.batch;
      const program = response.data.program;
      const studentId = response.data.id;
      localStorage.setItem("token", token);
      localStorage.setItem("role", role);
      localStorage.setItem("userId", userId);
      localStorage.setItem("department", department);
      localStorage.setItem("modality", modality);
      localStorage.setItem("batch", batch);
      localStorage.setItem(
        "name",
        response.data.firstName + " " + response.data.middleName
      );
      localStorage.setItem("program", program);
      localStorage.setItem("studentId", studentId);
      if (role === "student") {
        setLoading(false);
        navigate("/home");
      }
      if (role === "super-admin" || role === "college") {
        setLoading(false);
        navigate("/dashboard");
      }

      if (role === "admin") {
        setLoading(false);
        navigate("/register");
      }

      //   onLogin(token);
    } catch (error) {
      setShowFailur(true);
      setLoading(false);
      setMessage("Incorrect email or Password");
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setShowFailur(false);
      setShowPopup(false);
    }, 10000);
  }, [showPopup, failur]);

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container
        component="main"
        maxWidth="xs"
        className="responsive-container"
        sx={styles.mobile}
      >
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {/* <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}> */}
          <img
            src="https://firebasestorage.googleapis.com/v0/b/genius-eth.appspot.com/o/question_paper_images%2Flogo.jpg?alt=media&token=a0ada078-4076-4ddb-bb45-30cfdfe13db6"
            alt=""
            style={{
              backgroundSize: "cover",
              width: "100px",
              height: "100px",
            }}
          />

          {/* </Avatar> */}
          <Stack sx={{ width: "100%" }} spacing={2}>
            {showPopup && (
              <Alert variant="filled" severity="success">
                {message}
              </Alert>
            )}

            {failur && (
              <Alert variant="filled" severity="error">
                {message}
              </Alert>
            )}
          </Stack>
          <Typography component="h1" variant="h5">
            Exam SignIn Portal
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email or Id"
              name="email"
              autoComplete="email"
              autoFocus
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            {/* <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            /> */}
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type={showPassword ? "text" : "password"}
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                ),
              }}
            />
            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={loading}
            >
              {loading ? <CircularProgress /> : "Sign In"}
            </Button>
            {/* <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="/register" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid> */}
          </Box>
        </Box>
        {/* <Copyright sx={{ mt: 8, mb: 4 }} /> */}
      </Container>
    </ThemeProvider>
  );
}

const styles = {
  mobile: {
    "@media (max-width: 600px)": {
      maxWidth: "90%",
    },
  },
};
