import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

/** Custom Hook */
import { updateResult } from "../hooks/setResult";
import { startFetch } from "../redux/question_reducer";
import { useParams } from "react-router-dom";

export default function Questions({
  onChecked,
  userAnswer,
  checked,
  setChecked,
}) {
  // const [checked, setChecked] = useState(undefined);

  const { trace } = useSelector((state) => state.questions);
  const result = useSelector((state) => state.result.result);
  const { examId } = useParams();
  const questions = useSelector(
    (state) => state.questions.queue[state.questions.trace]
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(startFetch({ examId }));
  }, []);

  useEffect(() => {
    dispatch(updateResult({ trace, checked }));
  }, [dispatch, checked]);

  function onSelect(key) {
    onChecked(trace, key); // Update local checked state
    setChecked(key);
    dispatch(updateResult({ trace, checked }));
    // handleAnswerSubmit();
  }

  return (
    <div className="questions">
      <h3 className="">
        {trace + 1}. {questions?.question}
      </h3>

      {/* <ul>
        {questions?.options &&
          Object.entries(questions.options).map(([key, value]) => (
            <li key={key}>
              <input1
                type="radio"
                id={`option-${key}`}
                name={`question-${trace}`}
                value={key}
                checked={checked === key} // Check if the current option is checked
                onChange={() => onSelect(key)}
              />
              <label htmlFor={`option-${key}`}>{value}</label>
            </li>
          ))}
      </ul> */}
      <ul style={{ overflowY: "auto", maxHeight: "400px" }}>
        {questions?.options &&
          Object.entries(questions?.options).map(([key, value], i) => (
            <>
              {value && (
                <li key={i} style={{ color: "black" }}>
                  <input
                    type="radio"
                    value={key}
                    name={`question-${trace}`}
                    id={`q${i}-option`}
                    onChange={() => onSelect(key)}
                    checked={result[trace] == key || checked == key}
                  />

                  <label className="text" htmlFor={`q${i}-option`}>
                    {value}
                  </label>
                  <div
                    className={`check ${checked == key ? "checked" : ""}`}
                  ></div>
                </li>
              )}
            </>
          ))}
      </ul>
    </div>
  );
}
