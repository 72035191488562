import { createSlice } from "@reduxjs/toolkit";

const studentsSlice = createSlice({
  name: "students",
  initialState: {
    students: [],
    loading: false,
    error: null,
  },
  reducers: {
    fetchStudentsStart: (state) => {
      state.loading = true;
    },
    fetchStudentsSuccess: (state, action) => {
      state.loading = false;
      state.students = action.payload;
      state.error = null;
    },
    fetchStudentsFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    updateStudentStart: (state) => {
      state.loading = true;
    },
    updateStudentSuccess: (state, action) => {
      state.loading = false;
      const updatedStudent = action.payload;
      state.students = state.students.map((student) =>
        student._id === updatedStudent._id ? updatedStudent : student
      );
      state.error = null;
    },
    updateStudentFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    deleteStudentStart: (state) => {
      state.loading = true;
    },
    deleteStudentSuccess: (state, action) => {
      state.loading = false;
      const deletedStudentId = action.payload;
      state.students = state.students.filter(
        (student) => student._id !== deletedStudentId
      );
      state.error = null;
    },
    deleteStudentFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    quesryDataStart: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const {
  fetchStudentsStart,
  fetchStudentsSuccess,
  fetchStudentsFailure,
  updateStudentStart,
  updateStudentSuccess,
  updateStudentFailure,
  deleteStudentStart,
  deleteStudentSuccess,
  deleteStudentFailure,
  quesryDataStart,
  // Add other actions here
} = studentsSlice.actions;

export default studentsSlice.reducer;
