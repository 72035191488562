import { all } from "redux-saga/effects";
// import watchSaga from "./sagas";
import { watchFetchQuestions } from "./questionSaga";
import watchSaga from "./saga";

export default function* rootSaga() {
  yield all([
    watchSaga(),
    watchFetchQuestions(),
    // Add other sagas here
  ]);
}
