import { useRef, useState } from "react";
import { useEffect } from "react";
import "./form.css";
import axios from "axios";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import ExcelExamUploader from "../ExamExcelUpload";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";

const ExamForm = () => {
  const [questionData, setQuestionData] = useState({
    question: "",
    options: {
      A: "",
      B: "",
      C: "",
      D: "",
    },
    correctAnswer: "",
    explanation: "",
  });
  const [courseList, setCourseList] = useState([]);
  const [message, setMessage] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [failur, setShowFailur] = useState(false);
  const [program, setProgram] = useState("");
  const [time, setTime] = useState("");
  const [department, setDepartment] = useState("");
  const [password, setPassword] = useState("");
  const [departments, setDepartmets] = useState([]);

  const [examId, setExamId] = useState("");
  const [exams, setExams] = useState([]);

  const formRef = useRef(null);

  const handleProgramChange = (e) => {
    setProgram(e.target.value);
  };
  const handleTimeChange = (e) => {
    setTime(e.target.value);
  };
  const handleDepartmentChange = (e) => {
    setDepartment(e.target.value);
  };
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name.includes("option")) {
      const optionName = name.slice(-1);
      setQuestionData((prevData) => ({
        ...prevData,
        options: {
          ...prevData.options,
          [optionName]: value,
        },
      }));
    } else {
      setQuestionData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // dispatch(setExamStart({ question }));
    axios
      .put(
        `${process.env.REACT_APP_API_URL}/question/add-exam/${examId}`,
        questionData
      )
      .then((response) => {
        setShowPopup(true);
        setMessage("Question Add successfully");
        formRef.current.reset();
      })
      .catch((error) => {
        setShowFailur(true);
        setMessage("Failure in exam cretion" + error);
        console.error(error);
      });
    // handleTimeSubmit();
  };
  useEffect(() => {}, []);

  useEffect(() => {
    setTimeout(() => {
      setShowFailur(false);
      setShowPopup(false);
    }, 10000);
  }, [showPopup, failur]);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/questions`
        );
        setCourseList(response.data);
      } catch (error) {
        console.error("Error fetching user:", error.message);
      }
    };
    const fetchDepartment = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/department`
        );
        setDepartmets(response.data);
      } catch (error) {
        console.error("Error fetching user:", error.message);
      }
    };
    fetchUser();
    fetchDepartment();
  }, []);

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/questions`
        );
        const questionsData = response.data;
        setExams(questionsData);
      } catch (error) {
        console.error("Error fetching questions:", error.message);
      }
    };
    fetchQuestions();
  }, []);
  return (
    <>
      <div className="container" style={{ width: "90%" }}>
        <div className="row" style={{ width: "100%", margin: "auto" }}>
          <div
            className="col-md-12"
            style={{
              width: "100%",
              margin: "auto",
            }}
          >
            <div className="card" style={{ width: "100%", margin: "auto" }}>
              <Stack sx={{ width: "100%" }} spacing={2}>
                {showPopup && (
                  <Alert variant="filled" severity="success">
                    {message}
                  </Alert>
                )}

                {failur && (
                  <Alert variant="filled" severity="error">
                    {message}
                  </Alert>
                )}
              </Stack>
              <div
                className="card-header row"
                style={{
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <h4>Add Exam Question</h4>
              </div>
              <div
                style={{
                  // border: "2px solid blue",
                  padding: "10px",
                  boxShadow: "0 0 12px 4px rgba(179, 178, 178, 0.4)",
                  background: "white",
                }}
              >
                <ExcelExamUploader
                  courseList={courseList}
                  questionData={questionData}
                  setMessage={setMessage}
                  setShowPopup={setShowPopup}
                  setShowFailur={setShowFailur}
                  handleProgramChange={handleProgramChange}
                  handleTimeChange={handleTimeChange}
                  handleDepartmentChange={handleDepartmentChange}
                  time={time}
                  program={program}
                  department={department}
                  departments={departments}
                  handlePasswordChange={handlePasswordChange}
                  password={password}
                />
              </div>
              <div className="card-body">
                <form onSubmit={handleSubmit} ref={formRef}>
                  <div className="mb-3">
                    <label>Question</label>
                    <input
                      type="text"
                      name="question"
                      className="form-control"
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="row">
                    <div
                      className="mb-3"
                      style={{ paddingLeft: "3rem", width: "50%" }}
                    >
                      <label>Option A</label>
                      <input
                        type="text"
                        name="optionA"
                        className="form-control"
                        onChange={handleInputChange}
                      />
                    </div>

                    <div
                      className="mb-3"
                      style={{ paddingLeft: "3rem", width: "50%" }}
                    >
                      <label>Option B</label>
                      <input
                        type="text"
                        name="optionB"
                        className="form-control"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div
                      className="mb-3"
                      style={{ paddingLeft: "3rem", width: "50%" }}
                    >
                      <label>Option C</label>
                      <input
                        type="text"
                        name="optionC"
                        className="form-control"
                        onChange={handleInputChange}
                      />
                    </div>
                    <div
                      className="mb-3"
                      style={{ paddingLeft: "3rem", width: "50%" }}
                    >
                      <label>Option D</label>
                      <input
                        type="text"
                        name="optionD"
                        className="form-control"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div
                      className="mb-3"
                      style={{ paddingLeft: "3rem", width: "50%" }}
                    >
                      <label>Correct Answer</label>
                      <br />
                      <input
                        type="text"
                        name="correctAnswer"
                        className="form-control"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div
                    className="row"
                    style={{ justifyContent: "space-between" }}
                  >
                    <FormControl style={{ width: "33%" }}>
                      <InputLabel id="demo-simple-select-label">
                        Exam List
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={examId}
                        label="Department"
                        onChange={(e) => setExamId(e.target.value)}
                      >
                        {exams?.map((dept) => (
                          <MenuItem
                            key={dept._id}
                            value={dept._id}
                            sx={{
                              height: "40px",
                              // textAlign: "left",
                            }}
                          >
                            {dept.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>

                  {/* <div className="row"></div> */}

                  <div style={{ marginTop: "100px" }}>
                    <button type="submit" className="btn btn-primary">
                      Save Question
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          {/* {examStatus && <ResponseModal type="exam" />} */}
        </div>
      </div>
      {/* </div>
      </WrapperContainer> */}
    </>
  );
};

export default ExamForm;
