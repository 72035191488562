import "../styles/App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { useSelector } from "react-redux";

/** Import components */
import Main from "./Main";
import Quiz from "./Quiz";
import Result from "./Result";
import ExamForm from "../components/Admin/Exam";
import { CheckUserExist } from "../helper/helper";
import SignIn from "./Login";
import RegisterForm from "./RegisterStudent";
import Dashboard from "./SideBar";
import StudentResult from "./StundentDetail";
import UpdateForm from "./UpdateStudent";
import QuestionBank from "./QuestionBank";
import QuestionDetail from "./QuestionDetail";
import InsertQuestionForm from "./Admin/QuestionInsert.js";
import ExamList from "./Admin/ExamList";
import ProfileHeader from "./ui/Header";
import ResultDisplay from "./ui/ResultDisplay";
import ExamResultList from "./Admin/Result/ResultList";
import CollectionComponet from "./Admin/AcademicComponent/CollectionComponent";
import CourseForm from "./Admin/AcademicComponent/Course.js";
import StudentList from "./Admin/student/StudentList.js";
import EditExamForm from "./Admin/AcademicComponent/EditExam.js";
import QuestionDetailInResult from "./Admin/Result/QuestionDetail.js";
import ResultReview from "./Admin/Result/ResultReview.js";
import EditQuestion from "./Admin/Result/EditQuestion.js";
import RegisterAdmin from "./Admin/Super-Admin/registerAdmin.js";
import RegisterSuperAdmin from "./college/registerAdmin.js";
import UpdateAdmins from "./college/UpdateAdmins.js";
import AdminDashboard from "./Dashboard/Dashboard.js";

/** React routes */
const router = createBrowserRouter([
  {
    path: "/",
    element: <SignIn />,
  },
  {
    path: "/home",
    element: (
      <CheckRoleAccess allowedRoles={["student"]}>
        {/* <Main /> */}
        <ProfileHeader />
        <ExamList />
      </CheckRoleAccess>
    ),
  },
  {
    path: "/exam/:examId",
    element: (
      <CheckRoleAccess allowedRoles={["student"]}>
        <Main />
        {/* <ExamList /> */}
      </CheckRoleAccess>
    ),
  },
  {
    path: "/quiz/:examId",
    element: (
      <CheckRoleAccess allowedRoles={["student"]}>
        <Quiz />
      </CheckRoleAccess>
    ),
  },
  {
    path: "/result/:examId",
    element: (
      <CheckRoleAccess allowedRoles={["student"]}>
        <Result />
      </CheckRoleAccess>
    ),
  },
  {
    path: "/result-detail/:examId",
    element: (
      <CheckRoleAccess allowedRoles={["student"]}>
        <ProfileHeader />
        <ResultDisplay />
      </CheckRoleAccess>
    ),
  },
  {
    path: "/exam-uploud",
    element: (
      <CheckRoleAccess allowedRoles={["admin", "super-admin", "college"]}>
        <Dashboard>
          <ExamForm />
        </Dashboard>
      </CheckRoleAccess>
    ),
  },
  {
    path: "/dashboard",
    element: (
      <CheckRoleAccess allowedRoles={["super-admin", "college"]}>
        <Dashboard>
          <AdminDashboard />
        </Dashboard>
      </CheckRoleAccess>
    ),
  },
  {
    path: "/register",
    element: (
      // <CheckUserExist>
      <CheckRoleAccess allowedRoles={["admin", "super-admin", "college"]}>
        <Dashboard>
          <RegisterForm />
        </Dashboard>
      </CheckRoleAccess>
      // </CheckUserExist>
    ),
  },
  {
    path: "/student-result/:examId",
    element: (
      // <CheckUserExist>
      <CheckRoleAccess allowedRoles={["college", "super-admin"]}>
        <Dashboard>
          <StudentResult />
        </Dashboard>
      </CheckRoleAccess>
      // </CheckUserExist>
    ),
  },
  {
    path: "/exam/list",
    element: (
      // <CheckUserExist>
      <CheckRoleAccess allowedRoles={["super-admin", "college"]}>
        <Dashboard>
          <ExamResultList />
        </Dashboard>
      </CheckRoleAccess>
      // </CheckUserExist>
    ),
  },
  {
    path: "/update-question/:index/:examId",
    element: (
      // <CheckUserExist>
      <CheckRoleAccess allowedRoles={["super-admin", "college"]}>
        <Dashboard>
          <EditQuestion />
        </Dashboard>
      </CheckRoleAccess>
      // </CheckUserExist>
    ),
  },
  {
    path: "/student/result-detail/:studentId/:examId/:id",
    element: (
      // <CheckUserExist>
      <CheckRoleAccess allowedRoles={["college", "super-admin"]}>
        <Dashboard>
          <ResultReview />
        </Dashboard>
      </CheckRoleAccess>
      // </CheckUserExist>
    ),
  },
  {
    path: "/exam/edit/:examId",
    element: (
      // <CheckUserExist>
      <CheckRoleAccess allowedRoles={["super-admin", "college"]}>
        <Dashboard>
          <EditExamForm />
        </Dashboard>
      </CheckRoleAccess>
      // </CheckUserExist>
    ),
  },
  {
    path: "/student/list",
    element: (
      // <CheckUserExist>
      <CheckRoleAccess allowedRoles={["super-admin", "college"]}>
        <Dashboard>
          <StudentList />
        </Dashboard>
      </CheckRoleAccess>
      // </CheckUserExist>
    ),
  },
  {
    path: "/question-bank",
    element: (
      // <CheckUserExist>
      <CheckRoleAccess allowedRoles={["super-admin", "college"]}>
        <Dashboard>
          <QuestionBank />
        </Dashboard>
      </CheckRoleAccess>
      // </CheckUserExist>
    ),
  },
  {
    path: "/update-student/:id",
    element: (
      // <CheckUserExist>
      <CheckRoleAccess allowedRoles={["super-admin", "college"]}>
        <Dashboard>
          <UpdateForm />
        </Dashboard>
      </CheckRoleAccess>
      // </CheckUserExist>
    ),
  },
  {
    path: "/update-admin/:id",
    element: (
      // <CheckUserExist>
      <CheckRoleAccess allowedRoles={["super-admin", "college"]}>
        <Dashboard>
          <UpdateAdmins />
        </Dashboard>
      </CheckRoleAccess>
      // </CheckUserExist>
    ),
  },
  {
    path: "/question/:id",
    element: (
      // <CheckUserExist>
      <CheckRoleAccess allowedRoles={["college", "super-admin"]}>
        <Dashboard>
          <QuestionDetail />
        </Dashboard>
      </CheckRoleAccess>
      // </CheckUserExist>
    ),
  },
  {
    path: "/question-detail/:id",
    element: (
      // <CheckUserExist>
      <CheckRoleAccess allowedRoles={["super-admin", "college"]}>
        <Dashboard>
          <QuestionDetailInResult />
        </Dashboard>
      </CheckRoleAccess>
      // </CheckUserExist>
    ),
  },
  {
    path: "/department-form",
    element: (
      // <CheckUserExist>
      <CheckRoleAccess allowedRoles={["super-admin", "college"]}>
        <Dashboard>
          <InsertQuestionForm />
          <CollectionComponet />
        </Dashboard>
      </CheckRoleAccess>
      // </CheckUserExist>
    ),
  },
  {
    path: "/register/super-admin",
    element: (
      // <CheckUserExist>
      <CheckRoleAccess allowedRoles={["super-admin", "college"]}>
        <Dashboard>
          <RegisterSuperAdmin />
        </Dashboard>
      </CheckRoleAccess>
      // </CheckUserExist>
    ),
  },
]);

function App() {
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

/** CheckRoleAccess component to conditionally render based on user role */
function CheckRoleAccess({ allowedRoles, children }) {
  const token = localStorage.getItem("token");
  const userRole = localStorage.getItem("role");
  if (allowedRoles.includes(userRole)) {
    return <>{children}</>;
  } else {
    return <h1> Page Not Found</h1>;
  }
}

export default App;
